import { cloneDeep } from 'lodash';
import { TemplateProps as TemplateType } from 'pages/ChartEditorPage/meta/templates/types';
import { SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import { getPlanById } from 'pages/SubscriptionPage/utils/plansHelper';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Accordion from 'shared/Accordion';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import PanelContainer from 'shared/editor/generic/PanelContainer';
import Modal from 'shared/modal/Modal';
import { cancelTextMap, showPaidPlanModal } from 'shared/utils/paidPlanHelper';
import ListOption from 'shared/wizard/components/ListOption';
import { getCheckTypes } from '../../../shared/utils/templateHelper';
import { setTemplateAction } from '../actions/chartEditor';
import { templateIcons } from '../meta/templates/Icons';
import './../../../../node_modules/react-quill/dist/quill.snow.css';
import { getAllInCat, getMostPopular } from './../../../editor/core/highed.templateman';

type TemplateProps = {
  active: boolean;
};

const Template = (props: TemplateProps) => {
  const dispatch = useDispatch();
  const [selectOptions, setSelectOptions] = useState<TemplateType[]>([]);
  const [templates, setTemplates] = useState<Record<string, TemplateType>>({});
  const [activeTemplate, setActiveTemplate] = useState<TemplateType>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openSection, setOpenSection] = useState('type');
  const { aggregatedOptions, useDefaultData } = useSelector<any, any>((state: RootState) => state.projectConfig);
  const { chosenWizardTemplate } = useSelector<any, any>((state: RootState) => state.chartEditorPage);
  const { subscription } = useSelector((state: RootState) => state.profile);
  const hasBillingSetup = window.hcconfig.billing.enabled;

  const currentPlan = getPlanById(subscription?.id);
  const isFreePlan = currentPlan === SubscriptionPlans.Free;

  const getTemplates = (selectedValue: TemplateType) => {
    const templates = getAllInCat((selectedValue ?? chosenWizardTemplate).advancedParent);
    setTemplates(templates);
  };

  const getAllTemplateTypes = () => {
    let mostPopular = getMostPopular();
    mostPopular = mostPopular.map((template) => cloneDeep(template));
    mostPopular.sort((a, b) => a.basic.order - b.basic.order);
    setSelectOptions(mostPopular);

    getTemplates(chosenWizardTemplate ?? mostPopular[0]);
  };

  useEffect(() => {
    getAllTemplateTypes();
  }, []);

  const setTemplate = (template: TemplateType, useUserData: boolean) => {
    dispatch(setTemplateAction({ template, useUserData }));
    setIsModalOpen(false);
  };

  const selectTemplate = (template: TemplateType) => {
    const newType = template?.config?.chart?.type ?? '';
    const checkTypes = getCheckTypes(aggregatedOptions);
    if (!(newType in checkTypes) && !useDefaultData) {
      setIsModalOpen(true);
    } else setTemplate(template, newType in checkTypes);
  };

  const onSelectChangeTemplate = (template: TemplateType) => {
    if (isFreePlan && hasBillingSetup && template.locked) showPaidPlanModal('starter', cancelTextMap.previewChart);

    setActiveTemplate(template);
    getTemplates(template);
    selectTemplate(template);
  };

  const toggleSection = (type: string) => setOpenSection(openSection === type ? '' : type);

  return (
    <PanelContainer active={props.active} className="pt-6">
      <Modal
        isOpen={isModalOpen}
        title="New datastructure"
        onClose={() => setIsModalOpen(false)}
        width="w-[446px]"
        bg="bg-ev-yellow-1"
      >
        <p>
          This type is not compatible with the datastructure you have. You will lose the dataset you have now if you
          continue.
        </p>
        <div className="flex gap-4 mt-6">
          <PrimaryButton
            text="Okay, change to this type"
            onClick={() => setTemplate(activeTemplate as TemplateType, false)}
            buttonColor={ButtonColor.NavyBlue}
          />
          <PrimaryButton text="Cancel" onClick={() => setIsModalOpen(false)} buttonColor={ButtonColor.BabyBlue} />
        </div>
      </Modal>
      <div>
        <div>
          <div className="highed-chart-templates flex flex-col gap-2 pb-4 ev-sm:pb-0">
            <Accordion
              headerText="Chart type"
              onClick={() => toggleSection('type')}
              selected={openSection === 'type'}
              selectedChildren={chosenWizardTemplate.advancedParent}
              childrenClasses={
                'relative flex flex-col rounded py-2 ev-sm:h-[calc(100vh-340px)] ev-sm:overflow-auto pl-6 pr-2'
              }
            >
              <div className="overflow-auto pr-4">
                <div className="templates-container basic-options grid grid-cols-2 gap-2">
                  {(selectOptions || []).map((template, i) => {
                    const Icon = templateIcons[template.basic?.icon || ''];
                    return (
                      <ListOption
                        onClick={() => onSelectChangeTemplate(template)}
                        selected={chosenWizardTemplate.advancedParent === template.advancedParent}
                        icon={Icon}
                        title={template.title ?? ''}
                        key={`list_${i}`}
                        locked={isFreePlan && hasBillingSetup && template.locked}
                      />
                    );
                  })}
                </div>
              </div>
            </Accordion>

            {Object.keys(templates ?? {}).length > 1 && (
              <Accordion
                headerText="Style"
                selectedChildren={chosenWizardTemplate.advanced?.name ?? chosenWizardTemplate.title ?? ''}
                onClick={() => toggleSection('style')}
                selected={openSection === 'style'}
                childrenClasses={'relative flex flex-col rounded py-2 ev-sm:h-[calc(100vh-340px)] pl-6 pr-2'}
              >
                <div className="ev-sm:overflow-auto pr-4">
                  <div className="project-grid mb-16">
                    {Object.values(templates || {}).map((template: TemplateType, i) => {
                      return (
                        <div
                          onClick={() => selectTemplate(template)}
                          className={`advanced-template space-y-4 cursor-pointer ${
                            chosenWizardTemplate.title === template.title ? 'active' : ''
                          }`}
                          key={`advanced-template-${i}`}
                        >
                          <img
                            src={`${window.hcconfig.frontend.hostname}/static/thumbnails/${template.thumbnail}`}
                            className="mx-auto"
                          />
                          <div className="font-medium text-ev-navy-blue pt-2 text-center">
                            {template.advanced ? template.advanced.name : template.title}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Accordion>
            )}
          </div>
        </div>
      </div>
    </PanelContainer>
  );
};

export default Template;
