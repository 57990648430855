import { XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import React, { CSSProperties, forwardRef } from 'react';
import InputError from 'shared/message/InputError';
import Info from '../message/Info';

type TextInputProps = {
  name: string;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  label?: string;
  placeholder?: string;
  error?: string;
  extraClass?: string;
  infoText?: string;
  type?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  readOnly?: boolean;
  style?: CSSProperties;
  inlineLabel?: string;
  labelClass?: string;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  inputClassName?: string;
  tabIndex?: number;
  reset?: React.MouseEventHandler<SVGSVGElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  id?: string;
  errorsBlock?: React.ReactNode;
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      name,
      label,
      onChange,
      placeholder,
      value,
      error,
      extraClass,
      infoText,
      type,
      disabled,
      autoFocus,
      readOnly,
      style,
      inlineLabel,
      labelClass,
      onKeyUp,
      inputClassName,
      tabIndex,
      reset,
      id,
      onClick,
      errorsBlock,
      ...props
    },
    ref
  ) => {
    const blockClasses = classNames('form-group', extraClass, {
      'has-error': error && error.length
    });
    const inputClasses = classNames('form-control', inputClassName, {
      'ring-2 ring-ev-error': error
    });
    const labelClasses = classNames(labelClass, {
      'text-ev-error': error
    });

    return (
      <div className={blockClasses} id={id}>
        {label && (
          <label htmlFor={name} className={labelClasses}>
            {label}
          </label>
        )}
        {infoText && <Info description={infoText} />}
        <div className="field relative">
          {inlineLabel && <span className={'form-group ' + labelClass}>{inlineLabel} &nbsp;</span>}
          <input
            ref={ref}
            type={type || 'text'}
            name={name}
            disabled={disabled || false}
            className={inputClasses}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            autoFocus={autoFocus}
            style={style}
            onKeyUp={onKeyUp}
            tabIndex={tabIndex}
            readOnly={readOnly}
            onClick={onClick}
            {...props}
          />
          {reset && (
            <XIcon
              onClick={reset}
              className={`text-gray-500 hover:text-gray-700 absolute top-2 right-1 w-6 h-6 transition duration-75
              ${value.length >= 1 ? 'opacity-100 cursor-pointer' : 'opacity-0 pointer-events-none'}`}
            />
          )}
        </div>
        {error && !errorsBlock && <InputError error={error} />}
        {errorsBlock}
      </div>
    );
  }
);

export default TextInput;
