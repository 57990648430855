import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubscriptionPlans } from '../../../pages/SubscriptionPage/types/plansModels';
import { getPlanById } from '../../../pages/SubscriptionPage/utils/plansHelper';
import { showSignupModalAction } from '../../../redux/actions/projectConfig';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import { RootState } from '../../../redux/store';
import { checkCreatePermission, checkPermission } from '../../../utils/profileHelper';
import PrimaryButton from '../../buttons/PrimaryButton';
import { ButtonColor, ButtonType } from '../../buttons/types/ButtonModels';
import PermissionModal from '../../modal/PermissionModal';
import useToggle from '../../utils/useToggle';
import { hideElementHideButton } from '../../wizard/editor/HideElementButton';
import { getPublishStatus, onPublish } from '../publishHelper';

type ProjectPublishButtonProps = {
  className?: string;
};

export default function ProjectPublishButton({ className }: ProjectPublishButtonProps) {
  const dispatch = useDispatch();
  const projectConfig: RootState['projectConfig'] = useSelector(getProjectConfig);
  const { saved, published, publishLoading, changeMade, lastPublishTime, lastSaved } = projectConfig;

  const { type } = useSelector((state: RootState) => state.projectConfig);
  const { chosenWizardTemplate } = useSelector((state: RootState) => state.chartEditorPage);
  const profile = useSelector((state: RootState) => state.profile);
  const { user, team, subscription } = profile;
  const [open, toggleOpen] = useToggle(false);
  const { buttonText, light } = getPublishStatus(published, saved, changeMade, lastPublishTime, lastSaved);
  const [showPermissionModal, toggleShowPermissionModal] = useToggle();

  const currentPlan = getPlanById(subscription?.id);
  const isFreePlan = currentPlan === SubscriptionPlans.Free;
  const anonymous = !user && !team;
  const hasPublishPermission = anonymous || checkPermission('publish');
  const hasBillingSetup = window.hcconfig.billing.enabled;
  const hasChartTypeSavePermission =
    !(isFreePlan && hasBillingSetup && (chosenWizardTemplate as any)?.locked) && checkCreatePermission(type);

  const showSignupModal = () => dispatch(showSignupModalAction());

  const togglePublishSection = () => {
    toggleOpen();
    hideElementHideButton();
  };

  const handlePublish = () => {
    if (!team) {
      showSignupModal();
      return;
    }
    if (!hasPublishPermission || !hasChartTypeSavePermission) {
      toggleShowPermissionModal(true);
      return;
    }
    if (!open) togglePublishSection();
    onPublish(type, dispatch);
  };

  return (
    <>
      <PrimaryButton
        onClick={handlePublish}
        useLoader={{
          default: {
            text: buttonText,
            icon: 'circle-arrow-up'
          },
          loading: {
            text: buttonText,
            check: publishLoading
          },
          done: {
            text: buttonText,
            icon: 'rotate',
            check: published
          }
        }}
        buttonColor={light ? ButtonColor.White : ButtonColor.NavyBlue}
        buttonType={ButtonType.Info}
        className={'text-sm ' + className}
        showPermissionLock={!hasPublishPermission || !hasChartTypeSavePermission}
        text={buttonText}
      />
      {showPermissionModal && <PermissionModal isOpen={showPermissionModal} onClose={toggleShowPermissionModal} />}
    </>
  );
}
