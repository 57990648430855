import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Options from './Options';

export type PanelOptionProps = {
  id?: string;
  name?: string;
  element: React.ReactNode;
  permission?: string;
  mapDisabled?: boolean;
  locationMapDisabled?: boolean;
};

type OptionsPanelProps = {
  panelOptions: Array<PanelOptionProps>;
  options: any;
  customizeOptions?: any;
};

export default (props: OptionsPanelProps) => {
  const { tab } = useSelector((state: RootState) => state.projectConfig);
  const { panelOptions, options, customizeOptions } = props;

  return (
    <Options activePanel={tab} options={options} panelOptions={panelOptions} customizeOptions={customizeOptions} />
  );
};
