/* eslint-disable @typescript-eslint/no-var-requires */
const colors = require('tailwindcss/colors');
const forms = require('@tailwindcss/forms');
const lineclamp = require('@tailwindcss/line-clamp');

const evDarkPurple = '#6D5BD1';
const evLightPurple = '#DDD8F5';
const evNavyBlue2 = '#28277E';

module.exports = {
  mode: 'jit',
  important: true,
  content: [
    'index.ejs',
    './src/pages/**/*.{js,ts,tsx}',
    './src/components/**/*.{js,ts,tsx}',
    './src/editor/**/*.{js,ts,tsx}',
    './src/shared/**/*.{js,ts,tsx}',
    './src/routes/**/*.{js,ts,tsx}'
  ],
  theme: {
    extend: {
      fontFamily: {
        brand: ['"RadioGrotesk Regular"', 'sans-serif']
      },
      spacing: {
        28: '7.5rem'
      },
      dropShadow: {
        'color-widget-shadow': '0px 2px 2px rgba(0, 0, 0, 0.25)',
        'number-input': '0px 1px 2px rgba(0, 0, 0, 0.25)'
      },
      fontSize: {
        '2xs': [
          '0.63rem',
          {
            lineHeight: '1rem'
          }
        ],
        '3xl': [
          '2rem',
          {
            lineHeight: '2.25rem'
          }
        ],
        '4xl': [
          '2.5rem',
          {
            lineHeight: '2.5rem'
          }
        ]
      },
      colors: {
        bluegray: colors.slate,
        gray: colors.gray,
        dimmed: '#172b4cbf',
        'ev-yellow-1': 'var(--primary-background)',
        'ev-yellow-2': '#EBC645',
        'ev-yellow-3': '#E0AC27',
        'ev-yellow-4': '#FDD548',
        'ev-yellow-5': '#F7F1E1',
        'ev-yellow-6': '#fbf6eb',
        'ev-yellow-7': '#faf6ec',
        'ev-red': '#BC094A',
        'ev-red-1': '#AD063A',
        'ev-red-2': '#FF495E',
        'ev-dark-red': '#9F042A',
        'ev-blue': '#25386E',
        'ev-light-blue': '#49B9FF',
        'ev-lighter-blue': '#67B9F9',
        'ev-dark-blue': '#2A2383',
        'ev-navy-blue': '#172B4C',
        'ev-navy-blue-2': evNavyBlue2,
        'ev-navy-blue-3': '#141358', // Used for hovers on buttons
        'ev-navy-blue-4': '#25376E',
        'ev-baby-blue': '#B4DFFC',
        'ev-baby-blue-2': '#AEC8FA',
        'ev-baby-blue-3': '#E7EEFE',
        'ev-dark-green': '#1F5A63',
        'hc-green': '#90ef7f',
        rose: '#F43F5E',
        'ev-dark-purple': evDarkPurple,
        'ev-dark-purple-2': '#7256d8',
        'ev-grey': '#F7F8F8',
        'ev-grey-2': '#E8E8E8',
        'ev-grey-3': '#D4D4D4',
        'ev-grey-4': '#444444',
        'ev-grey-5': '#C4C4C4',
        'ev-grey-6': '#808080',
        'ev-light-orange': '#F8D6BB',
        'ev-light-purple': evLightPurple,
        'ev-light-red': '#F9DCE7',
        'ev-green': '#2F5962',
        'ev-danger-red': '#E74C3C',
        'ev-error': '#F24A40',
        'ev-alert-notice': '#F89942'
      },
      scale: {
        101: '1.01',
        102: '1.02',
        103: '1.03',
        104: '1.04'
      },
      screens: {
        'ev-xs': '360px',
        'ev-sm': '743px',
        'ev-sm-max': {
          max: '743px'
        },
        'ev-md': '996px',
        'ev-lg': '1096px',
        'ev-xl': '1485px'
      },
      gridTemplateColumns: {
        // Simple 16 column grid
        radio: '2.5em auto',
        checkbox: '1.8em auto'
      },
      lineHeight: {
        radio: '1.1'
      },
      boxShadow: {
        'ev-standard': '0px 1px 5px rgba(0, 0, 0, 0.12)',
        'ev-inset': '0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset',
        radio: `inset 1em 1em ${evDarkPurple}`,
        'transparent-button': `0px 0px 0px 1px ${evNavyBlue2}`,
        light: `inset 0px 0px 0px 1px ${evDarkPurple}`,
        dark: `inset 0px 0px 0px 1px ${evLightPurple}`,
        top: '0px -2px 4px rgba(0, 0, 0, 0.15)',
        bottom: '0px 2px 4px rgba(0, 0, 0, 0.15)',
        'ev-marker': '0px 1px 15px 6px rgba(0, 0, 0, 0.06)'
      }
    }
  },
  plugins: [forms, lineclamp]
};
