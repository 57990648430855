import { cloneDeep } from 'lodash';
import { adjustSeriesAssignsAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { getFilteredValue, getIndexFromPath } from './widgetHelper';

export default ({ dispatch, props, seriesAssigns }) => {
  const getValue = () => {
    const { option, parentPath, chart } = props;

    let index = getIndexFromPath(parentPath);
    let type = getFilteredValue(option, chart, index);

    return {
      label: type.charAt(0).toUpperCase() + type.slice(1),
      value: type
    };
  };

  const changeProp = (value, onChange, parentPath, option) => {
    onChange({
      val: value.val,
      optional: { id: `${parentPath}.${option.id}` }
    });
    dispatch(adjustSeriesAssignsAction({ seriesAssigns }));
  };

  const handleRefersTo = (option, parentPath) => {
    if (option.refersTo) {
      const refersTo = cloneDeep(option.refersTo);
      Object.values(refersTo).forEach((refersToType) => {
        Object.values(refersToType).forEach((referToValue) => {
          // Handle sets
          Object.keys(referToValue.set).forEach((key) => {
            referToValue.set[key.replace('series[n]', parentPath)] = referToValue.set[key];
            delete referToValue.set[key];
          });

          //Handle gets
          if (referToValue.get) {
            referToValue.get = referToValue.get.map((get) => {
              Object.keys(get).forEach((key) => {
                get[key.replace('series[n]', parentPath)] = get[key];
                delete get[key];
              });
              return get;
            });
          }
        });
      });
      return refersTo;
    }
    return null;
  };

  const getChartTypeFilterOptions = (option, chart, parentPath) => {
    const options = option.options;
    let index = getIndexFromPath(parentPath);
    let type = getFilteredValue(option, chart, index);

    return options
      .map((option) => {
        if (option.subType.indexOf(type) > -1) {
          return {
            ...option,
            id: `${option.id.replace('series[n]', parentPath)}`,
            refersTo: handleRefersTo(option, parentPath)
          };
        }
        return null;
      })
      .filter((d) => d);
  };

  const { option, chart, parentPath, onChange } = props;
  const filteredOptions = {
    ...option,
    filteredOptions: getChartTypeFilterOptions(option, chart, parentPath)
  };

  return {
    selectOptions: option.values || [],
    selectValue: getValue(props),
    onChangeSelect: (value) => changeProp(value, onChange, parentPath, option),
    filteredOptions
  };
};
