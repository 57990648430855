import { TemplateProps } from '../types';

const Location: TemplateProps = {
  title: 'Location map',
  description: [
    'Point maps can be used to show the distribution of data over a geographical region, by placing equally sized markers over the region.'
  ],
  constructor: 'Map',
  thumbnail: 'mappoint.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  locked: true,
  config: {},
  sampleSet: 'mappoint',
  dataValidator: false,
  loadForEachSeries: false,
  defaultDataType: 'locationMap',
  isMap: true,
  provider: 'locationMap',
  advancedParent: 'Location map',
  basic: {
    order: 4,
    thumbnail: 'point_map.svg',
    icon: 'LocationMap'
  }
};

export default Location;
