import { TemplateProps } from '../../types';

const Sankey: TemplateProps = {
  title: 'Sankey',
  description: '',
  constructor: '',
  thumbnail: 'sankey.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'sankey'
    }
  },
  plugins: ['sankey'],
  sampleSet: 'sankey',
  advancedParent: 'Sankey',
  basic: {
    order: 11,
    thumbnail: 'sankey_diagram.svg',
    icon: 'Sankey'
  },
  dataValidator: false,
  parent: 'More',
  locked: true
};

export default Sankey;
