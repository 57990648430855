
/**
 * This file was generated by bin/create-schema.js
 *
 * Generated on Mon Aug 12 2024 13:11:34 GMT+0200 (Central European Summer Time)
 */
import api from './../utils/ajax';

/**
 * To keep everything in one place..
 */
export const login = api.login;

/**
 * To keep everything in one place
 */
export const logout = api.logout;

/**
 *
 */
export const isLoggedIn = api.isLoggedIn;

/**
 * 
 * Express path: /login/saml/:domain
 */
export const getLoginSamlWithDomain = (domain) => {
  return api.ajax({
    url: `/login/saml/${domain}`,
    type: 'get'
  });
};

/**
 * The actual google login route
 * Express path: /login/google
 */
export const getLoginGoogle = () => {
  return api.ajax({
    url: `/login/google`,
    type: 'get'
  });
};

/**
 * Note that this is not called by Google, but rather by the frontend
Google login callback
 * Express path: /login/google/callback
 */
export const getLoginGoogleCallback = (data) => {
  return api.ajax({
    url: `/login/google/callback`,
    type: 'get',
    data: data
  });
};

/**
 * Create a new account
 * Requires captcha response
 * Express path: /account
 */
export const postAccount = (data) => {
  return api.ajax({
    url: `/account`,
    type: 'post',
    data: data
  });
};

/**
 * Do a forgot password thing
 * Express path: /account/forgotpassword
 */
export const postAccountForgotpassword = (data) => {
  return api.ajax({
    url: `/account/forgotpassword`,
    type: 'post',
    data: data
  });
};

/**
 * Do a password change
body needs a token + password
 * Express path: /account/changepassword/:token
 */
export const postAccountChangepasswordUsingToken = (token, data) => {
  return api.ajax({
    url: `/account/changepassword/${token}`,
    type: 'post',
    data: data
  });
};

/**
 * Start e-mail verification for the logged in user
 * Requires that the user is logged in
 * Express path: /account/requestverification
 */
export const postAccountRequestverification = () => {
  return api.ajax({
    url: `/account/requestverification`,
    type: 'post'
  });
};

/**
 * Do account verification
 * Express path: /account/verify/:token
 */
export const postAccountVerifyUsingToken = (token) => {
  return api.ajax({
    url: `/account/verify/${token}`,
    type: 'post'
  });
};

/**
 * 
 * Requires that the user is logged in
 * Express path: /account/announcements
 */
export const getAccountAnnouncements = () => {
  return api.ajax({
    url: `/account/announcements`,
    type: 'get'
  });
};

/**
 * Check if organisation eligible for SSO
body needs an email
 * Express path: /account/organisation
 */
export const postAccountOrganisation = (data) => {
  return api.ajax({
    url: `/account/organisation`,
    type: 'post',
    data: data
  });
};

/**
 * Redeem a free trial token
 * Requires that the user is logged in
 * Express path: /freetrial
 */
export const getFreetrial = () => {
  return api.ajax({
    url: `/freetrial`,
    type: 'get'
  });
};

/**
 * Check if user has valid CMS license
body needs a license
 * Express path: /account/license
 */
export const postAccountLicense = (data) => {
  return api.ajax({
    url: `/account/license`,
    type: 'post',
    data: data
  });
};

/**
 * Get teams id using cms license
 * Requires that the user is logged in
 * Express path: /team
 */
export const getTeam = () => {
  return api.ajax({
    url: `/team`,
    type: 'get'
  });
};

/**
 * Get teams cms license
 * Requires that the user is logged in
 * Express path: /team/:teamid/license
 */
export const getTeamLicenseWithTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/license`,
    type: 'get'
  });
};

/**
 * Generate a license for the team
 * Requires that the user is logged in
 * Express path: /team/:teamid/license
 */
export const postTeamLicenseUsingTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/license`,
    type: 'post'
  });
};

/**
 * Delete a cms license for the team
 * Requires that the user is logged in
 * Express path: /team/:teamid/license/:licenseid
 */
export const delTeamLicenseUsingTeamidAndLicenseid = (teamid, licenseid) => {
  return api.ajax({
    url: `/team/${teamid}/license/${licenseid}`,
    type: 'delete'
  });
};

/**
 * Get the roles available for a given team
 * Requires that the user is logged in
 * Returns an object with the following properties:
  *   - (number) id: The role ID
  *   - (string) name: The role name
 * Express path: /team/:teamid/roles
 */
export const getTeamRolesWithTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/roles`,
    type: 'get'
  });
};

/**
 * Delete a role
 * Requires that the user is logged in
 * Express path: /team/:teamid/role/:roleid
 */
export const delTeamRoleUsingTeamidAndRoleid = (teamid, roleid) => {
  return api.ajax({
    url: `/team/${teamid}/role/${roleid}`,
    type: 'delete'
  });
};

/**
 * Add a new role to a team
 * Requires that the user is logged in
 * Express path: /team/:teamid/role
 */
export const postTeamRoleUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}/role`,
    type: 'post',
    data: data
  });
};

/**
 * Update a team role
 * Requires that the user is logged in
 * Express path: /team/:teamid/role/:roleid
 */
export const postTeamRoleUsingTeamidAndRoleid = (teamid, roleid, data) => {
  return api.ajax({
    url: `/team/${teamid}/role/${roleid}`,
    type: 'post',
    data: data
  });
};

/**
 * Add a permission to a role
 * Requires that the user is logged in
 * Express path: /team/:teamid/role/:roleid/permission/:permissionid
 */
export const postTeamRolePermissionUsingTeamidAndRoleidAndPermissionid = (teamid, roleid, permissionid) => {
  return api.ajax({
    url: `/team/${teamid}/role/${roleid}/permission/${permissionid}`,
    type: 'post'
  });
};

/**
 * Remove a permission from a role
 * Requires that the user is logged in
 * Express path: /team/:teamid/role/:roleid/permission/:permissionid
 */
export const delTeamRolePermissionUsingTeamidAndRoleidAndPermissionid = (teamid, roleid, permissionid) => {
  return api.ajax({
    url: `/team/${teamid}/role/${roleid}/permission/${permissionid}`,
    type: 'delete'
  });
};

/**
 * Get the permissions for a given role
 * Requires that the user is logged in
 * Returns an object with the following properties:
  *   - (string) name: The name of the permission
  *   - (string) description: The permission description
 * Express path: /team/:teamid/role/:roleid/permissions
 */
export const getTeamRolePermissionsWithTeamidAndRoleid = (teamid, roleid) => {
  return api.ajax({
    url: `/team/${teamid}/role/${roleid}/permissions`,
    type: 'get'
  });
};

/**
 * Get the members of a given role
 * Requires that the user is logged in
 * Express path: /team/:teamid/role/:roleid/members
 */
export const getTeamRoleMembersWithTeamidAndRoleid = (teamid, roleid) => {
  return api.ajax({
    url: `/team/${teamid}/role/${roleid}/members`,
    type: 'get'
  });
};

/**
 * Set the group for a given user
 * Requires that the user is logged in
 * Express path: /team/:teamid/role/:roleid/user/:userid
 */
export const postTeamRoleUserUsingTeamidAndRoleidAndUserid = (teamid, roleid, userid) => {
  return api.ajax({
    url: `/team/${teamid}/role/${roleid}/user/${userid}`,
    type: 'post'
  });
};

/**
 * Get all permissions available
 * Requires that the user is logged in
 * Returns an object with the following properties:
  *   - (number) id: The ID of the permission
  *   - (string) name: The permission name
  *   - (string) description: The permission description
 * Express path: /permissions
 */
export const getPermissions = () => {
  return api.ajax({
    url: `/permissions`,
    type: 'get'
  });
};

/**
 * Get active api keys
 * Requires that the user is logged in
 * Express path: /team/:teamid/keys
 */
export const getTeamKeysWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/keys?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Delete/revoke an API key
 * Requires that the user is logged in
 * Express path: /team/:teamid/key/:keyid
 */
export const delTeamKeyUsingTeamidAndKeyid = (teamid, keyid) => {
  return api.ajax({
    url: `/team/${teamid}/key/${keyid}`,
    type: 'delete'
  });
};

/**
 * Generate a new API key
 * Requires that the user is logged in
 * Express path: /team/:teamid/keys/generate
 */
export const postTeamKeysGenerateUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}/keys/generate`,
    type: 'post',
    data: data
  });
};

/**
 * Update an API key
 * Requires that the user is logged in
 * Express path: /team/:teamid/key/:keyid
 */
export const postTeamKeyUsingTeamidAndKeyid = (teamid, keyid, data) => {
  return api.ajax({
    url: `/team/${teamid}/key/${keyid}`,
    type: 'post',
    data: data
  });
};

/**
 * Get the active plan for a team
 * Express path: /admin/billing/team/:teamid/plan
 */
export const getAdminBillingTeamPlanWithTeamid = (teamid) => {
  return api.ajax({
    url: `/admin/billing/team/${teamid}/plan`,
    type: 'get'
  });
};

/**
 * Get teams with billing info
 * Express path: /admin/billing/teams
 */
export const getAdminBillingTeams = () => {
  return api.ajax({
    url: `/admin/billing/teams`,
    type: 'get'
  });
};

/**
 * Get billing permissions
 * Express path: /admin/billing/permissions
 */
export const getAdminBillingPermissions = (page, pageSize) => {
  return api.ajax({
    url: `/admin/billing/permissions?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get billing config
 * Express path: /admin/billing/config
 */
export const getAdminBillingConfig = () => {
  return api.ajax({
    url: `/admin/billing/config`,
    type: 'get'
  });
};

/**
 * Create a new plan
- If billing is set to recurly, the sub should have a pending status
- Need to validate the ID&#x27;s somehow
 * Express path: /admin/billing/plan
 */
export const postAdminBillingPlan = (data) => {
  return api.ajax({
    url: `/admin/billing/plan`,
    type: 'post',
    data: data
  });
};

/**
 * Search for plan by name
 * Express path: /admin/billing/plans/search
 */
export const getAdminBillingPlansSearch = (page, pageSize, query) => {
  return api.ajax({
    url: `/admin/billing/plans/search?page=${page}&pageSize=${pageSize}&query=${query}`,
    type: 'get'
  });
};

/**
 * Get all plans
 * Express path: /admin/billing/plans
 */
export const getAdminBillingPlans = (page, pageSize) => {
  return api.ajax({
    url: `/admin/billing/plans?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Create a subscription for a team based on a plan id
Must take any existing subs into consideration.
Must also allow for assigning a new team to the given plan.
 * Express path: /admin/billing/createsub
 */
export const postAdminBillingCreatesub = (data) => {
  return api.ajax({
    url: `/admin/billing/createsub`,
    type: 'post',
    data: data
  });
};

/**
 * Webhooks
 * Express path: /billing/providerhook
 */
export const postBillingProviderhook = () => {
  return api.ajax({
    url: `/billing/providerhook`,
    type: 'post'
  });
};

/**
 * Get plan listing
 * Express path: /billing/plans
 */
export const getBillingPlans = () => {
  return api.ajax({
    url: `/billing/plans`,
    type: 'get'
  });
};

/**
 * Get a teams plan permissions list
 * Express path: /team/:teamid/billing/permissions
 */
export const getTeamBillingPermissionsWithTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/billing/permissions`,
    type: 'get'
  });
};

/**
 * Get all plans available for a team
 * Requires that the user is logged in
 * Express path: /team/:teamid/plans
 */
export const getTeamPlansWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/plans?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all plans
 * Express path: /plans
 */
export const getPlans = (page, pageSize) => {
  return api.ajax({
    url: `/plans?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get the subscription summary
 * Express path: /team/:teamid/subscription/summary
 */
export const getTeamSubscriptionSummaryWithTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/subscription/summary`,
    type: 'get'
  });
};

/**
 * Get all subscriptions for a team
 * Requires that the user is logged in
 * Express path: /team/:teamid/subscriptions
 */
export const getTeamSubscriptionsWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/subscriptions?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get the active subscription for a team
 * Requires that the user is logged in
 * Express path: /team/:teamid/subscription/active
 */
export const getTeamSubscriptionActiveWithTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/subscription/active`,
    type: 'get'
  });
};

/**
 * Get billing info
 * Requires that the user is logged in
 * Express path: /team/:teamid/billing/info
 */
export const getTeamBillingInfoWithTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/billing/info`,
    type: 'get'
  });
};

/**
 * Lookup coupon code
 * Express path: /coupon/:couponcode
 */
export const getCouponWithCouponcode = (couponcode) => {
  return api.ajax({
    url: `/coupon/${couponcode}`,
    type: 'get'
  });
};

/**
 * Update billing info
 * Requires captcha response
 * Express path: /team/:teamid/billing/info/:token
 */
export const postTeamBillingInfoUsingTeamidAndToken = (teamid, token, data) => {
  return api.ajax({
    url: `/team/${teamid}/billing/info/${token}`,
    type: 'post',
    data: data
  });
};

/**
 * Subscribe a team to a plan
 * Requires captcha response
 * Express path: /team/:teamid/subscribe/:planid
 */
export const postTeamSubscribeUsingTeamidAndPlanid = (teamid, planid, data) => {
  return api.ajax({
    url: `/team/${teamid}/subscribe/${planid}`,
    type: 'post',
    data: data
  });
};

/**
 * 
 * Requires captcha response
 * Express path: /team/:teamid/subscription/cancel/pending
 */
export const postTeamSubscriptionCancelPendingUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}/subscription/cancel/pending`,
    type: 'post',
    data: data
  });
};

/**
 * Cancel active subscription
Should turn off renewal on the subscription.
 * Requires that the user is logged in
 * Express path: /team/:teamid/subscription/cancel
 */
export const postTeamSubscriptionCancelUsingTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/subscription/cancel`,
    type: 'post'
  });
};

/**
 * Get the account id for a team
 * Requires that the user is logged in
 * Express path: /team/:teamid/billing/accountid
 */
export const getTeamBillingAccountidWithTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/billing/accountid`,
    type: 'get'
  });
};

/**
 * Get all adjustments records for a team
 * Requires that the user is logged in
 * NOTE: This is not implemented in the backend yet
 * Express path: /team/:teamid/billing/adjustments
 */
export const getTeamBillingAdjustmentsWithTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/billing/adjustments`,
    type: 'get'
  });
};

/**
 * Redeem a coupon code for an account
 * Requires that the user is logged in
 * Express path: /team/:teamid/billing/coupon
 */
export const postTeamBillingCouponUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}/billing/coupon`,
    type: 'post',
    data: data
  });
};

/**
 * Download Invoice
 * Requires that the user is logged in
 * Express path: /team/:teamid/invoice/:invoice
 */
export const getTeamInvoiceWithTeamidAndInvoice = (teamid, invoice) => {
  return api.download({
    url: `/team/${teamid}/invoice/${invoice}`,
    type: 'get'
  });
};

/**
 * Generate embed/inject snippets for a chart uuid
 * Requires that the user is logged in
 * Express path: /chart/templates/:uuid
 */
export const getChartTemplatesWithUuid = (uuid) => {
  return api.ajax({
    url: `/chart/templates/${uuid}`,
    type: 'get'
  });
};

/**
 * Get all the charts for which the user is a collaborator
 * Requires that the user is logged in
 * Express path: /profile/charts/collab
 */
export const getProfileChartsCollab = (page, pageSize) => {
  return api.ajax({
    url: `/profile/charts/collab?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Check if a user has permission to edit a chart
 * Requires that the user is logged in
 * Express path: /chart/accessto/:uuid
 */
export const getChartAccesstoWithUuid = (uuid) => {
  return api.ajax({
    url: `/chart/accessto/${uuid}`,
    type: 'get'
  });
};

/**
 * Updates data info
 * Express path: /:teamid/data/:dataid
 */
export const postDataUsingTeamidAndDataid = (teamid, dataid, data) => {
  return api.ajax({
    url: `/${teamid}/data/${dataid}`,
    type: 'post',
    data: data
  });
};

/**
 * Creates new data info
 * Express path: /:teamid/data
 */
export const postDataUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/${teamid}/data`,
    type: 'post',
    data: data
  });
};

/**
 * Get all data by team
 * Express path: /:teamid/data
 */
export const getDataWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/${teamid}/data?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * 
 * Express path: /data/:hash
 */
export const getDataWithHash = (hash) => {
  return api.ajax({
    url: `/data/${hash}`,
    type: 'get'
  });
};

/**
 * Create a new chart
 * Requires that the user is logged in
 * Express path: /team/:teamid/chart
 */
export const postTeamChartUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}/chart`,
    type: 'post',
    data: data
  });
};

/**
 * Get the view history for a chart
 * Requires that the user is logged in
 * Express path: /team/:teamid/chart/:chartid/views
 */
export const getTeamChartViewsWithTeamidAndChartid = (teamid, chartid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/chart/${chartid}/views?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get a single public chart version.
 * Returns an object with the following properties:
  *   - (number) uuid: The hash of the chart
  *   - (number) view_count: The view counter for the chart
  *   - (number) version: The chart version
  *   - (date) creation_time: The timestamp for this version
  *   - (json) data: The chart data
  *   - (string) thumbnail_url: The URL for the latest thumbnail
  *   - (string) username: The username for the owner
 * Express path: /show/chart/:uuid/:version
 */
export const getShowChartWithUuidAndVersion = (uuid, version) => {
  return api.ajax({
    url: `/show/chart/${uuid}/${version}`,
    type: 'get'
  });
};

/**
 * Get a single public chart - fetches the latest version.
 * Returns an object with the following properties:
  *   - (number) uuid: The hash of the chart
  *   - (number) view_count: The view counter for the chart
  *   - (number) version: The chart version
  *   - (date) creation_time: The timestamp for this version
  *   - (json) data: The chart data
  *   - (string) thumbnail_url: The URL for the latest thumbnail
 * Express path: /show/chart/:uuid
 */
export const getShowChartWithUuid = (uuid) => {
  return api.ajax({
    url: `/show/chart/${uuid}`,
    type: 'get'
  });
};

/**
 * Get a single chart - fetches the latest version
 * Returns an object with the following properties:
  *   - (number) chart_id: The ID of the chart
  *   - (number) view_count: The view counter for the chart
  *   - (number) version: The chart version
  *   - (date) creation_time: The timestamp for this version
  *   - (json) data: The chart data
  *   - (string) thumbnail_url: The URL for the latest thumbnail
  *   - (boolean) private: Wether or not the chart is private
  *   - (string) username: The username for the owner
 * Express path: /team/:teamid/chart/:chartid
 */
export const getTeamChartWithTeamidAndChartid = (teamid, chartid) => {
  return api.ajax({
    url: `/team/${teamid}/chart/${chartid}`,
    type: 'get'
  });
};

/**
 * Get a single chart - with a specified version
 * Returns an object with the following properties:
  *   - (number) chart_id: The ID of the chart
  *   - (number) view_count: The view counter for the chart
  *   - (number) version: The chart version
  *   - (date) creation_time: The timestamp for this version
  *   - (json) data: The chart data
  *   - (string) thumbnail_url: The URL for the latest thumbnail
  *   - (boolean) private: Wether or not the chart is private
  *   - (string) username: The username for the owner
 * Express path: /team/:teamid/chart/:chartid/:versionid
 */
export const getTeamChartWithTeamidAndChartidAndVersionid = (teamid, chartid, versionid) => {
  return api.ajax({
    url: `/team/${teamid}/chart/${chartid}/${versionid}`,
    type: 'get'
  });
};

/**
 * Get a single chart - with a specified version, only works for public charts
 * Returns an object with the following properties:
  *   - (number) chart_id: The ID of the chart
  *   - (number) view_count: The view counter for the chart
  *   - (number) version: The chart version
  *   - (date) creation_time: The timestamp for this version
  *   - (json) data: The chart data
  *   - (string) thumbnail_url: The URL for the latest thumbnail
  *   - (boolean) private: Wether or not the chart is private
  *   - (string) username: The username for the owner
 * Express path: /chart/:chartid/:versionid
 */
export const getChartWithChartidAndVersionid = (chartid, versionid) => {
  return api.ajax({
    url: `/chart/${chartid}/${versionid}`,
    type: 'get'
  });
};

/**
 * Get all the charts a user has access to in a given team
 * Requires that the user is logged in
 * Express path: /team/:teamid/charts
 */
export const getTeamChartsWithTeamid = (teamid, orderBy, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/charts?orderBy=${orderBy}&page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all published charts a user has access to in a given team
 * Requires that the user is logged in
 * Express path: /team/:teamid/charts/published
 */
export const getTeamChartsPublishedWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/charts/published?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all published project a user has access to in a given team
 * Requires that the user is logged in
 * Express path: /team/:teamid/projects/published
 */
export const getTeamProjectsPublishedWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/projects/published?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all published projects a user has access to in a given team
 * Requires that the user is logged in
 * Express path: /team/:teamid/projects/search/published
 */
export const getTeamProjectsSearchPublishedWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/projects/search/published?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all the charts a user has access to in a given team with no division set
 * Requires that the user is logged in
 * Express path: /team/:teamid/division/charts/all
 */
export const getTeamDivisionChartsAllWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/division/charts/all?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all the charts a user has access to in a given division
 * Requires that the user is logged in
 * Express path: /division/:divisionid/charts
 */
export const getDivisionChartsWithDivisionid = (divisionid, orderBy, page, pageSize) => {
  return api.ajax({
    url: `/division/${divisionid}/charts?orderBy=${orderBy}&page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all the deleted charts in a team
 * Requires that the user is logged in
 * Express path: /division/:divisionid/charts/deleted
 */
export const getDivisionChartsDeletedWithDivisionid = (divisionid, page, pageSize) => {
  return api.ajax({
    url: `/division/${divisionid}/charts/deleted?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all the deleted charts in a team
 * Requires that the user is logged in
 * Express path: /team/:teamid/charts/deleted
 */
export const getTeamChartsDeletedWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/charts/deleted?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all the public charts of a team
 * Express path: /team/:teamid/charts/public
 */
export const getTeamChartsPublicWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/charts/public?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Delete a chart
 * Express path: /team/:teamid/chart/:chartid
 */
export const delTeamChartUsingTeamidAndChartid = (teamid, chartid) => {
  return api.ajax({
    url: `/team/${teamid}/chart/${chartid}`,
    type: 'delete'
  });
};

/**
 * Undelete a chart
 * Express path: /team/:teamid/chart/:chartid/recover
 */
export const postTeamChartRecoverUsingTeamidAndChartid = (teamid, chartid) => {
  return api.ajax({
    url: `/team/${teamid}/chart/${chartid}/recover`,
    type: 'post'
  });
};

/**
 * Duplicate chart
 * Requires that the user is logged in
 * Express path: /team/:teamid/chart/:chartid/duplicate
 */
export const postTeamChartDuplicateUsingTeamidAndChartid = (teamid, chartid, data) => {
  return api.ajax({
    url: `/team/${teamid}/chart/${chartid}/duplicate`,
    type: 'post',
    data: data
  });
};

/**
 * Update chart meta
 * Express path: /team/:teamid/chartmeta/:chartid
 */
export const postTeamChartmetaUsingTeamidAndChartid = (teamid, chartid, data) => {
  return api.ajax({
    url: `/team/${teamid}/chartmeta/${chartid}`,
    type: 'post',
    data: data
  });
};

/**
 * Update chart division
 * Requires that the user is logged in
 * Express path: /team/:teamid/chartdivision/:chartid
 */
export const postTeamChartdivisionUsingTeamidAndChartid = (teamid, chartid, data) => {
  return api.ajax({
    url: `/team/${teamid}/chartdivision/${chartid}`,
    type: 'post',
    data: data
  });
};

/**
 * Update a chart version
 * Express path: /team/:teamid/chart/:chartid
 */
export const postTeamChartUsingTeamidAndChartid = (teamid, chartid, data) => {
  return api.ajax({
    url: `/team/${teamid}/chart/${chartid}`,
    type: 'post',
    data: data
  });
};

/**
 * Get all the charts within a team with no divisions! (search)
 * Express path: /team/:teamid/division/charts/all/search
 */
export const getTeamDivisionChartsAllSearchWithTeamid = (teamid, query, tags, creator, orderBy, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/division/charts/all/search?query=${query}&tags=${tags}&creator=${creator}&orderBy=${orderBy}&page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all the charts within a team! (search)
 * Requires that the user is logged in
 * Express path: /team/:teamid/charts/search
 */
export const getTeamChartsSearchWithTeamid = (teamid, query, tags, creator, orderBy, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/charts/search?query=${query}&tags=${tags}&creator=${creator}&orderBy=${orderBy}&page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all the charts within a division! (search)
 * Express path: /division/:divisionid/charts/search
 */
export const getDivisionChartsSearchWithDivisionid = (divisionid, query, tags, creator, orderBy, page, pageSize) => {
  return api.ajax({
    url: `/division/${divisionid}/charts/search?query=${query}&tags=${tags}&creator=${creator}&orderBy=${orderBy}&page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all the chart tags
 * Express path: /charts/:teamid/tags
 */
export const getChartsTagsWithTeamid = (teamid) => {
  return api.ajax({
    url: `/charts/${teamid}/tags`,
    type: 'get'
  });
};

/**
 * Get private chart
 * Express path: /private/chart/:chartid
 */
export const postPrivateChartUsingChartid = (chartid, data) => {
  return api.ajax({
    url: `/private/chart/${chartid}`,
    type: 'post',
    data: data
  });
};

/**
 * Get compiled chart by id
 * Requires that the user is logged in
 * Express path: /team/:teamid/compiled/chart/:chartid
 */
export const getTeamCompiledChartWithTeamidAndChartid = (teamid, chartid) => {
  return api.ajax({
    url: `/team/${teamid}/compiled/chart/${chartid}`,
    type: 'get'
  });
};

/**
 * Get share chart
 * Express path: /share/:uuid
 */
export const getShareWithUuid = (uuid) => {
  return api.ajax({
    url: `/share/${uuid}`,
    type: 'get'
  });
};

/**
 * Get share package chart
 * Express path: /share/package/:uuid
 */
export const getSharePackageWithUuid = (uuid) => {
  return api.ajax({
    url: `/share/package/${uuid}`,
    type: 'get'
  });
};

/**
 * Get 5 random examples
 * Express path: /examples
 */
export const getExamples = () => {
  return api.ajax({
    url: `/examples`,
    type: 'get'
  });
};

/**
 * Get samples for a template type
 * Express path: /samples
 */
export const getSamples = (tag) => {
  return api.ajax({
    url: `/samples?tag=${tag}`,
    type: 'get'
  });
};

/**
 * Get google sheet data
 * Express path: /gsheet
 */
export const getGsheet = (googleSpreadsheetKey, worksheet, apiKey) => {
  return api.ajax({
    url: `/gsheet?googleSpreadsheetKey=${googleSpreadsheetKey}&worksheet=${worksheet}&apiKey=${apiKey}`,
    type: 'get'
  });
};

/**
 * Republishes a google sheet chart if it has been previously been set to unshared
 * Express path: /republish/googlesheet/:worksheetid
 */
export const getRepublishGooglesheetWithWorksheetid = (worksheetid) => {
  return api.ajax({
    url: `/republish/googlesheet/${worksheetid}`,
    type: 'get'
  });
};

/**
 * Save/Update users third party api credentials
 * Requires that the user is logged in
 * Express path: /team/:teamid/provider/:providertype/dataserver
 */
export const postTeamProviderDataserverUsingTeamidAndProvidertype = (teamid, providertype, data) => {
  return api.ajax({
    url: `/team/${teamid}/provider/${providertype}/dataserver`,
    type: 'post',
    data: data
  });
};

/**
 * Check if user has data server connection set up
 * Requires that the user is logged in
 * Express path: /team/:teamid/provider/:providertype/dataserver
 */
export const getTeamProviderDataserverWithTeamidAndProvidertype = (teamid, providertype) => {
  return api.ajax({
    url: `/team/${teamid}/provider/${providertype}/dataserver`,
    type: 'get'
  });
};

/**
 * 
 * Express path: /discourse/sso
 */
export const getDiscourseSso = () => {
  return api.ajax({
    url: `/discourse/sso`,
    type: 'get'
  });
};

/**
 * Get all divisions of a team.
 * Express path: /divisions/:teamid
 */
export const getDivisionsWithTeamid = (teamid) => {
  return api.ajax({
    url: `/divisions/${teamid}`,
    type: 'get'
  });
};

/**
 * Get all divisions of a team.
 * Express path: /divisions/:teamid/all
 */
export const getDivisionsAllWithTeamid = (teamid) => {
  return api.ajax({
    url: `/divisions/${teamid}/all`,
    type: 'get'
  });
};

/**
 * Get all members of a team and if they are in the division
 * Requires that the user is logged in
 * Express path: /division/:divisionid/team/:teamid/all
 */
export const getDivisionTeamAllWithDivisionidAndTeamid = (divisionid, teamid, query, page, pageSize) => {
  return api.ajax({
    url: `/division/${divisionid}/team/${teamid}/all?query=${query}&page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Creates a new division
 * Requires that the user is logged in
 * Express path: /division
 */
export const postDivision = (data) => {
  return api.ajax({
    url: `/division`,
    type: 'post',
    data: data
  });
};

/**
 * Update division details
 * Requires that the user is logged in
 * Express path: /division/:divisionid
 */
export const postDivisionUsingDivisionid = (divisionid, data) => {
  return api.ajax({
    url: `/division/${divisionid}`,
    type: 'post',
    data: data
  });
};

/**
 * Updates a users division status
 * Requires that the user is logged in
 * Express path: /division/:divisionid/user/:userid
 */
export const postDivisionUserUsingDivisionidAndUserid = (divisionid, userid, data) => {
  return api.ajax({
    url: `/division/${divisionid}/user/${userid}`,
    type: 'post',
    data: data
  });
};

/**
 * Create or update editor config record by team
 * Requires that the user is logged in
 * Express path: /editor/team/:teamid
 */
export const postEditorTeamUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/editor/team/${teamid}`,
    type: 'post',
    data: data
  });
};

/**
 * Create or update editor config record by division
 * Requires that the user is logged in
 * Express path: /editor/division/:divisionid
 */
export const postEditorDivisionUsingDivisionid = (divisionid, data) => {
  return api.ajax({
    url: `/editor/division/${divisionid}`,
    type: 'post',
    data: data
  });
};

/**
 * Get editor features by team
 * Express path: /editor/team/:teamid
 */
export const getEditorTeamWithTeamid = (teamid) => {
  return api.ajax({
    url: `/editor/team/${teamid}`,
    type: 'get'
  });
};

/**
 * Get editor features by division
 * Express path: /editor/division/:divisionid
 */
export const getEditorDivisionWithDivisionid = (divisionid) => {
  return api.ajax({
    url: `/editor/division/${divisionid}`,
    type: 'get'
  });
};

/**
 * Generates embed and inject scripts for a given chart version
 * Requires that the user is logged in
 * Express path: /team/:teamid/chart/:chartid/:version/embed
 */
export const postTeamChartEmbedUsingTeamidAndChartidAndVersion = (teamid, chartid, version, data) => {
  return api.ajax({
    url: `/team/${teamid}/chart/${chartid}/${version}/embed`,
    type: 'post',
    data: data
  });
};

/**
 * Generates embed and inject scripts for a given chart
 * Requires that the user is logged in
 * Express path: /team/:teamid/chart/:chartid/embed
 */
export const postTeamChartEmbedUsingTeamidAndChartid = (teamid, chartid, data) => {
  return api.ajax({
    url: `/team/${teamid}/chart/${chartid}/embed`,
    type: 'post',
    data: data
  });
};

/**
 * Export project with chartid
 * Express path: /team/:teamid/chart/:chartid/export
 */
export const postTeamChartExportUsingTeamidAndChartid = (teamid, chartid) => {
  return api.ajax({
    url: `/team/${teamid}/chart/${chartid}/export`,
    type: 'post'
  });
};

/**
 * Export project without chart id
 * Express path: /team/:teamid/export
 */
export const postTeamExportUsingTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/export`,
    type: 'post'
  });
};

/**
 * Export project
 * Express path: /export
 */
export const postExport = () => {
  return api.ajax({
    url: `/export`,
    type: 'post'
  });
};

/**
 * Get recent export history list
 * Express path: /team/:teamid/exports
 */
export const getTeamExportsWithTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/exports`,
    type: 'get'
  });
};

/**
 * Delete a export history
 * Express path: /team/:teamid/exports/:exportid
 */
export const delTeamExportsUsingTeamidAndExportid = (teamid, exportid) => {
  return api.ajax({
    url: `/team/${teamid}/exports/${exportid}`,
    type: 'delete'
  });
};

/**
 * Export project by chart uuid
 * Express path: /export/:uuid
 */
export const getExportWithUuid = (uuid) => {
  return api.ajax({
    url: `/export/${uuid}`,
    type: 'get'
  });
};

/**
 * Export private project by chart id
Expect: password as post argument
 * Express path: /export/:chartid
 */
export const postExportUsingChartid = (chartid) => {
  return api.ajax({
    url: `/export/${chartid}`,
    type: 'post'
  });
};

/**
 * Export video project
 * Express path: /video/export
 */
export const postVideoExport = () => {
  return api.ajax({
    url: `/video/export`,
    type: 'post'
  });
};

/**
 * Submit a project for the inspired section at www.everviz.com/inspired
 * Express path: /team/:teamid/chart/:chartid/inspired
 */
export const postTeamChartInspiredUsingTeamidAndChartid = (teamid, chartid) => {
  return api.ajax({
    url: `/team/${teamid}/chart/${chartid}/inspired`,
    type: 'post'
  });
};

/**
 * Get a company theme by themeid
 * Express path: /team/:teamid/companytheme/:themeid
 */
export const getTeamCompanythemeWithTeamidAndThemeid = (teamid, themeid) => {
  return api.ajax({
    url: `/team/${teamid}/companytheme/${themeid}`,
    type: 'get'
  });
};

/**
 * Get company themes by team
 * Express path: /team/:teamid/companythemes
 */
export const getTeamCompanythemesWithTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/companythemes`,
    type: 'get'
  });
};

/**
 * Get the active company theme associated with the team
 * Express path: /team/:teamid/companytheme
 */
export const getTeamCompanythemeWithTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/companytheme`,
    type: 'get'
  });
};

/**
 * Add a new company theme to the team. If this is the first company theme created, this will automatically be set to active.
 * Requires that the user is logged in
 * Express path: /team/:teamid/companytheme
 */
export const postTeamCompanythemeUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}/companytheme`,
    type: 'post',
    data: data
  });
};

/**
 * Update a company theme for a team
 * Requires that the user is logged in
 * Express path: /team/:teamid/companytheme/:themeid
 */
export const postTeamCompanythemeUsingTeamidAndThemeid = (teamid, themeid, data) => {
  return api.ajax({
    url: `/team/${teamid}/companytheme/${themeid}`,
    type: 'post',
    data: data
  });
};

/**
 * Update company theme meta
 * Requires that the user is logged in
 * Express path: /team/:teamid/companythememeta/:themeid
 */
export const postTeamCompanythememetaUsingTeamidAndThemeid = (teamid, themeid, data) => {
  return api.ajax({
    url: `/team/${teamid}/companythememeta/${themeid}`,
    type: 'post',
    data: data
  });
};

/**
 * Set a company theme as active
 * Requires that the user is logged in
 * Express path: /team/:teamid/companytheme/:themeid/active
 */
export const postTeamCompanythemeActiveUsingTeamidAndThemeid = (teamid, themeid) => {
  return api.ajax({
    url: `/team/${teamid}/companytheme/${themeid}/active`,
    type: 'post'
  });
};

/**
 * Duplicate a company theme
 * Requires that the user is logged in
 * Express path: /team/:teamid/companytheme/:themeid/duplicate
 */
export const postTeamCompanythemeDuplicateUsingTeamidAndThemeid = (teamid, themeid) => {
  return api.ajax({
    url: `/team/${teamid}/companytheme/${themeid}/duplicate`,
    type: 'post'
  });
};

/**
 * Return all deleted company themes
 * Express path: /team/:teamid/companythemes/deleted
 */
export const getTeamCompanythemesDeletedWithTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/companythemes/deleted`,
    type: 'get'
  });
};

/**
 * Get the message stack for a team - including unread
 * Express path: /team/:teamid/messages/all
 */
export const getTeamMessagesAllWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/messages/all?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get the message stack for a team
 * Express path: /team/:teamid/messages
 */
export const getTeamMessagesWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/messages?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Mark a message as read
 * Requires that the user is logged in
 * Express path: /team/:teamid/message/:id/read
 */
export const postTeamMessageReadUsingTeamidAndId = (teamid, id) => {
  return api.ajax({
    url: `/team/${teamid}/message/${id}/read`,
    type: 'post'
  });
};

/**
 * 
 * Express path: /oembed
 */
export const getOembed = () => {
  return api.ajax({
    url: `/oembed`,
    type: 'get'
  });
};

/**
 * 
 * Express path: /openincloud
 */
export const postOpenincloud = () => {
  return api.ajax({
    url: `/openincloud`,
    type: 'post'
  });
};

/**
 * 
 * Express path: /openedfromhc/:id
 */
export const getOpenedfromhcWithId = (id) => {
  return api.ajax({
    url: `/openedfromhc/${id}`,
    type: 'get'
  });
};

/**
 * Get all packages of a team.
 * Express path: /packages/:teamid
 */
export const getPackagesWithTeamid = (teamid) => {
  return api.ajax({
    url: `/packages/${teamid}`,
    type: 'get'
  });
};

/**
 * Get a single project in a package based on the project uuid.
 * Express path: /packages/:teamid/project/:uuid
 */
export const getPackagesProjectWithTeamidAndUuid = (teamid, uuid) => {
  return api.ajax({
    url: `/packages/${teamid}/project/${uuid}`,
    type: 'get'
  });
};

/**
 * Generate publish codes for a package project
 * Express path: /team/:teamid/packages/:id/embed
 */
export const postTeamPackagesEmbedUsingTeamidAndId = (teamid, id) => {
  return api.ajax({
    url: `/team/${teamid}/packages/${id}/embed`,
    type: 'post'
  });
};

/**
 * Get package theme for team
 * Express path: /team/:teamid/packages/:id/theme
 */
export const postTeamPackagesThemeUsingTeamidAndId = (teamid, id) => {
  return api.ajax({
    url: `/team/${teamid}/packages/${id}/theme`,
    type: 'post'
  });
};

/**
 * Unpublish a project
 * Requires that the user is logged in
 * Express path: /team/:teamid/chart/:chartid/unpublish
 */
export const postTeamChartUnpublishUsingTeamidAndChartid = (teamid, chartid) => {
  return api.ajax({
    url: `/team/${teamid}/chart/${chartid}/unpublish`,
    type: 'post'
  });
};

/**
 * Book a demo with sales
 * Express path: /team/:teamid/sales/book
 */
export const postTeamSalesBookUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}/sales/book`,
    type: 'post',
    data: data
  });
};

/**
 * Create a new story
 * Requires that the user is logged in
 * Express path: /team/:teamid/story
 */
export const postTeamStoryUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}/story`,
    type: 'post',
    data: data
  });
};

/**
 * Get a single story - fetches the latest version
 * Returns an object with the following properties:
  *   - (number) story_id: The ID of the story
  *   - (number) version: The story version
  *   - (date) creation_time: The timestamp for this version
  *   - (json) data: The story data
  *   - (string) username: The username for the owner
 * Express path: /team/:teamid/story/:storyid
 */
export const getTeamStoryWithTeamidAndStoryid = (teamid, storyid) => {
  return api.ajax({
    url: `/team/${teamid}/story/${storyid}`,
    type: 'get'
  });
};

/**
 * Update a story version
 * Express path: /team/:teamid/story/:storyid
 */
export const postTeamStoryUsingTeamidAndStoryid = (teamid, storyid, data) => {
  return api.ajax({
    url: `/team/${teamid}/story/${storyid}`,
    type: 'post',
    data: data
  });
};

/**
 * Generates embed and inject scripts for a given story
 * Express path: /team/:teamid/story/:storyid/embed
 */
export const postTeamStoryEmbedUsingTeamidAndStoryid = (teamid, storyid, data) => {
  return api.ajax({
    url: `/team/${teamid}/story/${storyid}/embed`,
    type: 'post',
    data: data
  });
};

/**
 * Update story meta
 * Express path: /team/:teamid/storymeta/:storyid
 */
export const postTeamStorymetaUsingTeamidAndStoryid = (teamid, storyid, data) => {
  return api.ajax({
    url: `/team/${teamid}/storymeta/${storyid}`,
    type: 'post',
    data: data
  });
};

/**
 * Duplicate story
 * Express path: /team/:teamid/story/:storyid/duplicate
 */
export const postTeamStoryDuplicateUsingTeamidAndStoryid = (teamid, storyid, data) => {
  return api.ajax({
    url: `/team/${teamid}/story/${storyid}/duplicate`,
    type: 'post',
    data: data
  });
};

/**
 * Get the themes associated with the team
 * Requires that the user is logged in
 * Express path: /team/:teamid/themes/story
 */
export const getTeamThemesStoryWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/themes/story?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get the themes associated with the division
 * Requires that the user is logged in
 * Express path: /division/:divisionid/themes/story
 */
export const getDivisionThemesStoryWithDivisionid = (divisionid, page, pageSize) => {
  return api.ajax({
    url: `/division/${divisionid}/themes/story?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get the deleted themes associated with the team
 * Requires that the user is logged in
 * Express path: /team/:teamid/themes/story/deleted
 */
export const getTeamThemesStoryDeletedWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/themes/story/deleted?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get the deleted themes associated with the division
 * Requires that the user is logged in
 * Express path: /division/:divisionid/themes/story/deleted
 */
export const getDivisionThemesStoryDeletedWithDivisionid = (divisionid, page, pageSize) => {
  return api.ajax({
    url: `/division/${divisionid}/themes/story/deleted?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all themes associated with the division id (non-deleted)
 * Requires that the user is logged in
 * Express path: /division/:divisionid/themes/story/all
 */
export const getDivisionThemesStoryAllWithDivisionid = (divisionid, page, pageSize) => {
  return api.ajax({
    url: `/division/${divisionid}/themes/story/all?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all themes associated with the team (deleted and non-deleted)
 * Requires that the user is logged in
 * Express path: /team/:teamid/deleted/themes/story/all
 */
export const getTeamDeletedThemesStoryAllWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/deleted/themes/story/all?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all the themes within a team! (search)
 * Express path: /team/:teamid/themes/story/search
 */
export const getTeamThemesStorySearchWithTeamid = (teamid, query, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/themes/story/search?query=${query}&page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all the themes within a division! (search)
 * Express path: /division/:divisionid/themes/story/search
 */
export const getDivisionThemesStorySearchWithDivisionid = (divisionid, query, page, pageSize) => {
  return api.ajax({
    url: `/division/${divisionid}/themes/story/search?query=${query}&page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all themes associated with the team (non-deleted)
 * Requires that the user is logged in
 * Express path: /team/:teamid/themes/story/all
 */
export const getTeamThemesStoryAllWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/themes/story/all?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get a single theme - fetches the latest version.
 * Requires that the user is logged in
 * Returns an object with the following properties:
  *   - (number) story_id: The ID of the story
  *   - (number) view_count: The view counter for the chart
  *   - (number) version: The chart version
  *   - (date) creation_time: The timestamp for this version
  *   - (json) data: The chart data
  *   - (string) thumbnail_url: The URL for the latest thumbnail
  *   - (string) username: The username for the owner
 * Express path: /team/:teamid/theme/:themeid/story
 */
export const getTeamThemeStoryWithTeamidAndThemeid = (teamid, themeid) => {
  return api.ajax({
    url: `/team/${teamid}/theme/${themeid}/story`,
    type: 'get'
  });
};

/**
 * Turn a chart into a theme
 * Requires that the user is logged in
 * Express path: /team/:teamid/story/:storyid/totheme
 */
export const postTeamStoryTothemeUsingTeamidAndStoryid = (teamid, storyid) => {
  return api.ajax({
    url: `/team/${teamid}/story/${storyid}/totheme`,
    type: 'post'
  });
};

/**
 * Update a theme for a team
 * Requires that the user is logged in
 * Express path: /teams/:teamid/story/theme/:themeid
 */
export const postTeamsStoryThemeUsingTeamidAndThemeid = (teamid, themeid, data) => {
  return api.ajax({
    url: `/teams/${teamid}/story/theme/${themeid}`,
    type: 'post',
    data: data
  });
};

/**
 * Get a list of all teams
 * Express path: /admin/teams
 */
export const getAdminTeams = (page, pageSize) => {
  return api.ajax({
    url: `/admin/teams?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get a list of all users
 * Express path: /admin/users
 */
export const getAdminUsers = (page, pageSize) => {
  return api.ajax({
    url: `/admin/users?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Create a new account
 * Express path: /admin/user
 */
export const postAdminUser = () => {
  return api.ajax({
    url: `/admin/user`,
    type: 'post'
  });
};

/**
 * Search for user
 * Express path: /admin/users/search
 */
export const getAdminUsersSearch = (page, pageSize, query) => {
  return api.ajax({
    url: `/admin/users/search?page=${page}&pageSize=${pageSize}&query=${query}`,
    type: 'get'
  });
};

/**
 * Get all the charts! (search)
 * Express path: /admin/charts/search
 */
export const getAdminChartsSearch = () => {
  return api.ajax({
    url: `/admin/charts/search`,
    type: 'get'
  });
};

/**
 * Deactivate a user account
 * Express path: /admin/user/:userid/deactivate
 */
export const postAdminUserDeactivateUsingUserid = (userid) => {
  return api.ajax({
    url: `/admin/user/${userid}/deactivate`,
    type: 'post'
  });
};

/**
 * Activate a user account
 * Express path: /admin/user/:userid/activate
 */
export const postAdminUserActivateUsingUserid = (userid) => {
  return api.ajax({
    url: `/admin/user/${userid}/activate`,
    type: 'post'
  });
};

/**
 * Force a password change.
Sends an e-mail to the user with a change password link
 * Express path: /admin/user/:userid/changepass
 */
export const postAdminUserChangepassUsingUserid = (userid) => {
  return api.ajax({
    url: `/admin/user/${userid}/changepass`,
    type: 'post'
  });
};

/**
 * Update user
 * Express path: /admin/user/:id
 */
export const postAdminUserUsingId = (id, data) => {
  return api.ajax({
    url: `/admin/user/${id}`,
    type: 'post',
    data: data
  });
};

/**
 * Get statistics
 * Returns an object with the following properties:
  *   - (number) charts: Number of charts
  *   - (number) chartsThisWeek: Number of charts created this week
  *   - (number) users: Number of users
  *   - (number) usersThisWeek: Number of new users this week
  *   - (number) chartViews: Total number of chart views
 * Express path: /admin/stats
 */
export const getAdminStats = () => {
  return api.ajax({
    url: `/admin/stats`,
    type: 'get'
  });
};

/**
 * Publish package for user - Used in packages script (create-package-for-user)
 * Express path: /admin/team/:teamid/packages/:id/embed
 */
export const postAdminTeamPackagesEmbedUsingTeamidAndId = (teamid, id) => {
  return api.ajax({
    url: `/admin/team/${teamid}/packages/${id}/embed`,
    type: 'post'
  });
};

/**
 * Generate thumbnail for package chart
Once we see thumbnail gen is stable, we&#x27;ll just add this to
the publish package project route instead
 * Express path: /admin/team/:teamid/packages/:id/thumbnail
 */
export const postAdminTeamPackagesThumbnailUsingTeamidAndId = (teamid, id) => {
  return api.ajax({
    url: `/admin/team/${teamid}/packages/${id}/thumbnail`,
    type: 'post'
  });
};

/**
 * Create a new table
 * Requires that the user is logged in
 * Express path: /team/:teamid/table
 */
export const postTeamTableUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}/table`,
    type: 'post',
    data: data
  });
};

/**
 * Get a single table - fetches the latest version
 * Returns an object with the following properties:
  *   - (number) table_id: The ID of the table
  *   - (number) version: The table version
  *   - (date) creation_time: The timestamp for this version
  *   - (json) data: The table data
  *   - (string) username: The username for the owner
 * Express path: /team/:teamid/table/:tableid
 */
export const getTeamTableWithTeamidAndTableid = (teamid, tableid) => {
  return api.ajax({
    url: `/team/${teamid}/table/${tableid}`,
    type: 'get'
  });
};

/**
 * Update a table version
 * Express path: /team/:teamid/table/:tableid
 */
export const postTeamTableUsingTeamidAndTableid = (teamid, tableid, data) => {
  return api.ajax({
    url: `/team/${teamid}/table/${tableid}`,
    type: 'post',
    data: data
  });
};

/**
 * Generates embed and inject scripts for a given table
 * Express path: /team/:teamid/table/:tableid/embed
 */
export const postTeamTableEmbedUsingTeamidAndTableid = (teamid, tableid, data) => {
  return api.ajax({
    url: `/team/${teamid}/table/${tableid}/embed`,
    type: 'post',
    data: data
  });
};

/**
 * Update table meta
 * Express path: /team/:teamid/tablemeta/:tableid
 */
export const postTeamTablemetaUsingTeamidAndTableid = (teamid, tableid, data) => {
  return api.ajax({
    url: `/team/${teamid}/tablemeta/${tableid}`,
    type: 'post',
    data: data
  });
};

/**
 * Duplicate table
 * Express path: /team/:teamid/table/:tableid/duplicate
 */
export const postTeamTableDuplicateUsingTeamidAndTableid = (teamid, tableid, data) => {
  return api.ajax({
    url: `/team/${teamid}/table/${tableid}/duplicate`,
    type: 'post',
    data: data
  });
};

/**
 * Delete a tag
 * Requires that the user is logged in
 * Express path: /team/:teamid/tag/:tagid
 */
export const delTeamTagUsingTeamidAndTagid = (teamid, tagid) => {
  return api.ajax({
    url: `/team/${teamid}/tag/${tagid}`,
    type: 'delete'
  });
};

/**
 * Get invites for a team
 * Requires that the user is logged in
 * Express path: /team/:teamid/invites
 */
export const getTeamInvitesWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/invites?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Delete a team invitation
 * Requires that the user is logged in
 * Express path: /team/:teamid/invitation/:inviteid
 */
export const delTeamInvitationUsingTeamidAndInviteid = (teamid, inviteid) => {
  return api.ajax({
    url: `/team/${teamid}/invitation/${inviteid}`,
    type: 'delete'
  });
};

/**
 * Invite someone to a team
 * Requires that the user is logged in
 * Express path: /team/:teamid/invitation
 */
export const postTeamInvitationUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}/invitation`,
    type: 'post',
    data: data
  });
};

/**
 * Resend an invitation
 * Requires that the user is logged in
 * Express path: /team/:teamid/invitation/resend
 */
export const postTeamInvitationResendUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}/invitation/resend`,
    type: 'post',
    data: data
  });
};

/**
 * Accept an inviation with an existing account
 * Express path: /team/accept/invitation/:token
 */
export const postTeamAcceptInvitationUsingToken = (token) => {
  return api.ajax({
    url: `/team/accept/invitation/${token}`,
    type: 'post'
  });
};

/**
 * Accept an invitation, and create a new account.
Note that this also logs the user in, so it returns a session token
on success.
 * Express path: /team/accept/invitation/newaccount/:token
 */
export const postTeamAcceptInvitationNewaccountUsingToken = (token, data) => {
  return api.ajax({
    url: `/team/accept/invitation/newaccount/${token}`,
    type: 'post',
    data: data
  });
};

/**
 * Get a single team profile with details.
 * Requires that the user is logged in
 * Express path: /team/:teamid/profile
 */
export const getTeamProfileWithTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/profile`,
    type: 'get'
  });
};

/**
 * Get a single team profile, if public.
 * Express path: /team/:teamid/profile/public
 */
export const getTeamProfilePublicWithTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/profile/public`,
    type: 'get'
  });
};

/**
 * Get a list of all the teams the user has access to
 * Requires that the user is logged in
 * Express path: /teams
 */
export const getTeams = (page, pageSize) => {
  return api.ajax({
    url: `/teams?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Creates a new team
 * Requires that the user is logged in
 * Express path: /team
 */
export const postTeam = (data) => {
  return api.ajax({
    url: `/team`,
    type: 'post',
    data: data
  });
};

/**
 * Deletes a team
 * Requires that the user is logged in
 * Express path: /team/:teamid
 */
export const delTeamUsingTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}`,
    type: 'delete'
  });
};

/**
 * Updates a team
 * Requires that the user is logged in
 * Express path: /team/:teamid
 */
export const postTeamUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}`,
    type: 'post',
    data: data
  });
};

/**
 * Assign a user to a team
 * Requires that the user is logged in
 * Express path: /team/:teamid/user/:userid
 */
export const postTeamUserUsingTeamidAndUserid = (teamid, userid) => {
  return api.ajax({
    url: `/team/${teamid}/user/${userid}`,
    type: 'post'
  });
};

/**
 * Removes a batch of users from a team
 * Requires that the user is logged in
 * Express path: /team/:teamid/user/batch
 */
export const delTeamUserBatchUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}/user/batch`,
    type: 'delete',
    data: data
  });
};

/**
 * Removes a user from a team
 * Requires that the user is logged in
 * Express path: /team/:teamid/user/:userid
 */
export const delTeamUserUsingTeamidAndUserid = (teamid, userid) => {
  return api.ajax({
    url: `/team/${teamid}/user/${userid}`,
    type: 'delete'
  });
};

/**
 * Get a list of all users in a given division
 * Express path: /division/:divisionid/users
 */
export const getDivisionUsersWithDivisionid = (divisionid) => {
  return api.ajax({
    url: `/division/${divisionid}/users`,
    type: 'get'
  });
};

/**
 * Get a list of all users in a given team
 * Express path: /team/:teamid/users
 */
export const getTeamUsersWithTeamid = (teamid, query, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/users?query=${query}&page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get a list of all fonts in a given team
 * Express path: /team/:teamid/fonts
 */
export const getTeamFontsWithTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/fonts`,
    type: 'get'
  });
};

/**
 * Get a list of all logos in a given team
 * Express path: /team/:teamid/logos
 */
export const getTeamLogosWithTeamid = (teamid) => {
  return api.ajax({
    url: `/team/${teamid}/logos`,
    type: 'get'
  });
};

/**
 * Add a new logo to a team
 * Requires that the user is logged in
 * Express path: /team/:teamid/logo
 */
export const postTeamLogoUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}/logo`,
    type: 'post',
    data: data,
    uploadFile: true
  });
};

/**
 * Add a new font to a team
 * Requires that the user is logged in
 * Express path: /team/:teamid/font
 */
export const postTeamFontUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}/font`,
    type: 'post',
    data: data,
    uploadFile: true
  });
};

/**
 * Update font
 * Requires that the user is logged in
 * Express path: /team/:teamid/font/:fontid
 */
export const postTeamFontUsingTeamidAndFontid = (teamid, fontid, data) => {
  return api.ajax({
    url: `/team/${teamid}/font/${fontid}`,
    type: 'post',
    data: data,
    uploadFile: true
  });
};

/**
 * Delete logo
 * Requires that the user is logged in
 * Express path: /team/:teamid/delete/logo/:logoid
 */
export const postTeamDeleteLogoUsingTeamidAndLogoid = (teamid, logoid) => {
  return api.ajax({
    url: `/team/${teamid}/delete/logo/${logoid}`,
    type: 'post'
  });
};

/**
 * Delete font
 * Requires that the user is logged in
 * Express path: /team/:teamid/delete/font/:fontid
 */
export const postTeamDeleteFontUsingTeamidAndFontid = (teamid, fontid) => {
  return api.ajax({
    url: `/team/${teamid}/delete/font/${fontid}`,
    type: 'post'
  });
};

/**
 * Get the themes associated with the team
 * Express path: /team/:teamid/type/themes/:charttype
 */
export const getTeamTypeThemesWithTeamidAndCharttype = (teamid, charttype, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/type/themes/${charttype}?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get the themes associated with the division
 * Express path: /division/:divisionid/type/themes/:charttype
 */
export const getDivisionTypeThemesWithDivisionidAndCharttype = (divisionid, charttype, page, pageSize) => {
  return api.ajax({
    url: `/division/${divisionid}/type/themes/${charttype}?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get the deleted themes associated with the team
 * Requires that the user is logged in
 * Express path: /team/:teamid/themes/deleted
 */
export const getTeamThemesDeletedWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/themes/deleted?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get the deleted themes associated with the division
 * Requires that the user is logged in
 * Express path: /division/:divisionid/themes/deleted
 */
export const getDivisionThemesDeletedWithDivisionid = (divisionid, page, pageSize) => {
  return api.ajax({
    url: `/division/${divisionid}/themes/deleted?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all themes associated with the division id (non-deleted)
 * Requires that the user is logged in
 * Express path: /division/:divisionid/themes/all
 */
export const getDivisionThemesAllWithDivisionid = (divisionid, page, pageSize) => {
  return api.ajax({
    url: `/division/${divisionid}/themes/all?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all themes associated with the team (deleted and non-deleted)
 * Requires that the user is logged in
 * Express path: /team/:teamid/deleted/themes/all
 */
export const getTeamDeletedThemesAllWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/deleted/themes/all?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all the themes within a team! (search)
 * Express path: /team/:teamid/themes/search
 */
export const getTeamThemesSearchWithTeamid = (teamid, query, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/themes/search?query=${query}&page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all the themes within a division! (search)
 * Express path: /division/:divisionid/themes/search
 */
export const getDivisionThemesSearchWithDivisionid = (divisionid, query, page, pageSize) => {
  return api.ajax({
    url: `/division/${divisionid}/themes/search?query=${query}&page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get all themes associated with the team (non-deleted)
 * Requires that the user is logged in
 * Express path: /team/:teamid/themes/all
 */
export const getTeamThemesAllWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/themes/all?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get a single theme - fetches the latest version.
 * Requires that the user is logged in
 * Returns an object with the following properties:
  *   - (number) chart_id: The ID of the chart
  *   - (number) view_count: The view counter for the chart
  *   - (number) version: The chart version
  *   - (date) creation_time: The timestamp for this version
  *   - (json) data: The chart data
  *   - (string) thumbnail_url: The URL for the latest thumbnail
  *   - (string) username: The username for the owner
 * Express path: /team/:teamid/theme/:themeid
 */
export const getTeamThemeWithTeamidAndThemeid = (teamid, themeid) => {
  return api.ajax({
    url: `/team/${teamid}/theme/${themeid}`,
    type: 'get'
  });
};

/**
 * Turn a chart into a theme
 * Requires that the user is logged in
 * Express path: /team/:teamid/chart/:chartid/totheme
 */
export const postTeamChartTothemeUsingTeamidAndChartid = (teamid, chartid) => {
  return api.ajax({
    url: `/team/${teamid}/chart/${chartid}/totheme`,
    type: 'post'
  });
};

/**
 * Duplicate a chart as a theme
 * Requires that the user is logged in
 * Express path: /team/:teamid/chart/:chartid/dupetotheme
 */
export const postTeamChartDupetothemeUsingTeamidAndChartid = (teamid, chartid) => {
  return api.ajax({
    url: `/team/${teamid}/chart/${chartid}/dupetotheme`,
    type: 'post'
  });
};

/**
 * Add a new theme to the team
 * Requires that the user is logged in
 * Express path: /team/:teamid/theme
 */
export const postTeamThemeUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}/theme`,
    type: 'post',
    data: data
  });
};

/**
 * Update a theme for a team
 * Requires that the user is logged in
 * Express path: /teams/:teamid/theme/:themeid
 */
export const postTeamsThemeUsingTeamidAndThemeid = (teamid, themeid, data) => {
  return api.ajax({
    url: `/teams/${teamid}/theme/${themeid}`,
    type: 'post',
    data: data
  });
};

/**
 * Recover a theme
 * Requires that the user is logged in
 * Express path: /team/:teamid/theme/:themeid/recover
 */
export const postTeamThemeRecoverUsingTeamidAndThemeid = (teamid, themeid) => {
  return api.ajax({
    url: `/team/${teamid}/theme/${themeid}/recover`,
    type: 'post'
  });
};

/**
 * Remove a theme from a team
 * Requires that the user is logged in
 * Express path: /team/:teamid/theme/:themeid
 */
export const delTeamThemeUsingTeamidAndThemeid = (teamid, themeid) => {
  return api.ajax({
    url: `/team/${teamid}/theme/${themeid}`,
    type: 'delete'
  });
};

/**
 * Used for checking that the server is up and running
 * Returns an object with the following properties:
  *   - (date) time: The current server time
 * Express path: /info/healthcheck
 */
export const getInfoHealthcheck = () => {
  return api.ajax({
    url: `/info/healthcheck`,
    type: 'get'
  });
};

/**
 * Used to get the version of the service
 * Returns an object with the following properties:
  *   - (string) version: The running version
 * Express path: /info/version
 */
export const getInfoVersion = () => {
  return api.ajax({
    url: `/info/version`,
    type: 'get'
  });
};

/**
 * Get the basic configuration currently running
 * Express path: /info/config
 */
export const getInfoConfig = () => {
  return api.ajax({
    url: `/info/config`,
    type: 'get'
  });
};

/**
 * Get authentication info
 * Requires that the user is logged in
 * Express path: /info/auth
 */
export const getInfoAuth = () => {
  return api.ajax({
    url: `/info/auth`,
    type: 'get'
  });
};

/**
 * Get the number of charts in the system
 * Express path: /info/chartcount
 */
export const getInfoChartcount = () => {
  return api.ajax({
    url: `/info/chartcount`,
    type: 'get'
  });
};

/**
 * Get the number of published charts in the system
 * Express path: /info/publishedcount
 */
export const getInfoPublishedcount = () => {
  return api.ajax({
    url: `/info/publishedcount`,
    type: 'get'
  });
};

/**
 * Is logged in?
 * Express path: /info/loggedin
 */
export const getInfoLoggedin = () => {
  return api.ajax({
    url: `/info/loggedin`,
    type: 'get'
  });
};

/**
 * Get the profile for the logged in user
 * Requires that the user is logged in
 * Express path: /profile
 */
export const getProfile = () => {
  return api.ajax({
    url: `/profile`,
    type: 'get'
  });
};

/**
 * Update the profile for the logged in user
 * Requires that the user is logged in
 * Express path: /profile
 */
export const postProfile = (data) => {
  return api.ajax({
    url: `/profile`,
    type: 'post',
    data: data
  });
};

/**
 * Get the permissions the user has in a given team
 * Requires that the user is logged in
 * Express path: /user/permissions/:teamid
 */
export const getUserPermissionsWithTeamid = (teamid) => {
  return api.ajax({
    url: `/user/permissions/${teamid}`,
    type: 'get'
  });
};

/**
 * Give user feedback
 * Requires that the user is logged in
 * Express path: /feedback/team/:teamid/user
 */
export const postFeedbackTeamUserUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/feedback/team/${teamid}/user`,
    type: 'post',
    data: data
  });
};

/**
 * Get user feedback
 * Requires that the user is logged in
 * Express path: /user/feedback/:userid
 */
export const getUserFeedbackWithUserid = (userid) => {
  return api.ajax({
    url: `/user/feedback/${userid}`,
    type: 'get'
  });
};

/**
 * Switch cloud
 * Requires that the user is logged in
 * Express path: /user/switchcloud
 */
export const postUserSwitchcloud = (data) => {
  return api.ajax({
    url: `/user/switchcloud`,
    type: 'post',
    data: data
  });
};

/**
 * Increment the view counter
 * Express path: /chart/:chartid/:version/view
 */
export const postChartViewUsingChartidAndVersion = (chartid, version) => {
  return api.ajax({
    url: `/chart/${chartid}/${version}/view`,
    type: 'post'
  });
};

/**
 * Get the supported webhooks
 * Express path: /webhooks
 */
export const getWebhooks = (page, pageSize) => {
  return api.ajax({
    url: `/webhooks?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Get a list of all webhooks for a given team
 * Requires that the user is logged in
 * Express path: /team/:teamid/webhooks
 */
export const getTeamWebhooksWithTeamid = (teamid, page, pageSize) => {
  return api.ajax({
    url: `/team/${teamid}/webhooks?page=${page}&pageSize=${pageSize}`,
    type: 'get'
  });
};

/**
 * Add a new webhook for a given team
 * Requires that the user is logged in
 * Express path: /team/:teamid/webhooks
 */
export const postTeamWebhooksUsingTeamid = (teamid, data) => {
  return api.ajax({
    url: `/team/${teamid}/webhooks`,
    type: 'post',
    data: data
  });
};

/**
 * Delete a webhook
 * Requires that the user is logged in
 * Express path: /team/:teamid/webhook/:hookid
 */
export const delTeamWebhookUsingTeamidAndHookid = (teamid, hookid) => {
  return api.ajax({
    url: `/team/${teamid}/webhook/${hookid}`,
    type: 'delete'
  });
};

/**
 * Update a webhook
 * Requires that the user is logged in
 * Express path: /team/:teamid/webhook/:hookid
 */
export const postTeamWebhookUsingTeamidAndHookid = (teamid, hookid, data) => {
  return api.ajax({
    url: `/team/${teamid}/webhook/${hookid}`,
    type: 'post',
    data: data
  });
};
