import { HotTable } from '@handsontable/react';
import classNames from 'classnames';
import { getLetterFromIndex } from 'editor/core/highcharts-editor';
import Handsontable from 'handsontable';
import {
  AutoColumnSize,
  ContextMenu,
  CopyPaste,
  ManualColumnMove,
  ManualColumnResize,
  ManualRowMove,
  registerPlugin,
  UndoRedo
} from 'handsontable/plugins';
import { cloneDeep } from 'lodash';
import {
  addDataGridSeriesAction,
  deleteDataGridSeriesAction,
  moveCellsAction,
  remapAction,
  transposeDataAction
} from 'pages/ChartEditorPage/actions/chartEditor';
import { useChart } from 'pages/ChartEditorPage/meta/highchartsHelper';
import { getMapCodeValueIndex } from 'pages/ChartEditorPage/utils/chartEditorDataHelper';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateGridData } from 'redux/reducers/dataGridReducer';
import { setSeparatorTab } from 'redux/reducers/wizardReducer';
import { RootState } from 'redux/store';
import IconButton from 'shared/buttons/IconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import {
  afterGetColHeader,
  beforeOnCellMouseDown,
  cellsConfig,
  CoordProps,
  editData,
  getHoveredRowIndices,
  hotContextMenuConfig,
  mapParsedDataToDictionary,
  setDataMap
} from 'shared/wizard/utils/dataGridHelper';
import { parsePastedValue, SetDataParams } from '../utils/dragAndDropHelper';
import { getAllowProps, getIsLiveDataSource } from '../utils/seriesHelper';
import useCategoriesAndSeries from '../utils/useCategoriesAndSeries';

registerPlugin(UndoRedo);
registerPlugin(ManualColumnResize);
registerPlugin(CopyPaste);
registerPlugin(AutoColumnSize);
registerPlugin(ContextMenu);
registerPlugin(ManualColumnMove);
registerPlugin(ManualRowMove);

type DataGridProps = {
  isTable?: boolean;
  togglePasting?: (toggle?: boolean) => void;
  minRows?: number;
};

const MIN_COLS = 4;
const visualFeedbackCharts = [
  // pie stands for both PIE & DONUT chart. item stands for both PARLIAMENT & UNIT Chart
  'pie',
  'timeline',
  'sankey',
  'dependencywheel',
  'item',
  'funnel',
  'packedbubble',
  'treemap'
];

export default function DataGrid(props: DataGridProps) {
  const { togglePasting } = props;
  const dispatch = useDispatch();
  const hotTableRef = useRef<HotTable | null>({} as HotTable);
  const {
    seriesAssigns,
    dataOptions,
    activeColumns,
    aggregatedOptions,
    dataConfig,
    isPastingActive,
    mapCodeErrors,
    type,
    provider
  } = useSelector((state: RootState) => state.projectConfig);
  if (provider === 'locationMap') throw new Error('Component cannot be used with location map');

  const { mappedData, isMap } = useSelector((state: RootState) => state.chartEditorPage);
  const oldColHeaders = useSelector((state: RootState) => state.dataGridConfig.colHeaders);
  const [parsedData, setParsedData] = useState<Handsontable.RowObject[]>(
    cloneDeep(dataOptions.length ? dataOptions : [[]])
  );
  const [activeRows, setActiveRows] = useState<number[]>([]);
  const { mapCodeIndex } = getMapCodeValueIndex(seriesAssigns);
  const chart = useChart();

  const selectedChart = (aggregatedOptions?.chart as { type?: string })?.type ?? '';
  const { seriesTypes } = getAllowProps(aggregatedOptions, props.isTable);
  const getIndexForAssignRawOptions = seriesTypes.includes('mappoint') ? 1 : 0;
  const isPointMapTypes = isMap && seriesTypes.includes('mappoint');
  const { categoriesIndexes, seriesIndexes } = useCategoriesAndSeries(getIndexForAssignRawOptions);
  const isLiveDataSource = getIsLiveDataSource(aggregatedOptions, dataConfig);

  useEffect(() => {
    document.querySelector('.everviz-datagrid')?.addEventListener('wheel', function (event) {
      if ((event as WheelEvent).deltaX < 0) event.preventDefault();
    });
  }, []);

  const setData = (params: SetDataParams) => {
    dispatch(setDataMap[type](params));
  };

  const parseMapValues = (data?: Handsontable.RowObject[]) => {
    setParsedData(
      (data ?? parsedData).map((item: Handsontable.RowObject) => {
        item[mapCodeIndex] = mappedData[item[mapCodeIndex]] ?? item[mapCodeIndex];
        return item;
      })
    );
  };

  useEffect(() => {
    if (isLiveDataSource) return;

    if (isMap) parseMapValues(cloneDeep(dataOptions));
    else setParsedData(cloneDeep(dataOptions));

    const headers = dataOptions[0];
    if (headers.length !== oldColHeaders.length) {
      const gridData = hotTableRef.current?.hotInstance?.getData() as [[]];
      const colHeaders = hotTableRef.current?.hotInstance?.getColHeader();
      if (gridData && colHeaders) {
        let newColHeaders = headers.map((_, index) => getLetterFromIndex(index));
        if (newColHeaders.length < MIN_COLS) {
          newColHeaders = Array.from({ length: MIN_COLS }, (_, i) => newColHeaders[i] ?? getLetterFromIndex(i));
        }
        dispatch(updateGridData({ gridData, colHeaders: newColHeaders }));
      }
    }
  }, [dataOptions]);

  useEffect(() => {
    if (isMap) parseMapValues();
  }, [mappedData]);

  const onBeforeChange = (changes: Handsontable.CellChange[]) => {
    if (isPastingActive && togglePasting) togglePasting(false);
    dispatch(editData[type]({ changes, seriesAssigns, isTable: props.isTable }));
  };

  const onCreate = (index: number, amount: number, source?: Handsontable.ChangeSource) => {
    if (
      source === 'ContextMenu.rowAbove' ||
      source === 'ContextMenu.rowBelow' ||
      source === 'ContextMenu.columnLeft' ||
      source === 'ContextMenu.columnRight'
    ) {
      dispatch(addDataGridSeriesAction({ index, amount, source }));
    }
  };

  const onColumnMove = (movedColumns: number[], finalIndex: number) => {
    if (movedColumns.length && movedColumns[0] === finalIndex) return;
    dispatch(moveCellsAction({ type, moveType: 'columns', movedCells: movedColumns, finalIndex }));
    return false;
  };

  const onRowMove = (movedRows: number[], finalIndex: number) => {
    dispatch(moveCellsAction({ type, moveType: 'row', movedCells: movedRows, finalIndex }));
    return false;
  };

  const onRemove = (index: number, amount: number, physicalCells: number[], source?: Handsontable.ChangeSource) => {
    if (source === 'ContextMenu.removeRow' || source === 'ContextMenu.removeColumn') {
      dispatch(deleteDataGridSeriesAction({ index, amount, source }));
    }
  };

  const transposeData = () => {
    if (isLiveDataSource) return;
    dispatch(
      transposeDataAction({
        type,
        callback: (data: Handsontable.RowObject[]) => {
          setParsedData(data);
        }
      })
    );
  };

  const onBeforeOnCellMouseDown = (e: MouseEvent, coords: CoordProps) => {
    beforeOnCellMouseDown(e, coords, hotTableRef.current?.hotInstance as Handsontable);
  };

  const beforePaste = (data: any, coords: { endRow: number; endCol: number; startCol: number; startRow: number }[]) => {
    const parsedValue = parsePastedValue(window.event as ClipboardEvent, props.isTable);

    const hot = hotTableRef.current?.hotInstance;
    const selectedAllCells =
      coords?.length === 1 &&
      coords[0].startCol === 0 &&
      coords[0].startRow === 0 &&
      hot?.countCols() === coords[0].endCol + 1 &&
      hot?.countRows() === coords[0].endRow + 1;

    const afterPaste = () => {
      togglePasting && togglePasting(false);
      dispatch(setSeparatorTab({ display: true }));
    };

    // Selected all cells and trying to paste. Do a normal import in this case
    if (selectedAllCells || isPastingActive) {
      setData({ csv: parsedValue, dataType: 'csv', cb: afterPaste, isMap });
      return false;
    }
  };

  const afterPaste = () => {
    dispatch(
      remapAction({
        type,
        skipNotification: !isPastingActive,
        cb: () => {
          if (isPastingActive && togglePasting) togglePasting();
        }
      })
    );
  };

  const handleMouseOver = (rowsData: { [key: string]: number[] }) => {
    const hoveredRowIndices = getHoveredRowIndices(selectedChart, rowsData);
    setActiveRows(hoveredRowIndices);
  };

  const handleMouseLeave = () => {
    setActiveRows([]);
  };

  useEffect(() => {
    if (visualFeedbackCharts.includes(selectedChart)) {
      const mappedParsedData = mapParsedDataToDictionary(selectedChart, parsedData);
      const rootSelectorCharts = ['timeline', 'packedbubble', 'treemap'];
      const selector = rootSelectorCharts.includes(selectedChart)
        ? 'svg.highcharts-root'
        : 'svg.highcharts-root g.highcharts-series';
      const highchartsRoot = document.querySelector(selector);

      const handleMouseOverListener = () => handleMouseOver(mappedParsedData);
      const handleMouseLeaveListener = () => handleMouseLeave();

      if (highchartsRoot) {
        highchartsRoot.addEventListener('mouseover', handleMouseOverListener);
        highchartsRoot.addEventListener('mouseleave', handleMouseLeaveListener);
      }

      return () => {
        if (highchartsRoot) {
          highchartsRoot.removeEventListener('mouseover', handleMouseOverListener);
          highchartsRoot.removeEventListener('mouseleave', handleMouseLeaveListener);
        }
      };
    }
  }, [chart]);

  const getCells = (row: number, col: number) => {
    if (isLiveDataSource) return {};
    return cellsConfig(
      row,
      col,
      isMap,
      categoriesIndexes,
      seriesIndexes,
      isPointMapTypes,
      (activeColumns ?? {}).columns,
      activeRows,
      mapCodeErrors,
      mapCodeIndex
    );
  };

  const tableClass = classNames({
    'live-data': isLiveDataSource,
    'everviz-datatable': true
  });
  const containerClass = classNames('relative', {
    'pasting-active': isPastingActive
  });

  return (
    <div className={containerClass}>
      <HotTable
        ref={hotTableRef}
        data={parsedData}
        cells={getCells}
        rowHeaders={true}
        colHeaders={true}
        manualColumnMove={true}
        manualRowMove={true}
        contextMenu={hotContextMenuConfig}
        copyPaste={true}
        manualColumnResize={true}
        minRows={props.minRows ?? 20}
        minCols={MIN_COLS}
        stretchH="all"
        licenseKey={window.hcconfig.dataGridLicenceKey ?? 'non-commercial-and-evaluation'}
        className={tableClass}
        beforeChange={onBeforeChange}
        beforeCreateRow={onCreate}
        beforeCreateCol={onCreate}
        beforeRemoveRow={onRemove}
        beforeRemoveCol={onRemove}
        afterGetColHeader={afterGetColHeader}
        beforeOnCellMouseDown={onBeforeOnCellMouseDown}
        beforeColumnMove={onColumnMove}
        beforeRowMove={onRowMove}
        beforePaste={beforePaste}
        afterPaste={afterPaste}
        bindRowsWithHeaders={true}
      />
      {!isMap && (
        <div className="switch-rows absolute top-[0px] left-[0px] w-[50px] h-[30px] z-[1200]">
          <IconButton
            icon={isLiveDataSource ? 'lock' : 'repeat'}
            className="w-full h-full  mx-0"
            buttonColor={ButtonColor.Transparent}
            onClick={transposeData}
          />
        </div>
      )}
      {isLiveDataSource && (
        <div className="w-full h-[531px] bg-transparent absolute top-0 z-[1]">
          <div className="absolute top-[77px] left-[85px] z-[1] italic font-bold text-ev-grey-2 bg-white">
            Unable to show preview of live data
          </div>
        </div>
      )}
    </div>
  );
}
