import React from 'react';
import RichTextEditor from './RichTextEditor';
import TableRow from '../containers/TableRow';
import DOMPurify from 'dompurify';
import { isNull, isObj, merge } from 'editor/core/highcharts-editor';

let timer = false;
let dataTimer = false;

class Table extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleCEUpdate = this.handleCEUpdate.bind(this);
    this.getBufferDataRowIndex = this.getBufferDataRowIndex.bind(this);
    this.changeActiveRowHover = this.changeActiveRowHover.bind(this);
    this.onMouseOutRow = this.onMouseOutRow.bind(this);
    this.onHeaderHover = this.onHeaderHover.bind(this);
    this.updateDraggedOver = this.updateDraggedOver.bind(this);
    this.changeRowOrder = this.changeRowOrder.bind(this);
    this.changeDirection = this.changeDirection.bind(this);
    // this.showCellSettings = this.showCellSettings.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.cellOnDblClick = this.cellOnDblClick.bind(this);
    this.onMouseOverCell = this.onMouseOverCell.bind(this);
    this.onMouseDownCell = this.onMouseDownCell.bind(this);
    this.onHeaderMouseDown = this.onHeaderMouseDown.bind(this);
    this.setSelectModeProps = this.setSelectModeProps.bind(this);

    this.onMouseOverHeaderCell = this.onMouseOverHeaderCell.bind(this);
    this.setMouseOverProps = this.setMouseOverProps.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.resetKey = this.resetKey.bind(this);
    this.finishAndGoDown = this.finishAndGoDown.bind(this);
    this.showContextMenu = this.showContextMenu.bind(this);

    // this.calcPosition = this.calcPosition.bind(this);

    this.state = {
      container: document.querySelector('.canvas-fixed-height-container'),
      draggedOver: null,
      smallWidth: 500
    };
  }

  shouldComponentUpdate() {
    return this.props.shouldUpdateTableComponent;
  }

  pastePlainText(e) {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertHTML', false, text);
  }

  getCaretPosition(node) {
    let range = window.getSelection().getRangeAt(0),
      preCaretRange = range.cloneRange(),
      caretPosition,
      tmp = document.createElement('div');

    preCaretRange.selectNodeContents(node);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    tmp.appendChild(preCaretRange.cloneContents());

    caretPosition = tmp.innerText.length;
    return caretPosition;
  }

  disableNewlines(evt) {
    const keyCode = evt.keyCode || evt.which;
    if (keyCode === 13) {
      // Enter key
      evt.returnValue = false;
      if (evt.preventDefault) evt.preventDefault();
    }
  }

  changeDirection(e, cb) {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 37) {
      // Go the the column to the left
      this.moveCursorUsingElement(e.currentTarget.parentNode, 0, -1);
      if (e.preventDefault) e.preventDefault();
      // Go to the column above
    } else if (keyCode === 38) {
      this.moveCursorUsingElement(e.currentTarget.parentNode, -1, 0);
      if (e.preventDefault) e.preventDefault();
      // Go to the column to the right
    } else if (
      keyCode === 39 /* && this.getCaretPosition(e.target) === e.target.textContent.length*/ ||
      keyCode === 9
    ) {
      this.moveCursorUsingElement(e.currentTarget.parentNode, 0, 1);
      if (e.preventDefault) e.preventDefault();
      // Go to the column below
    } else if (keyCode === 40) {
      this.moveCursorUsingElement(e.currentTarget.parentNode, 1, 0);
      if (e.preventDefault) e.preventDefault();
    } else if (keyCode === 8 || keyCode === 46) {
      this.props.deleteBulkCellValues(
        this.props.dataOptions,
        this.props.activeBufferCell,
        this.props.activeSelectedBufferCell
      );
      if (e.preventDefault) e.preventDefault();
      // eslint-disable-next-line callback-return
    } else if (cb) cb(e);
  }

  moveCursor(row, column) {
    const newVal = document.querySelector("td[data-row='" + row + "'][data-column='" + column + "'] .input");
    if (!newVal) return;

    setTimeout(() => {
      if (newVal.parentNode) newVal.focus();
    }, 0);
  }

  moveCursorUsingElement(current, rowOffset, columnOffset) {
    const currentRow = parseInt(current.getAttribute('data-row'), 10),
      currentColumn = parseInt(current.getAttribute('data-column'), 10);

    this.moveCursor(currentRow + rowOffset, currentColumn + columnOffset);
  }

  updateDraggedOver(index) {
    this.setState({
      draggedOver: index
    });
  }

  handleCEUpdate(isChart, row, column, text) {
    const { dataOptions, customizedOptions } = this.props;
    if (isChart) {
      this.props.updateChartHeader(this.props.customizedOptions, column, text);
      return;
    }

    let newDataOptions = JSON.parse(JSON.stringify(dataOptions));
    let cellIndexToUpdate = parseInt(column, 10);

    let count = 0;
    // eslint-disable-next-line array-callback-return
    (customizedOptions.columns || []).some((element, i) => {
      if (i === cellIndexToUpdate) return true;
      if (element.type === 'data') count++;
    });

    if (isObj(newDataOptions[row][count])) {
      newDataOptions[row][count].value = text; // e.target.value;
    } else {
      newDataOptions[row][count] = text; // e.target.value;
    }

    this.props.setTableAction({
      shouldUpdateTableComponent: false
    });
    this.props.updateData(newDataOptions);
    clearInterval(dataTimer);
    dataTimer = setTimeout(() => {
      this.props.setTableAction({
        shouldUpdateTableComponent: true
      });
    }, 200);
  }

  getBufferDataRowIndex(searchDataIndex, i) {
    return searchDataIndex?.length > 0 ? searchDataIndex[i] : i;
  }

  changeActiveRowHover(e) {
    // if (e.target.nodeName === 'DIV') e.target = e.target.parentNode;
    const dataRow = parseInt(e.currentTarget.getAttribute('data-row'), 10);
    if (this.props.rowSettings === dataRow) return;

    const elementDimensions = e.currentTarget.getBoundingClientRect();

    let settings = {
      settingsStyle: {
        height: elementDimensions.height + 1 + 'px',
        top: this.state.container.scrollTop + elementDimensions.y - 110 + 22 + 'px',
        display: 'block'
      },
      rowSettings: dataRow,
      rowSettingsType: 'vertical'
    };

    let handle;
    if (e.currentTarget) handle = e.currentTarget.querySelector('.table-move-handle');
    if (handle) {
      const table = document.querySelector('table');
      const canvas = document.querySelector('.story.table #canvas').getBoundingClientRect();

      const tableStyle = getComputedStyle(table, null);
      const leftOffset =
        table.getBoundingClientRect().left -
        canvas.left +
        parseInt(tableStyle.getPropertyValue('border-left-width') || 0, 10);
      settings.settingsStyle.left = handle.offsetLeft + leftOffset + 'px';
    }

    clearTimeout(timer);
    this.props.setTableAction(settings);
  }

  onMouseOutRow() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    timer = setTimeout(() => {
      that.props.setTableAction({
        settingsStyle: {
          display: 'none'
        },
        rowSettings: null
      });
    }, 2500);
  }

  onHeaderHover(e) {
    if (e.target.nodeName === 'DIV') e.target = e.target.parentNode;
    const dataColumn = parseInt(e.target.getAttribute('data-column'), 10);

    const elementDimensions = e.target.getBoundingClientRect();
    clearTimeout(timer);

    const table = document.querySelector('.everviz-data-table.table');
    const tableDimensions = table.getBoundingClientRect();

    this.props.setTableAction({
      settingsStyle: {
        left: elementDimensions.left - tableDimensions.left + 'px',
        display: 'block'
      },
      columnSettings: dataColumn,
      rowSettingsType: 'horizontal'
    });
  }

  changeRowOrder(from, to) {
    this.props.changeRowOrder(this.props.dataOptions, from, to);
    this.setState({
      draggedOver: null
    });
  }

  getTableClass() {
    let className = '';
    const showTableAsCards = this.props.aggregatedOptions.table ? this.props.aggregatedOptions.table.cards : null;
    if (this.props.resolution.width !== null && this.props.resolution.width <= this.state.smallWidth) {
      className += ' mobile ';
      if (showTableAsCards) className += ' cards';
    }

    return className;
  }

  cellOnDblClick(e) {
    const target = e.currentTarget;
    if (target) {
      if (
        !isNaN(parseInt(target.getAttribute('data-row'), 10)) &&
        !isNaN(parseInt(target.getAttribute('data-column'), 10))
      ) {
        this.props.setTableAction({
          activeCellRow: parseInt(target.getAttribute('data-row'), 10),
          activeCellColumn: parseInt(target.getAttribute('data-column'), 10)
        });
      }
    }
  }

  setSelectModeProps(target, isHeader) {
    this.props.setTableAction({
      activeBufferCell: [
        parseInt(target.getAttribute('data-column'), 10),
        parseInt(target.getAttribute('data-row'), 10)
      ],
      activeSelectedBufferCell: null,
      inSelectMode: true,
      isInHeaderSelectMode: isHeader
    });
  }

  onMouseDownCell(e) {
    const target = e.currentTarget;
    if (target) this.setSelectModeProps(target, false);
  }

  onHeaderMouseDown(e) {
    const target = e.currentTarget;
    if (target) this.setSelectModeProps(target, true);
  }

  setMouseOverProps(target) {
    const activeCell = this.props.activeBufferCell;
    let dataColumn = parseInt(target.getAttribute('data-column'), 10);
    let dataRow = parseInt(target.getAttribute('data-row'), 10);
    if (dataColumn > activeCell[0]) dataColumn += parseInt(target.getAttribute('colspan'), 10) - 1;
    if (dataRow > activeCell[1]) dataRow += parseInt(target.getAttribute('rowspan'), 10) - 1;

    this.props.setTableAction({
      activeSelectedBufferCell: [dataColumn, dataRow]
    });
  }

  onMouseOverHeaderCell(e) {
    const target = e.currentTarget;
    if (target && this.props.inSelectMode && this.props.isInHeaderSelectMode) {
      this.setMouseOverProps(target);
    }
  }

  onMouseOverCell(e) {
    const target = e.currentTarget;
    if (target && this.props.inSelectMode && !this.props.isInHeaderSelectMode) {
      this.setMouseOverProps(target);
    }
  }

  onFocus(e) {
    const target = e.currentTarget;
    if (target) {
      this.props.setTableAction({
        activeBufferCell: [
          parseInt(target.getAttribute('data-column'), 10),
          parseInt(target.getAttribute('data-row'), 10)
        ],
        activeSelectedBufferCell: null,
        activeCellRow: null,
        activeCellColumn: null
      });
    }
  }

  handleKeyDown(e) {
    this.changeDirection(e, (e) => {
      this.cellOnDblClick(e);
      if (e.which !== 0 && !e.ctrlKey && !e.metaKey && !e.altKey) {
        this.keySet = String.fromCharCode(e.which);
      }
    });
  }

  resetKey() {
    this.keySet = null;
  }

  showContextMenu(e) {
    e.preventDefault();

    const target = e.currentTarget;
    this.props.showContextMenu(
      e.clientX,
      e.clientY,
      parseInt(target.getAttribute('data-column'), 10),
      parseInt(target.getAttribute('data-row'), 10)
    );
  }

  finishAndGoDown() {
    const { activeBufferCell } = this.props;
    this.moveCursor(activeBufferCell[1] + 1, activeBufferCell[0] + 0);
  }

  render() {
    let {
      bufferDataOptions,
      aggregatedOptions,
      headerStyle,
      rowSettings,
      isGSheet,
      lowCell,
      highCell,
      activeCellColumn,
      activeCellRow,
      searchDataIndex
    } = this.props;
    const { draggedOver } = this.state;
    const tableStyle = aggregatedOptions.table ? aggregatedOptions.table.style : null;
    const columns = aggregatedOptions.columns;
    const header = aggregatedOptions.header || {};
    const rowStyle =
      aggregatedOptions.rows && aggregatedOptions.rows.stripes
        ? {
            backgroundColor: aggregatedOptions.rows.stripesColor ? aggregatedOptions.rows.stripesColor : '#f9f9f9',
            color: aggregatedOptions.rows.textColor ? aggregatedOptions.rows.textColor : '#000'
          }
        : null;
    let headerRows = header.headerRows || 1;
    const headerRowArr = Array(headerRows).fill(null);
    let resultsPerPage = null;
    if (aggregatedOptions && aggregatedOptions.table && aggregatedOptions.table.pagination) {
      resultsPerPage = aggregatedOptions.table.pagination.resultsPerPage || 10;
    }

    return (
      <table
        className={'everviz-data-table table ' + (isGSheet ? ' is-gsheet ' : ' ') + this.getTableClass()}
        style={tableStyle}
      >
        {header.headerRows !== 0 && (
          <thead>
            {headerRowArr.map((row, headerRowIndex) => {
              return (
                <tr key={'header_row_' + headerRowIndex}>
                  {bufferDataOptions[headerRowIndex].map((column, i) => {
                    const isChart = Array.isArray(column);
                    let cellValue = column,
                      colSpan = 1,
                      rowSpan = 1;

                    if (isObj(column)) {
                      if (column.mergedCell) return <></>;

                      cellValue = column.value;
                      colSpan = column.colSpan || 1;
                      rowSpan = column.rowSpan || 1;
                    }

                    if (isNull(cellValue)) cellValue = '';

                    const isActive = activeCellColumn === i && activeCellRow === headerRowIndex;
                    let aggHeaderStyle = headerStyle;
                    if (aggregatedOptions.columns && aggregatedOptions.columns[i]) {
                      // eslint-disable-next-line no-prototype-builtins
                      if (aggregatedOptions.columns[i].hasOwnProperty('style')) {
                        aggHeaderStyle = merge(merge({}, headerStyle || {}), aggregatedOptions.columns[i].style);
                      }
                    }

                    if (column && column.style) aggHeaderStyle = merge(aggHeaderStyle, merge({}, column.style));

                    return (
                      <th
                        key={'thead_' + headerRowIndex + '_' + i}
                        style={aggHeaderStyle}
                        colSpan={colSpan}
                        rowSpan={rowSpan}
                        onContextMenu={this.showContextMenu}
                        data-isheader={true}
                        onDoubleClick={this.cellOnDblClick}
                        onMouseOver={this.onMouseOverHeaderCell}
                        onMouseDown={this.onHeaderMouseDown}
                        className={
                          columns[i]?.visible === false
                            ? ' hidden '
                            : ' ' +
                              (lowCell &&
                              i >= lowCell[0] &&
                              i <= highCell[0] &&
                              headerRowIndex >= lowCell[1] &&
                              headerRowIndex <= highCell[1]
                                ? ' is-selected '
                                : '')
                        }
                        data-row={headerRowIndex}
                        data-column={i}
                      >
                        {!isActive && (
                          <div
                            className="input"
                            data-row={headerRowIndex}
                            disabled={isGSheet}
                            onKeyDown={this.handleKeyDown}
                            onFocus={this.onFocus}
                            tabIndex="0"
                            data-column={i}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(isChart ? columns[i]?.columnName : cellValue)
                            }}
                          />
                        )}

                        {isActive && (
                          <RichTextEditor
                            row={headerRowIndex}
                            column={i}
                            isChart={isChart}
                            text={isChart ? columns[i].columnName : cellValue}
                            handleCEUpdate={this.handleCEUpdate}
                            setTableAction={this.setTableAction}
                            bufferDataOptions={bufferDataOptions}
                            activeCellColumn={activeCellColumn}
                            activeCellRow={activeCellRow}
                            keySet={this.keySet}
                            resetKey={this.resetKey}
                          />
                        )}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
        )}
        <tbody>
          {bufferDataOptions.slice(headerRows).length === 0 ? (
            <tr>
              <td>
                <div>{aggregatedOptions?.table?.searchable?.noDataText ?? 'No data'}</div>
              </td>
            </tr>
          ) : (
            bufferDataOptions.slice(headerRows).map((row, i) => {
              return (
                <TableRow
                  key={'row_' + this.getBufferDataRowIndex(searchDataIndex, i)}
                  rowIndex={this.getBufferDataRowIndex(searchDataIndex, i)}
                  changeDirection={this.changeDirection}
                  row={row}
                  searchDataIndex={searchDataIndex}
                  // transform any object into the value prop
                  // bug introduced by rich text editing
                  headers={bufferDataOptions[0].map((x) => x?.value || x)}
                  pastePlainText={this.pastePlainText}
                  disableNewlines={this.disableNewlines}
                  handleCEUpdate={this.handleCEUpdate}
                  rowStyle={rowStyle}
                  aggregatedOptions={aggregatedOptions}
                  classToUse={i === rowSettings && !this.props.inSelectMode ? ' row-setting-hovered' : ''}
                  updateDraggedOver={this.updateDraggedOver}
                  draggedOver={draggedOver}
                  changeRowOrder={this.changeRowOrder}
                  cellOnDblClick={this.cellOnDblClick}
                  onMouseOverCell={this.onMouseOverCell}
                  onMouseDownCell={this.onMouseDownCell}
                  showContextMenu={this.showContextMenu}
                  onFocus={this.onFocus}
                  highCell={highCell}
                  lowCell={lowCell}
                  headerRows={headerRows}
                  finishAndGoDown={this.finishAndGoDown}
                  resultsPerPage={resultsPerPage}
                />
              );
            })
          )}

          {isNull(this.props.activeCellColumn) && isNull(this.props.activeCellRow) && (
            <tr>
              <td className="hidden">
                <RichTextEditor text="" cellStyle={{}} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}

Table.propTypes = {};

export default Table;
