/* eslint-disable @typescript-eslint/no-explicit-any -- this file is in progress, many unknown typings still*/
import { OptionProps, WidgetType } from 'pages/ChartEditorPage/meta/CustomizeOptions';
import { updateThemeConfigAction } from 'pages/CompanyThemeEditorPage/actions/ThemeEditorPage';
import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from 'redux/store';
import IconButton from 'shared/buttons/IconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { GenericInputProps, InputChangeParams, UpdateChartFunction } from 'shared/types/commonPropTypes';
import en from '../../editor/en';
import {
  deleteCustomizedAction,
  updateBasedOnDataPropAction,
  updateDataRelatedPropAction
} from '../../pages/ChartEditorPage/actions/chartEditor';
import { updateDataPropAction } from '../../pages/LayoutEditorPage/actions/layoutEditor.js';
import layoutEn from '../../pages/LayoutEditorPage/meta/en';
import { loadPaginatedAction } from '../../pages/TableEditorPage/actions/tableEditor.js';
import tableEn from '../../pages/TableEditorPage/meta/en';
import { BuildInputsProps } from './BuildInputs';
import fieldsMap from './FieldsMap';
import {
  getFilteredValue,
  getIndexFromPath,
  getUpdateCustomizeAction,
  handleRefersToChange
} from './utils/widgetHelper';
import LabelWithDescription from './components/LabelWithDescription';
import { useChart } from 'pages/ChartEditorPage/meta/highchartsHelper';

const UndoWidgetContainer = (props: { children: ReactNode }) => <div className="flex w-full">{props.children}</div>;
const NormalWidgetContainer = (props: { children: ReactNode }) => <>{props.children}</>;
const classNameColorMap: Record<string, string> = {
  'bg-ev-grey': '#F7F8F8',
  'bg-white': '#FFFFFF'
};
const buttonColorMap: Record<string, ButtonColor> = {
  'bg-ev-grey': ButtonColor.Grey
};

type ConditionalInputProps = {
  value: string | number;
  label: string;
  parent: string;
  option: OptionProps;
  index?: number | string;
  overrideOnChange?: (type: string, option: OptionProps, val: any, extraModulesToLoad?: any) => void;
  parentPath: string;
  aggregatedOptions: any;
  userAggregatedOptions?: any;
  className?: string;
  isArraySelector?: boolean;
};

const ConditionalInput = (props: ConditionalInputProps & BuildInputsProps) => {
  const { option, overrideOnChange, parent, value, parentPath, aggregatedOptions, userAggregatedOptions, className } =
    props;

  const dispatch = useDispatch();
  const { paths } = useSelector((state: RootState) => state.app);
  const { customFonts, logos } = useSelector((state: RootState) => state.layoutEditorPage);
  const chart = useChart();
  const { dataOptions, isCompanyThemeEditor, provider, type, customizedOptions } = useSelector(
    (state: RootState) => state.projectConfig
  );

  // eslint-disable-next-line no-nested-ternary
  const lang = type === 'chart' ? en : type === 'layout' ? layoutEn : tableEn;
  const label = option.description ? (
    <LabelWithDescription label={props.label} description={option.description} />
  ) : (
    props.label
  );

  const updateCustomizedProp: UpdateChartFunction = (type, optionProps, val, extraModulesToLoad, index) => {
    return dispatch(getUpdateCustomizeAction(provider, type)({ optionProps, val, extraModulesToLoad, index }));
  };

  const updateDataRelatedProp: UpdateChartFunction = (_, optionProps, val, extraModulesToLoad, index) => {
    return dispatch(updateDataRelatedPropAction({ optionProps, val, extraModulesToLoad, index }));
  };

  const updateBasedOnDataProp: UpdateChartFunction = (_, optionProps, val, extraModulesToLoad, index) => {
    return dispatch(updateBasedOnDataPropAction({ optionProps, val, extraModulesToLoad, index }));
  };

  const updateCompanyCustomizedProp: UpdateChartFunction = (type, optionProps, val, extraModulesToLoad?, index?) => {
    dispatch(updateThemeConfigAction({ type, optionProps, val, extraModulesToLoad, index }));
  };

  const loadPaginated = (val: any) => dispatch(loadPaginatedAction({ val }));

  const onChange = ({ val, extraModulesToLoad, optional, index }: InputChangeParams) => {
    if (option.refersTo) {
      handleRefersToChange({
        option,
        chart,
        val,
        update: updateCustomizedProp,
        projectType: type
      });
    } else if (option.isBasedOnSeriesData) {
      updateDataRelatedProp(type, optional || option, val, extraModulesToLoad);
    } else if (option.isBasedOnData) {
      updateBasedOnDataProp(type, optional || option, val, extraModulesToLoad);
    } else if (isCompanyThemeEditor) {
      updateCompanyCustomizedProp(type, optional || option, val, extraModulesToLoad);
    } else {
      const change = overrideOnChange || updateCustomizedProp;
      change(type, optional || option, val, extraModulesToLoad, index);
    }

    if (type === 'table' && option.isPagination) {
      loadPaginated(val);
    }
  };

  const onDataChange = (val: string, index: string, parent: string) => {
    dispatch(updateDataPropAction({ option: parent || option.id, index, val }));
  };

  if (option.type === 'subtext' && paths) {
    return (
      <div className="font-medium px-4 pt-2">
        {option.text}{' '}
        <NavLink to={paths[option.pathLink as string]}>
          {option.linkText} <i className="fa-solid fa-arrow-up-right-from-square" />
        </NavLink>
      </div>
    );
  }

  if (option.isSubheader) {
    const index = option.default as keyof typeof lang.entries;
    return <div className="highed-customizer-table-heading">{lang.entries[index]}</div>;
  }

  const getSelectValues = (option: OptionProps) => {
    if (option.values) return option.values;
    if (option.useLogos) return logos;
    if (option.subTypeValues) {
      const index = getIndexFromPath(parentPath);
      const type = getFilteredValue(option, chart, index);
      return option.subTypeValues[type] ?? [];
    }
    return [];
  };

  const clearValue = () => {
    return dispatch(deleteCustomizedAction({ optionProps: option, index: props.index }));
  };

  const InputType = fieldsMap[option.type as WidgetType] || fieldsMap.default;
  const inputTypeProps: GenericInputProps = {
    onChange,
    inputType: option.type,
    onDataChange,
    option,
    selectOptions: getSelectValues(option),
    value,
    label,
    customFonts,
    logos,
    customizedOptions,
    aggregatedOptions,
    userAggregatedOptions,
    lang,
    dataOptions,
    chart,
    parent,
    type,
    parentPath,
    index: props.index,
    className,
    backgroundColor: classNameColorMap[props.className!],
    isArraySelector: props.isArraySelector
  };

  const isArray = 'array' in (option?.types ?? {});
  const showUndoButton = option?.custom?.displayUndoButton || (props.showUndoButton && !isArray);
  const WidgetContainer = showUndoButton ? UndoWidgetContainer : NormalWidgetContainer;

  return (
    <WidgetContainer>
      <InputType {...inputTypeProps} />
      {showUndoButton && (
        <IconButton
          icon={'undo'}
          onClick={clearValue}
          className="rounded-sm ml-1 p-2 px-3"
          buttonColor={buttonColorMap[props.className!] ?? ButtonColor.White}
        />
      )}
    </WidgetContainer>
  );
};

export default ConditionalInput;
