/* eslint-disable @typescript-eslint/no-explicit-any -- this file is in progress, many unknown typings still*/
import { cloneDeep, find, get, isArray, isObject, isString } from 'lodash';
import SearchInput from 'pages/ChartEditorPage/components/advance/SearchInput';
import React, { useEffect, useState } from 'react';
import { uncamelize } from '../../../../editor/core/highcharts-editor';
import Transform from '../../../../editor/core/highed.transforms';
import defaultAdvancedOptions from '../../../../editor/generated_src/highed.meta.options.advanced';
import BuildInputs from '../../../../shared/widgets/BuildInputs';
import {
  exclusionConditions,
  filters,
  getFilteredList,
  getTreeItemClassName
} from '../../utils/advanceCustomizeHelper';
import AddButton from './AddButton';
import DeleteButton from './DeleteButton';
import {
  AdvanceCustomizeProps,
  OptionsAdvance,
  SearchedOption
} from 'pages/ChartEditorPage/types/advance/advanceTypes';

let transform: boolean | Transform = false;
export default function AdvanceCustomize(props: AdvanceCustomizeProps) {
  const { aggregatedOptions: agg, chart } = props;
  const [optionsAdvanced, setOptionsAdvanced] = useState<OptionsAdvance | null>({});
  const [selected, setSelected] = useState<OptionsAdvance | null>({ children: [], meta: {} });
  const [selectedPath, setSelectedPath] = useState<(OptionsAdvance | string)[]>([]);
  const [path, setPath] = useState('');
  const nameMap: Record<string, string> = { Lang: 'Localization' };

  const aggregatedOptions = cloneDeep(agg);
  ['xAxis', 'yAxis'].forEach((key) => {
    if (!(key in aggregatedOptions)) aggregatedOptions[key] = {};
  });

  useEffect(() => {
    if (props.isOpen) {
      // Load customize config
      transform = new Transform();
      let constr = props.constr;

      if (constr === 'Map') constr = 'highmaps';
      else if (constr === 'StockChart') constr = 'highstock';
      else constr = 'highcharts';

      setOptionsAdvanced(transform.transformAdv(defaultAdvancedOptions, true, constr));
    } else {
      setSelectedPath([]);
      setSelected({ children: [], meta: {} });
    }
  }, [props.isOpen]);

  const filterResults = (query: string) => {
    const searchResults: SearchedOption[] = [];
    search(optionsAdvanced?.children, null, query, searchResults);
    return searchResults;
  };

  const handleSearchResultClick = async (ev: React.MouseEvent, breadcrumbs: string, fieldId: string) => {
    let node = optionsAdvanced?.children;
    const breadcrumbsArray = breadcrumbs.split('.');
    for (const [i, path] of breadcrumbsArray.entries()) {
      const result = find(node, { meta: { name: path } }) as OptionsAdvance;
      node = result?.children;
      selectListItem(ev, result, null, breadcrumbsArray.slice(0, i + 1).join('.'), false, false);
      selectedPath.push(result);
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    const field = document.getElementById(`${breadcrumbs}.${fieldId}`);
    field?.scrollIntoView({ behavior: 'smooth' });
    field?.classList.remove('bg-white');
    field?.classList.add('active-highlight');
  };

  const search = (node: any, parent: any, query: string, searchResults: SearchedOption[]) => {
    if (isArray(node)) {
      node.forEach((child) => {
        search(child, parent, query, searchResults);
      });
    } else {
      if (exclusionConditions(node, aggregatedOptions)) return;

      let found = false;
      found = uncamelize(node.meta.name).toLowerCase().includes(query.toLowerCase());

      if (found && node.meta.ns) {
        searchResults.push({
          value: node.meta.ns + node.meta.name,
          fieldId: node.meta.name,
          label: uncamelize(node.meta.name),
          breadcrumbs: node.meta.ns
        });
      }
      if (node.children && node.children.length > 0) {
        search(node.children, node, query, searchResults);
      }
    }
  };

  const resetSelection = (deletedPath: string) => {
    setSelected({ children: [], meta: {} });
    setSelectedPath(
      (selectedPath ?? []).filter((selected) => isString(path) && !(selected as string).includes(deletedPath))
    );
    setPath('');
  };

  const onChange = (
    _type: string,
    optionProps: { name: string; id: string; isOptionalArray?: boolean },
    val: unknown,
    extraModulesToLoad: []
  ) => {
    // Replace first period in path, not needed.
    const changePath = optionProps.name ? `${path}.${optionProps.name}` : `${optionProps.id}`;
    props.updateCustomizedProp(
      { id: changePath, isOptionalArray: optionProps.isOptionalArray },
      val,
      extraModulesToLoad
    );
  };

  const selectListItem = (
    e: React.MouseEvent,
    value: OptionsAdvance,
    arrayOptions: { key: string } | null,
    valuePath: string,
    toggle: boolean,
    isPropArray: boolean | 0 | undefined
  ) => {
    e.stopPropagation();
    e.preventDefault();

    if (isPropArray) return;
    // Want to keep reference to original object so the compare does not mismatch in generateSection
    const newValue = arrayOptions?.key ?? value;
    const path = [...selectedPath];
    if (path.includes(newValue)) {
      // Already opened, close it by removing from the array
      if (toggle) {
        const index = path.indexOf(newValue);
        path.splice(index, 1);
      }
    } else path.push(newValue);

    setSelected(value);
    setSelectedPath(path);
    setPath(valuePath);

    return false;
  };

  const generateListItem = (children: string) => (
    <span className="cursor-pointer">{nameMap[children] ?? children}</span>
  );

  const generateSection = (children: OptionsAdvance[], parent: OptionsAdvance, parentKey: string, level: number) => {
    return children.map((option, i) => {
      if (!option.children?.length) return <></>;
      let fstate;
      const name = parent.meta?.name as keyof typeof filters | undefined;
      if (name && filters[name]) {
        if (option.meta?.validFor) {
          const customizedSeriesOption = chart.series;
          let found = false;
          (customizedSeriesOption || []).forEach(function (serieOption: any) {
            fstate = serieOption[filters[name].controller] || filters[name].default;
            if (option.meta?.validFor && option.meta.validFor[fstate]) found = true;
          });

          if (!found) {
            return <></>;
          }
        }
      }

      const key = `${parentKey ? parentKey + '.' : ''}${option.meta?.name}`;
      const rawKey = `${option.meta?.ns}.${option.meta?.name}`;

      const isDataLabel = option?.meta?.name === 'dataLabels';
      const isSeriesDataLabel = (rawKey ?? '').indexOf('series.dataLabels') > -1;

      const isPropArray =
        (option.meta?.types?.array && !isDataLabel) || (option.meta?.types?.array && isDataLabel && isSeriesDataLabel);

      let useArrayIndexInKey = true;

      let arraySections = isPropArray ? get(aggregatedOptions, key) ?? [] : [];
      if (!isArray(arraySections) && isObject(arraySections)) {
        arraySections = [arraySections];
        useArrayIndexInKey = false;
      }

      const isSingleOpen = selectedPath.includes(option);
      const isLastPath = selected === option;

      return (
        <div key={`advanced_menu_${i}`} onClick={(e) => selectListItem(e, option, null, key, false, isPropArray)}>
          {/* Single entries */}
          <div
            id={key}
            className={`${isSingleOpen && isLastPath ? 'bg-ev-navy-blue-2 text-white font-medium' : ''} ${
              isPropArray ? '' : 'hover:bg-ev-baby-blue-2 hover:text-ev-navy-blue-2 group node'
            }`}
            style={{ paddingLeft: `${(level + 1) * 2}px` }}
          >
            <span
              className={`${getTreeItemClassName(
                selectedPath,
                option,
                isSingleOpen,
                isSingleOpen && isLastPath,
                isPropArray
              )}`}
              onClick={(e) => selectListItem(e, option, null, key, true, isPropArray)}
            />{' '}
            {generateListItem(uncamelize(option.meta?.name ?? ''))}
            {isPropArray && <AddButton elementKey={key} />}
          </div>

          {/* Array entries (ex: Series, YAxis) */}
          {arraySections.map((_: any, index: number) => {
            const internalKey = key + (useArrayIndexInKey ? `[${index}]` : '');
            const open = selectedPath.includes(internalKey);
            const isInternalLastPath = path === internalKey;

            return (
              <div className="node" key={internalKey} id={internalKey}>
                <div
                  onClick={(e) => selectListItem(e, option, { key: internalKey }, internalKey, true, false)}
                  className={`hover:bg-ev-baby-blue-2 hover:text-ev-navy-blue-2 ${
                    open && isInternalLastPath ? 'bg-ev-navy-blue-2 text-white font-medium' : ''
                  } group pr-0.5`}
                  style={{ paddingLeft: `${(level + 1) * 2}px` }}
                >
                  <span
                    className={getTreeItemClassName(
                      selectedPath,
                      { meta: { hasSubTree: true } },
                      open,
                      open && isInternalLastPath
                    )}
                    onClick={(e) => selectListItem(e, option, { key: internalKey }, internalKey, true, false)}
                  />{' '}
                  {generateListItem(`${uncamelize(option.meta?.name ?? '')} ${index + 1}`)}{' '}
                  <DeleteButton index={index} elementKey={internalKey} resetSelection={resetSelection} />
                </div>
                {selectedPath.includes(internalKey) && (
                  <div className="node " id={internalKey}>
                    {generateSection(option.children ?? [], option, internalKey, level + 1)}
                  </div>
                )}
              </div>
            );
          })}
          {!isPropArray && selectedPath.includes(option) && (
            <div id={key} className="node " style={{ paddingLeft: `${(level + 1) * 2}px` }}>
              {generateSection(option.children, option, key, level + 1)}
            </div>
          )}
        </div>
      );
    });
  };

  const selectedArray = getFilteredList(selected, path);
  const header = uncamelize(selected?.meta?.name ?? '');

  return (
    <div className="flex flex-col h-[calc(100vh-316px)]">
      <SearchInput filterResults={filterResults} label="Advanced Search" handleClick={handleSearchResultClick} />
      <div className="flex h-[calc(100vh-380px)]">
        <div className="h-full overflow-auto pr-2 w-1/3 left-advance-panel">
          {generateSection(optionsAdvanced?.children ?? [], {}, optionsAdvanced?.meta?.name ?? '', 0)}
        </div>
        <div className="w-2/3 h-full overflow-auto px-4 bg-[#f5f5f5] right-advance-panel">
          <h3>{nameMap[header] ?? header}</h3>
          <div className="flex flex-col gap-2 mb-6">
            <BuildInputs section={{ options: selectedArray }} overrideOnChange={onChange} showUndoButton={true} />
          </div>
        </div>
      </div>
    </div>
  );
}
