// @ts-expect-error ts-2307
import { ajax } from 'highed';
import React, { useEffect, useState } from 'react';
import TextInput from 'shared/inputs/TextInput';
import { ChartLiveDataProps, LinkDataTypes } from 'shared/wizard/meta/LinkDataOptions';
import { getDataForGoogleErrors } from 'shared/wizard/utils/linkDataHelper';

type GoogleSheetContentProps = {
  handleSubmit: (options: ChartLiveDataProps, dataType: LinkDataTypes) => void;
  shouldSubmit: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorsComponent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

const GoogleSheetContent = (props: GoogleSheetContentProps) => {
  const { handleSubmit, shouldSubmit, setLoading } = props;

  const [worksheet, setWorksheet] = useState('');
  const [googleKey, setGoogleKey] = useState('');
  const [form, setForm] = useState({ url: '', startCells: '', endCells: '' });
  const [errors, setErrors] = useState<string[]>([]);
  const [errorsBlock, setErrorsBlock] = useState<React.ReactNode>();

  useEffect(() => {
    if (googleKey === '') return;
    let range = '';
    const { startCells, endCells } = form;

    if (worksheet && worksheet !== '') range = worksheet + '!';

    range += (startCells || 'A1') + ':';
    range += endCells || 'ZZ';

    const options = {
      googleSpreadsheetKey: googleKey,
      googleSpreadsheetRange: range
    };

    handleSubmit(options, 'google');
  }, [worksheet]);

  useEffect(() => {
    if (shouldSubmit) {
      const match = form.url.match(/https:\/\/docs.google.com\/spreadsheets\/d\/(.{44})\/edit#gid=(\d+)/);
      if (match) {
        setGoogleKey(match[1]);
        loadGSheet(match[1], match[2]);
      } else {
        updateErrors('badUrl');
      }
    }
  }, [shouldSubmit]);

  useEffect(() => {
    setErrorsBlock(
      errors.map((error) => (
        <div className="pt-2 font-bold text-ev-error" key={error}>
          {getDataForGoogleErrors(error)?.text}&nbsp;
          <a href={getDataForGoogleErrors(error)?.link} target="_blank" rel="noreferrer">
            Read more.
          </a>
        </div>
      ))
    );
  }, [errors]);

  const updateErrors = (error: string) => {
    setErrors((prev) => {
      if (!prev.includes(error)) {
        return [...prev, error];
      } else return prev;
    });
  };

  const findRequestedSheet = (data: Record<string, any>, gid: string): string => {
    if (data?.sheets && data.sheets.length) {
      const option = data.sheets.filter((opt: any) => String(opt.properties.sheetId) === String(gid));

      if (option && option[0] && option[0].properties && option[0].properties.title !== '') {
        return option[0].properties.title;
      }
    }
    return '';
  };

  const loadGSheet = (key: string, gid: string) => {
    setLoading(true);

    const link = 'https://sheets.googleapis.com/v4/spreadsheets/';
    ajax({
      url: link + key + '?key=AIzaSyCchblEzIdk4rPKD6sbi72c4OseEdqmyPQ',
      success: (data: any) => {
        setWorksheet(findRequestedSheet(data, gid));
        setLoading(false);
      },
      error: (e: number) => {
        if (e === 403) updateErrors('nonPublic');
        setLoading(false);
      }
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  return (
    <>
      <TextInput
        type="text"
        placeholder="Enter a valid spreadsheet url"
        onChange={onChange}
        name="url"
        value={form.url}
        label="Spreadsheet url"
        inputClassName="highed-field-input"
        labelClass="font-bold text-base"
        error={errors[0]}
        errorsBlock={errorsBlock}
        extraClass="pr-0"
        id="ev-spreadsheet-input"
      />
      <div className="flex gap-2">
        <TextInput
          placeholder="Ex: A1"
          inputClassName="highed-field-input"
          onChange={onChange}
          name="startCells"
          value={form.startCells}
          label="Start cell"
          labelClass="font-bold text-base"
          extraClass="pr-0"
        />
        <TextInput
          placeholder="Ex: ZZ"
          inputClassName="highed-field-input"
          onChange={onChange}
          name="endCells"
          value={form.endCells}
          label="End cell"
          labelClass="font-bold text-base"
          extraClass="pr-0"
        />
      </div>
    </>
  );
};

export default GoogleSheetContent;
