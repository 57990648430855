import { TemplateProps } from '../../types';

const SolidGauge: TemplateProps = {
  title: 'Solid gauge',
  description: '',
  constructor: '',
  thumbnail: 'apocob.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'solidgauge'
    },
    pane: {
      center: ['50%', '85%'],
      size: '140%',
      startAngle: '-90',
      endAngle: '90',
      background: {
        backgroundColor: '#EEE',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc'
      }
    },
    tooltip: {
      enabled: false
    },
    yAxis: {
      stops: [
        [0.1, '#55BF3B'],
        [0.5, '#DDDF0D'],
        [0.9, '#DF5353']
      ],
      min: 0,
      max: 100,
      lineWidth: 0,
      minorTickInterval: null,
      tickPixelInterval: 400,
      tickWidth: 0,
      title: {
        y: -70
      },
      labels: {
        y: 16
      }
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 10,
          borderWidth: 0,
          useHTML: true
        }
      }
    },
    'series[0]': {
      dataLabels: {
        format: '<div style="text-align:center"><span style="font-size:25px;color:#000000">{y}</span></div>'
      }
    }
  },
  sampleSet: 'solidgauge',
  advancedParent: 'Solid guage',
  dataValidator: false,
  basic: {
    order: 27,
    thumbnail: 'gauge_chart.svg',
    icon: 'SolidGauge'
  },
  parent: 'More',
  locked: true
};

export default SolidGauge;
