import { imageOptions } from './ImageOptions';
import { gifVideoOption, mp4VideoOption, webPVideoOption } from './VideoOptions';

export enum SocialMediaOption {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  Twitter = 'Twitter',
  Linkedin = 'Linkedin'
}

export enum Orientation {
  Landscape = 'Landscape',
  Portrait = 'Portrait',
  Square = 'Square'
}

export const socialMediaOptions = {
  [SocialMediaOption.Facebook]: {
    item: { label: SocialMediaOption.Facebook, value: SocialMediaOption.Facebook },
    defaultImageFormat: mp4VideoOption,
    defaultDimensions: {
      [Orientation.Landscape]: { width: 1080, height: 566 },
      [Orientation.Portrait]: { width: 1080, height: 1350 },
      [Orientation.Square]: { width: 1080, height: 1080 }
    }
  },
  [SocialMediaOption.Instagram]: {
    item: { label: SocialMediaOption.Instagram, value: SocialMediaOption.Instagram },
    defaultImageFormat: mp4VideoOption,
    defaultDimensions: {
      [Orientation.Landscape]: { width: 1200, height: 630 },
      [Orientation.Portrait]: { width: 630, height: 1200 },
      [Orientation.Square]: { width: 1200, height: 1200 }
    }
  },
  [SocialMediaOption.Twitter]: {
    item: { label: SocialMediaOption.Twitter, value: SocialMediaOption.Twitter },
    defaultImageFormat: gifVideoOption,
    defaultDimensions: {
      [Orientation.Landscape]: { width: 1024, height: 627 },
      [Orientation.Portrait]: { width: 627, height: 1024 },
      [Orientation.Square]: { width: 1024, height: 1024 }
    }
  },
  [SocialMediaOption.Linkedin]: {
    item: { label: SocialMediaOption.Linkedin, value: SocialMediaOption.Linkedin },
    defaultImageFormat: mp4VideoOption,
    defaultDimensions: {
      [Orientation.Landscape]: { width: 1200, height: 627 },
      [Orientation.Portrait]: { width: 627, height: 1200 },
      [Orientation.Square]: { width: 1200, height: 1200 }
    }
  }
};

export const getFormatList = (type: string) => {
  switch (type) {
    case SocialMediaOption.Twitter:
      return [gifVideoOption, webPVideoOption, ...imageOptions];
    case SocialMediaOption.Linkedin:
    case SocialMediaOption.Facebook:
    case SocialMediaOption.Instagram:
      return [mp4VideoOption, ...imageOptions];
    default:
      return [...imageOptions];
  }
};
