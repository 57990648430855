import DOMPurify from 'dompurify';
import React, { useCallback, useRef } from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import Tooltip from 'shared/tooltip/Tooltip';
import IconButton from 'shared/buttons/IconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { GenericInputProps } from 'shared/types/commonPropTypes';
import { setAction } from '../../../redux/actions/projectConfig';
import { getIndexFromPath } from '../utils/widgetHelper';

type RichtextGroupWidgetProps = {
  onClick: () => void;
};

const RichtextGroupWidget = (props: GenericInputProps & RichtextGroupWidgetProps) => {
  const { option, onClick } = props;
  const dispatch = useDispatch();

  const contentEditableRef = useRef<HTMLDivElement>(null);

  const onChange = useCallback(
    (e: ContentEditableEvent) => {
      const newValue = e.target.value;
      props.onChange({ val: newValue });
    },
    [props.onChange]
  );

  const makeRichtextBox = () => {
    const { option, parentPath } = props;
    const index = getIndexFromPath(parentPath);
    dispatch(
      setAction({
        richtextGroupOptions: {
          option,
          box: contentEditableRef.current,
          index: isNaN(index) ? 'all' : index
        }
      })
    );
  };

  const newValue = (props.value as { text: string }) ?? {}; //extractValue(type, option, chart, aggregatedOptions);
  let text = DOMPurify.sanitize(newValue.text ?? '');
  if (option?.richtext?.removeWatermark) {
    text = text.replace(' © <a href="http://www.naturalearthdata.com">Natural Earth</a>', '');
  }

  const buttonColor = props.className?.includes('bg-ev-grey') ? ButtonColor.Grey : ButtonColor.White;
  const isDisable = option?.shouldBeDisabled && option.shouldBeDisabled(option);

  const widgetClasses = classNames('flex flex-col mt-2', {
    'opacity-60': isDisable
  });

  const inputClasses = classNames(
    'highed-field-input break-all highed-richtext-field group',
    option?.className,
    props.className,
    { 'pointer-events-none': isDisable }
  );

  const richtextGroupWidget = (
    <div className={widgetClasses}>
      {props.label && <div className="text-ev-navy-blue-4 font-bold mb-2">{props.label}</div>}
      <div className="highed-field-container" id={`${option?.id}`}>
        <ContentEditable
          innerRef={contentEditableRef}
          id={option?.id}
          className={inputClasses}
          html={text}
          onChange={onChange}
          suppressContentEditableWarning={true}
          onClick={onClick}
          disabled={isDisable}
        />

        {!isDisable && (
          <IconButton
            icon="pencil"
            className={`w-8 h-8 absolute top-[5px] right-[5px] rounded ${props.className}`}
            onClick={makeRichtextBox}
            buttonColor={buttonColor}
          />
        )}
      </div>
    </div>
  );

  if (isDisable) {
    return <Tooltip tippyProps={{ content: option?.disabledText, placement: 'bottom' }}>{richtextGroupWidget}</Tooltip>;
  }
  return richtextGroupWidget;
};

export default RichtextGroupWidget;
