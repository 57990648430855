import React from 'react';
import { ExportHistoryType } from 'shared/types/exportHistoryType';
import RecentExport from './RecentExport';
import SideContainer from '../message/SideContainer';

type RecentExportListProps = {
  exports: ExportHistoryType[];
  isOpen: boolean;
  onClose: () => void;
};

const RecentExportList = ({ exports, isOpen, onClose }: RecentExportListProps) => {
  const handleOnClose = () => {
    onClose();
  };

  return (
    <SideContainer isOpen={isOpen} header="Recent exports" onClose={handleOnClose}>
      {exports?.length > 0 ? (
        exports.map(
          (
            {
              exportId,
              team_id,
              file_name,
              file_size,
              fileType,
              exportStatus,
              download_link,
              last_export_time,
              exportedBy
            },
            index
          ) => (
            <RecentExport
              key={index}
              exportId={exportId}
              teamId={team_id}
              exportedBy={exportedBy}
              fileName={file_name}
              fileSize={file_size}
              fileType={fileType}
              exportStatus={exportStatus}
              downloadLink={download_link}
              lastExportTime={last_export_time}
            />
          )
        )
      ) : (
        <div>No Recent Exports</div>
      )}
    </SideContainer>
  );
};

export default RecentExportList;
