/* eslint-disable no-invalid-this */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../buttons/Button';
import AlertMessage from '../message/AlertMessage';
import TextInput from '../inputs/TextInput';
import Modal from 'react-modal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { postTeamLogoUsingTeamid } from '../../api/cloudApiGenerated';

class LogoModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.closeModal = this.closeModal.bind(this);
    this.changeMetaData = this.changeMetaData.bind(this);
    this.updateState = this.updateState.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.show = this.show.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.onImageLoaded = this.onImageLoaded.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.dataURLtoFile = this.dataURLtoFile.bind(this);
    this.getCroppedImg = this.getCroppedImg.bind(this);

    this.state = {
      team: props.team,
      showMetaDataModal: false,
      crop: {
        x: 10,
        y: 10,
        keepSelection: true,
        width: 80,
        height: 80
      },
      form: {
        name: {
          value: ''
        }
      },
      error: '',
      file: null,
      src: '',
      croppedImageUrl: null,
      imageRef: null
    };
  }

  onImageLoaded(image) {
    this.setState({
      imageRef: image
    });
  }

  onCropComplete(crop) {
    if (this.state.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.state.imageRef, crop);
    }
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: 'image/jpeg' });
    this.setState({ croppedImage: croppedImage });
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    var originWidth = crop.width * scaleX;
    var originHeight = crop.height * scaleY;
    // maximum width/height
    var maxWidth = 1200,
      maxHeight = 1200 / (16 / 9);
    var targetWidth = originWidth,
      targetHeight = originHeight;
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth;
        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      } else {
        targetHeight = maxHeight;
        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      }
    }
    // set canvas size
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      targetWidth,
      targetHeight
    );

    const reader = new FileReader();
    canvas.toBlob(
      (blob) => {
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          this.dataURLtoFile(reader.result, 'cropped.jpg');
        };
      },
      'image/png',
      1
    );
  }

  onCropChange(crop) {
    this.setState({ crop });
  }

  updateState(event) {
    const field = event.target.name;
    let form = this.state.form;
    form[field].value = event.target.value;
    return this.setState({ form });
  }

  closeModal() {
    this.setState({ showMetaDataModal: false });
  }

  changeMetaData() {
    if (this.state.form.name.value === '') {
      this.setState({
        error: 'Name field is required'
      });
      return;
    }

    const files = document.querySelector('#font').files;

    if (!files || files.length === 0) {
      this.setState({
        error: 'File is required'
      });
      return;
    }

    let formData = new FormData();

    formData.append('logo', this.state.croppedImage);
    formData.append('name', this.state.form.name.value);

    postTeamLogoUsingTeamid(this.props.team.id, formData).then((data) => {
      this.props.snackBar('Logo uploaded');
      this.props.refreshLogos(this.props.team);
      this.closeModal();
    });
  }

  show() {
    const form = this.state.form;
    form.name.value = '';
    this.setState({
      form,
      showMetaDataModal: true
    });
  }

  onFileChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          this.setState({
            src: reader.result
          });
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  render() {
    let form = this.state.form;

    return (
      <Modal
        overlayClassName="hc-modal-overlay"
        appElement={document.querySelector('.page-container')}
        isOpen={this.state.showMetaDataModal}
        contentLabel="Change Details"
        onRequestClose={this.closeModal}
        className="hc-modal-content"
      >
        <h3 className="chart-modal-header">Add logo</h3>
        <div className="row row-centered">
          {this.state.error && <AlertMessage type="danger" hideTag={true} text={this.state.error} extraClass="my-4" />}

          <form role="form">
            <label htmlFor="name"> Name: </label>
            <TextInput
              name="name"
              onChange={this.updateState}
              placeholder="Name"
              value={form.name.value}
              autoFocus={true}
            />
            <div>
              <label htmlFor="private"> File: </label>
            </div>
            <div>
              <input type="file" id="font" name="font" accept="font/*" onChange={this.onFileChange} />
            </div>

            {this.state.src && (
              <ReactCrop
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
                src={this.state.src}
                crop={this.state.crop}
              />
            )}

            <div className="canvas-test"></div>
            <Button buttonClass="btn-primary" buttonText="Save" onClick={this.changeMetaData} />
            <Button buttonClass="btn-secondary" buttonText="Cancel" onClick={this.closeModal} />
          </form>
        </div>
      </Modal>
    );
  }
}

LogoModal.propTypes = {
  team: PropTypes.object,
  snackBar: PropTypes.func,
  refreshLogos: PropTypes.func,
  selectedLogo: PropTypes.object
};

export default LogoModal;
