import { merge } from 'editor/core/highcharts-editor';
import { cloneDeep, get, isNull } from 'lodash';
import DefaultData from 'pages/ChartEditorPage/meta/data/DefaultData';
import { getMapCodeValueIndex } from 'pages/ChartEditorPage/utils/chartEditorDataHelper';
import { call, select } from 'redux-saga/effects';

export const createColorAxisStops = (colors) => {
  const amount = (colors ?? []).length;
  const increments = 1.0 / Math.max(amount - 1, 0);
  let stops = (colors ?? []).map((color, index) => [increments * index, color]) ?? [];
  return {
    type: 'logarithmic',
    stops
  };
};

export const createColorAxisDataClasses = (colors, values) => {
  const amount = (colors ?? []).length;
  const max = Math.max(...values);
  const min = Math.min(...values);
  const difference = max - min;
  const segmentAmount = difference / amount;
  let dataClasses = (colors ?? []).map((color, index) => {
    const lastOption = index === amount - 1;
    return {
      from: (min + segmentAmount * index).toFixed(2),
      to: (lastOption ? max : min + segmentAmount * (index + 1)).toFixed(2),
      color,
      name: `Option ${index + 1}`
    };
  });
  return {
    dataClasses
  };
};

export const parseThemeOptions = (theme, rawOptions, template, values) => {
  const colors = get(rawOptions, 'map.all.everviz.colors');
  if (colors) {
    const colorAxis = get(template, 'colorAxis');
    const isDataClasses = (colorAxis && !('stops' in colorAxis)) || isNull(colorAxis?.stops);
    const isStops = (colorAxis && !('dataClasses' in colorAxis)) || isNull(colorAxis?.dataClasses);
    if (isDataClasses) theme.colorAxis = createColorAxisDataClasses(colors, values);
    else if (isStops) theme.colorAxis = createColorAxisStops(colors);
    else theme.colors = colors;
  }
  return theme;
};

const tableKey = {
  align: 'textAlign'
};
const chartKey = {
  color: 'style.color',
  fontStyle: 'style.fontStyle',
  textDecoration: 'style.textDecoration',
  fontSize: 'style.fontSize',
  textAlign: 'align',
  fontFamily: 'style.fontFamily',
  fontWeight: 'style.fontWeight'
};

export const parseCompanyThemeRichtextOptions = (options) => {
  const splitId = options.id.split('.');
  const key = splitId[splitId.length - 1];
  const isChartSpecific = options.aliasKey.includes('chart.all');
  options.aliasKey = isChartSpecific
    ? options.aliasKey.replace('.text', `.${chartKey[key] ?? key}`)
    : [
        options.aliasKey.replace('all.', 'everviz.all.').replace('.text', `.style.${tableKey[key] ?? key}`),
        options.aliasKey.replace('all.', 'highcharts.all.').replace('.text', `.${chartKey[key] ?? key}`)
      ];
  return options;
};

export const createChartDefault = (project) =>
  merge(cloneDeep(project.all), merge(cloneDeep(project.highcharts.all), cloneDeep(project.chart.all)));
export const createLayoutDefault = (project) => merge(cloneDeep(project.all), cloneDeep(project.everviz.all));
export const createTableDefault = (project) =>
  merge(cloneDeep(project.all), merge(cloneDeep(project.everviz.all), cloneDeep(project.table.all)));

export function* createMapDefault(project) {
  const { dataOptions, templateOptions, seriesAssigns } = yield select((state) => state.projectConfig);
  const values = getMapValues(seriesAssigns, dataOptions);
  values.shift();
  let mapDefault = merge(cloneDeep(project.all), merge(cloneDeep(project.highcharts.all), cloneDeep(project.map.all)));
  const colors = get(project, 'map.all.everviz.colors');
  if (colors) {
    mapDefault = parseThemeOptions(mapDefault, project, templateOptions?.[0] ?? {}, values);
  }
  return mapDefault;
}

export const getMapValues = (seriesAssigns, dataOptions) => {
  let { mapValueIndex } = getMapCodeValueIndex(seriesAssigns);
  return dataOptions.map((data) => data[mapValueIndex]);
};

export const loadChartData = (type) => {
  return cloneDeep({
    csv: DefaultData[type ?? 'line'].data,
    seriesMapping: DefaultData[type ?? 'line'].seriesMapping
  });
};
export const loadMapData = (type) => {
  return cloneDeep({
    csv: DefaultData[type ?? 'choropleth'].data,
    seriesMapping: DefaultData[type ?? 'choropleth'].seriesMapping
  });
};

export function* createDefaults(project) {
  const mapDefault = yield call(createMapDefault, project);
  return {
    chartDefault: createChartDefault(project),
    mapDefault,
    tableDefault: createTableDefault(project),
    layoutDefault: createLayoutDefault(project)
  };
}
