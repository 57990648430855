/* eslint-disable max-depth -- to be addressed when refactoring this file */
import React from 'react';
import MergeIcon from './quill/MergeIcon';
import BorderIcon from './quill/BorderIcon';
import FillIcon from './quill/FillIcon';
import Model from './RichTextEditorModel';
import BorderIconsToolbar from './quill/BorderIconsToolbar';
import ColorPickerModal from 'shared/modal/ColorPickerModal';
import { isNull, isObj } from 'editor/core/highcharts-editor';

export default class RichTextEditorToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.merge = this.merge.bind(this);
    this.borderClick = this.borderClick.bind(this);
    this.fillClick = this.fillClick.bind(this);
    this.closeBorderIconsToolbar = this.closeBorderIconsToolbar.bind(this);
    this.setBordersAllEdgesClick = this.setBordersAllEdgesClick.bind(this);
    this.onClick = this.onClick.bind(this);
    this.showWizard = this.showWizard.bind(this);
    this.showExport = this.showExport.bind(this);
    this.showColorPicker = this.showColorPicker.bind(this);
    this.showContextMenu = this.showContextMenu.bind(this);
    this.setRichTextAllProp = this.setRichTextAllProp.bind(this);
    this.onEditorFormatChange = this.onEditorFormatChange.bind(this);
    this.fillOnChange = this.fillOnChange.bind(this);
    this.onDataStyleChange = this.onDataStyleChange.bind(this);

    this.state = {
      showBorderIcons: false,
      showColorPickerModal: false,
      position: { xPos: 0, yPos: 0 },
      fillColor: '',
      cssMap: {
        bold: 'fontWeight',
        italic: 'fontStyle',
        underline: 'textDecoration'
      },
      colorPickerOnChange: null
    };
  }

  merge() {
    this.props.mergeCells(this.props.dataOptions, this.props.activeBufferCell, this.props.activeSelectedBufferCell);
  }

  closeBorderIconsToolbar() {
    this.setState({
      showBorderIcons: false
    });
  }

  borderClick() {
    this.setState({
      showBorderIcons: !this.state.showBorderIcons
    });
    // this.props.setDataStyle(this.props.dataOptions, this.props.activeBufferCell, this.props.activeSelectedBufferCell, 'border-right', '1px');
  }

  fillOnChange(color) {
    this.props.setDataStyle(
      this.props.dataOptions,
      this.props.activeBufferCell,
      this.props.activeSelectedBufferCell,
      'backgroundColor',
      color
    );
    this.setState({
      fillColor: color
    });
  }

  fillClick(e) {
    this.setState({
      position: { xPos: e.clientX, yPos: e.clientY },
      showColorPickerModal: true,
      colorPickerOnChange: this.fillOnChange
    });
  }

  onClick(e) {
    const { activeSelectedBufferCell, activeBufferCell, dataOptions, activeCellRow, activeCellColumn } = this.props;

    const target = e.currentTarget;

    if (!isNull(activeCellRow) && !isNull(activeCellColumn)) {
      // Remove if has property in cell
      const activeCell = dataOptions[activeCellRow][activeCellColumn];
      const suffix = target.getAttribute('data-suffix');

      if (activeCell) {
        if (isObj(activeCell) && activeCell.style) {
          if (this.state.cssMap[suffix] in activeCell.style) {
            const editor = Model.editor.getEditor();
            if (editor) {
              if (editor.getSelection().length !== editor.getText().replace('\n', '').length) {
                this.props.removeDataStyle(
                  dataOptions,
                  activeBufferCell,
                  activeSelectedBufferCell,
                  target.getAttribute('data-cssprop')
                );
                target.className = 'ql-' + suffix;
                const selection = editor.getSelection();
                editor.setSelection(0, editor.getText().replace('\n', '').length);
                editor.format(suffix, true);
                editor.setSelection(selection.index, selection.length);
                editor.format(suffix, false);
                target.className = 'ql-' + suffix;
              } else {
                target.className = 'ql-' + suffix;
                editor.setSelection(0, editor.getText().replace('\n', '').length);
                editor.format(suffix, false);
                editor.setSelection(0, editor.getText().replace('\n', '').length);
                target.className = 'ql-' + suffix;
              }
            }
          }
        }
      }

      this.props.removeDataStyle(
        this.props.dataOptions,
        this.props.activeBufferCell,
        this.props.activeSelectedBufferCell,
        target.getAttribute('data-cssprop')
      );
    } else {
      this.props.toggleDataStyle(
        dataOptions,
        activeBufferCell,
        activeSelectedBufferCell,
        target.getAttribute('data-cssprop'),
        target.getAttribute('data-cssvalue')
      );
    }
  }

  setBordersAllEdgesClick(e) {
    const target = e.currentTarget;
    this.props.setBordersAllEdges(
      this.props.dataOptions,
      this.props.activeBufferCell,
      this.props.activeSelectedBufferCell,
      target.getAttribute('data-type')
    );
  }

  setRichTextAllProp(e) {
    e.preventDefault();
    const { activeBufferCell, activeCellRow, activeCellColumn } = this.props;

    if (activeBufferCell && isNull(activeCellColumn) && isNull(activeCellRow)) {
      const target = e.currentTarget;

      this.props.setTableAction({
        activeCellRow: activeBufferCell[1],
        activeCellColumn: activeBufferCell[0]
      });

      setTimeout(() => {
        const editor = Model.editor.getEditor();
        const suffix = target.getAttribute('data-suffix');
        const value = target.getAttribute('value');

        target.className = 'ql-' + suffix;
        editor.setSelection(0, editor.getText().replace('\n', '').length);
        editor.format(suffix, value);
        editor.setSelection(0, editor.getText().replace('\n', '').length);
        target.className = 'ql-' + suffix;
      }, 10);
    }
  }

  showWizard() {
    if (
      confirm('Are you sure you would like to re-import your data? This will clear any data you have already set up.')
    ) {
      this.props.resetData(this.props.dataOptions, this.props.customizedOptions);
      this.props.showWizard();
    }
  }

  onDataStyleChange(color) {
    const { activeSelectedBufferCell, activeBufferCell, dataOptions } = this.props;
    this.props.toggleDataStyle(dataOptions, activeBufferCell, activeSelectedBufferCell, 'color', color);
    this.setState({
      fillColor: color
    });
  }

  onEditorFormatChange(color) {
    let editor = Model.editor.getEditor(),
      selection = editor.getSelection();

    editor.setSelection(selection.index, selection.length);
    editor.format('color', color);
    this.setState({
      fillColor: color
    });
  }

  showColorPicker(e) {
    let onChangeFunction;
    const { activeCellRow, activeCellColumn } = this.props;

    if (!isNull(activeCellRow) && !isNull(activeCellColumn)) {
      // In a cell, let quill take over
      onChangeFunction = this.onEditorFormatChange;
    } else {
      // if (activeSelectedBufferCell) {
      // Multiple
      onChangeFunction = this.onDataStyleChange;
    }
    this.setState({
      position: { xPos: e.clientX, yPos: e.clientY },
      showColorPickerModal: true,
      colorPickerOnChange: onChangeFunction
    });
  }

  showExport() {
    this.props.showExportModal('export');
  }

  showContextMenu(e) {
    const { activeBufferCell } = this.props;
    if (activeBufferCell) {
      this.props.showContextMenu(e.clientX, e.clientY, activeBufferCell[0], activeBufferCell[1]);
    }
  }

  render() {
    const {
      activeSelectedBufferCell,
      activeCell,
      bufferDataOptions,
      isGSheet,
      activeCellRow,
      activeCellColumn,
      activeBufferCell
    } = this.props;

    return (
      <div
        id="toolbar"
        className={`ql-toolbar ql-snow ${this.props.className ? this.props.className : 'hidden ev-md:block'}`}
      >
        {!isGSheet && (
          <span
            className={
              'rich-editor-container' +
              (isNull(activeSelectedBufferCell) &&
              isNull(activeCell) &&
              isNull(activeCellColumn) &&
              isNull(activeCellRow) &&
              isNull(activeBufferCell)
                ? ' inactive'
                : '')
            }
          >
            <button
              className="ql-bold"
              onClick={this.onClick}
              title="Bold"
              data-cssprop={'fontWeight'}
              data-suffix={'bold'}
              data-cssvalue={'bold'}
            />
            <button
              className="ql-italic"
              onClick={this.onClick}
              data-cssprop={'fontStyle'}
              data-suffix={'italic'}
              data-cssvalue={'italic'}
              title="Italic"
            />
            <button
              className="ql-underline"
              onClick={this.onClick}
              data-cssprop={'textDecoration'}
              data-suffix={'underline'}
              data-cssvalue={'underline'}
              title="Underline"
            />
            <button className={'ql-link ' + (activeSelectedBufferCell ? 'inactive' : '')} title="Link" />

            <button
              className="ql-align"
              value=""
              onClick={this.onClick}
              title="Left align"
              data-suffix={'align'}
              data-cssprop={'textAlign'}
              data-cssvalue={'left'}
            />
            <button
              className="ql-align"
              value="center"
              onClick={this.onClick}
              title="Center align"
              data-suffix={'align'}
              data-cssprop={'textAlign'}
              data-cssvalue={'center'}
            />
            <button
              className="ql-align"
              value="right"
              onClick={this.onClick}
              title="Right align"
              data-suffix={'align'}
              data-cssprop={'textAlign'}
              data-cssvalue={'right'}
            />
            <button
              className="ql-align"
              value="justify"
              onClick={this.onClick}
              title="Justify align"
              data-suffix={'align'}
              data-cssprop={'textAlign'}
              data-cssvalue={'justify'}
            />

            <button
              onClick={this.setRichTextAllProp}
              className={'ql-script ' + (activeSelectedBufferCell ? 'inactive' : '')}
              value="sub"
              title="Subscript"
              data-suffix={'script'}
            />
            <button
              onClick={this.setRichTextAllProp}
              className={'ql-script ' + (activeSelectedBufferCell ? 'inactive' : '')}
              value="super"
              title="Superscript"
              data-suffix={'script'}
            />

            <button className={'ql-color'} onClick={this.showColorPicker} title="Color" data-suffix={'color'} />

            {bufferDataOptions.length !== 0 && (
              <>
                <button
                  id="quill-merge-btn"
                  className="quill-merge-btn background-color-btn"
                  title="Fill"
                  onClick={this.fillClick}
                >
                  <FillIcon />
                </button>

                <button id="quill-merge-btn" className="quill-merge-btn merge-icon" title="Merge" onClick={this.merge}>
                  <MergeIcon />
                </button>

                <div className="quill-border-btn">
                  <button id="quill-merge-btn" className="quill-merge-btn" title="Border" onClick={this.borderClick}>
                    <BorderIcon />
                  </button>
                  {this.state.showBorderIcons && (
                    <div className="border-toolbar">
                      <BorderIconsToolbar
                        closeBorderIconsToolbar={this.closeBorderIconsToolbar}
                        setBordersAllEdgesClick={this.setBordersAllEdgesClick}
                        setBorderStyle={this.props.setBorderStyle}
                        dataOptions={this.props.dataOptions}
                        activeBufferCell={this.props.activeBufferCell}
                        activeSelectedBufferCell={this.props.activeSelectedBufferCell}
                      />
                    </div>
                  )}
                </div>

                <div className="add-row-btn">
                  <button className={'add-row-column'} onClick={this.showContextMenu}>
                    <i className="fa fa-plus-circle" aria-hidden="true" />
                  </button>
                </div>
              </>
            )}
          </span>
        )}

        <div className="import-export-button">
          <span className="import" onClick={this.showWizard}>
            Import
          </span>
          |
          <span className="export" onClick={this.showExport}>
            Export
          </span>
        </div>

        {this.state.showColorPickerModal && (
          <ColorPickerModal
            value={this.state.fillColor}
            isOpen={this.state.showColorPickerModal}
            onClose={() => this.setState({ showColorPickerModal: false, colorPickerOnChange: null })}
            setValue={this.state.colorPickerOnChange}
            showGradientColor={false}
            colors={[]}
            position={this.state.position}
          />
        )}
      </div>
    );
  }
}
