import { ProjectConfigLocationMapProps } from '../../pages/Editor/reducers/locationMapConfigTypes';

export default [
  {
    id: 99999994,
    name: 'Basic',
    isDefault: true,
    thumbnail_url: '/static/thumbnails/themes/basic.png',
    template: {
      title: {
        style: {
          fontWeight: 'bold',
          fontSize: '18px'
        }
      },
      subtitle: {
        style: {
          fontSize: '13px'
        }
      },
      caption: {
        style: {
          fontSize: '13px'
        }
      },
      styleOverride: {
        fontFamily: 'gordita'
      },
      theme: {
        type: 'dynamic',
        url: `${window.hcconfig.resourcesOrigin.frontend}/resources/location-map/themes/basic.json`
      },
      fullScreenControls: {
        enabled: true
      },
      customAttribution: {
        text: 'everviz'
      }
    } satisfies ProjectConfigLocationMapProps['themeOptions'],
    plugins: {
      cssModules: [`${window.hcconfig.resourcesOrigin.frontend}/static/fonts/gordita/style.css`]
    }
  },
  {
    id: 99999998,
    name: 'Alidade Smooth',
    thumbnail_url: '/static/thumbnails/themes/alidade-smooth.png',
    template: {
      title: {
        style: {
          fontWeight: 'bold',
          fontSize: '18px'
        }
      },
      subtitle: {
        style: {
          fontSize: '13px'
        }
      },
      caption: {
        style: {
          fontSize: '13px'
        }
      },
      styleOverride: {
        fontFamily: 'gordita'
      },
      theme: {
        type: 'dynamic',
        url: `${window.hcconfig.resourcesOrigin.frontend}/resources/location-map/themes/alidade_smooth.json`
      },
      fullScreenControls: {
        enabled: true
      },
      customAttribution: {
        text: 'everviz'
      }
    } satisfies ProjectConfigLocationMapProps['themeOptions'],
    plugins: {
      cssModules: [`${window.hcconfig.resourcesOrigin.frontend}/static/fonts/gordita/style.css`]
    }
  }
];
