import { TemplateProps } from '../../types';

const PackedBubble: TemplateProps = {
  title: 'Packed bubble',
  description: '',
  constructor: '',
  thumbnail: 'packed.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'packedbubble'
    },
    legend: {
      itemMarginBottom: 4,
      margin: 0,
      padding: 0
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true
        },
        lineWidth: 0
      },
      packedbubble: {
        minSize: '30%',
        maxSize: '120%'
      }
    }
  },
  parseData: 'packedbubble',
  plugins: ['packedbubble'],
  advancedParent: 'PackedBubble',
  sampleSet: 'packedbubble',
  dataValidator: false,
  basic: {
    order: 10,
    thumbnail: 'word_cloud.svg',
    icon: 'PackedBubble'
  },
  advanced: {
    name: 'Standard'
  },
  parent: 'PackedBubble',
  locked: true
};

export default PackedBubble;
