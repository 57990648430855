export default {
  'option.text.title': [
    {
      id: 'title',
      pid: 'option.text.title',
      options: [
        {
          id: 'title.text',
          pid: 'title.text',
          placeholder: '[Input Text Here]'
        },
        { type: 'separator' },
        {
          id: 'title.style',
          pid: 'title.style.font',
          type: 'fontpicker'
        },
        {
          id: 'title.style',
          pid: 'title.font.style',
          type: 'fontstyler',
          custom: {
            fields: {
              bold: 1,
              italic: 1,
              color: 1,
              fontSize: 1,
              align: 1
            }
          }
        },
        { type: 'separator' },
        {
          id: 'title.style.backgroundColor',
          pid: 'title.backgroundColor',
          type: 'color',
          default: 'transparent'
        }
      ]
    }
  ],
  'option.text.subtitle': [
    {
      id: 'subtitle',
      pid: 'option.text.subtitle',
      options: [
        {
          id: 'subtitle.text',
          pid: 'subtitle.text',
          placeholder: '[Input Text Here]'
        },
        { type: 'separator' },
        {
          id: 'subtitle.style',
          pid: 'subtitle.style.font',
          type: 'fontpicker'
        },
        {
          id: 'subtitle.style',
          pid: 'subtitle.font.style',
          type: 'fontstyler',
          custom: {
            fields: {
              bold: 1,
              italic: 1,
              color: 1,
              fontSize: 1,
              align: 1
            }
          }
        },
        { type: 'separator' },
        {
          id: 'subtitle.style.backgroundColor',
          pid: 'subtitle.style.backgroundColor',
          type: 'color',
          default: 'transparent'
        }
      ]
    }
  ],

  'option.text.caption': [
    {
      id: 'caption',
      pid: 'option.text.caption',
      options: [
        {
          id: 'caption.text',
          pid: 'caption.text',
          type: 'text',
          placeholder: '[Input Text Here]'
        },
        { type: 'separator' },
        {
          id: 'caption.style',
          pid: 'caption.style.font',
          type: 'fontpicker'
        },
        {
          id: 'caption.style',
          pid: 'caption.font.style',
          type: 'fontstyler',
          custom: {
            fields: {
              bold: 1,
              italic: 1,
              color: 1,
              fontSize: 1,
              align: 1
            }
          }
        },
        { type: 'separator' },
        {
          id: 'caption.style.backgroundColor',
          pid: 'caption.style.backgroundColor',
          type: 'color',
          default: 'transparent'
        }
      ]
    }
  ],

  'option.text.canvas': [
    {
      id: 'canvas',
      pid: 'option.text.canvas',
      options: [
        {
          id: 'canvas.style.padding',
          pid: 'canvas.style.padding',
          type: 'fourinputs',
          fourInputs: {
            options: [
              'canvas.style.paddingTop',
              'canvas.style.paddingRight',
              'canvas.style.paddingBottom',
              'canvas.style.paddingLeft'
            ]
          },
          default: [10, 10, 10, 10]
        },
        {
          pid: 'canvas.style.backgroundColor',
          id: 'canvas.style.backgroundColor',
          type: 'color',
          default: 'transparent'
        },
        {
          type: 'separator'
        },
        {
          id: 'table.extra.position',
          pid: 'table.extra.position',
          type: 'select',
          title: 'Extras position',
          default: 'top',
          values: [
            {
              label: 'Top',
              value: 'top'
            },
            {
              label: 'Bottom',
              value: 'bottom'
            }
          ]
        },
        {
          pid: 'table.extra.style.backgroundColor',
          id: 'table.extra.style.backgroundColor',
          type: 'color',
          default: 'transparent'
        },
        {
          pid: 'table.extra.style.borderWidth',
          id: 'table.extra.style.borderWidth',
          type: 'number',
          default: 0
        },
        {
          pid: 'table.extra.style.borderColor',
          id: 'table.extra.style.borderColor',
          type: 'color',
          default: 'transparent'
        }
      ]
    }
  ],
  'option.text.table': [
    {
      id: 'table',
      pid: 'option.text.table',
      options: [
        {
          id: 'table.searchable.enabled',
          pid: 'table.searchable.enabled',
          type: 'checkbox',
          default: false,
          postFields: [
            {
              optionNeeded: [true],
              className: 'mx-4',
              options: [
                {
                  pid: 'table.searchable.labelText',
                  id: 'table.searchable.labelText',
                  default: 'Search in table',
                  inputClassName: 'bg-ev-grey',
                  type: 'string'
                },
                {
                  pid: 'table.searchable.placeholderText',
                  id: 'table.searchable.placeholderText',
                  default: 'Search in table',
                  inputClassName: 'bg-ev-grey',
                  type: 'string'
                },
                {
                  pid: 'table.searchable.style.borderColor',
                  id: 'table.searchable.style.borderColor',
                  default: '#fff',
                  type: 'color'
                },
                {
                  pid: 'table.searchable.noDataText',
                  id: 'table.searchable.noDataText',
                  default: 'No data',
                  inputClassName: 'bg-ev-grey',
                  type: 'string'
                }
              ]
            }
          ]
        },
        {
          pid: 'table.sortable',
          id: 'table.sortable',
          type: 'checkbox',
          default: false
        },
        {
          pid: 'table.cards',
          id: 'table.cards',
          type: 'checkbox',
          default: false
        },
        {
          pid: 'table.pagination.enabled',
          id: 'table.pagination.enabled',
          isPagination: true,
          type: 'checkbox',
          default: false,
          postFields: [
            {
              optionNeeded: [true],
              className: 'mx-4',
              options: [
                {
                  isPagination: true,
                  default: 10,
                  pid: 'table.pagination.resultsPerPage',
                  id: 'table.pagination.resultsPerPage',
                  type: 'number'
                },
                {
                  pid: 'table.pagination.backButtonStyle.backgroundColor',
                  id: 'table.pagination.backButtonStyle.backgroundColor',
                  default: '#fff',
                  type: 'color'
                },
                {
                  pid: 'table.pagination.backButtonStyle.color',
                  id: 'table.pagination.backButtonStyle.color',
                  default: '#172b4c',
                  type: 'color'
                },
                {
                  pid: 'table.pagination.frontButtonStyle.backgroundColor',
                  id: 'table.pagination.frontButtonStyle.backgroundColor',
                  default: '#172b4c',
                  type: 'color'
                },
                {
                  pid: 'table.pagination.frontButtonStyle.color',
                  id: 'table.pagination.frontButtonStyle.color',
                  default: '#fff',
                  type: 'color'
                },
                {
                  pid: 'table.pagination.text.style.color',
                  id: 'table.pagination.text.style.color',
                  default: '#000',
                  type: 'color'
                }
              ]
            }
          ]
        },
        {
          type: 'separator'
        },
        {
          pid: 'table.style.font',
          id: 'table.style',
          type: 'fontpicker'
        },
        {
          pid: 'table.font.style',
          id: 'table.style',
          type: 'fontstyler',
          custom: {
            fields: {
              bold: 1,
              italic: 1,
              color: 1,
              fontSize: 1,
              align: 1
            }
          }
        },
        {
          type: 'separator'
        },
        {
          pid: 'table.style.backgroundColor',
          id: 'table.style.backgroundColor',
          type: 'color',
          default: 'transparent'
        },
        {
          pid: 'table.style.borderWidth',
          id: 'table.style.borderWidth',
          type: 'number',
          default: '1px',
          suffix: 'px'
        },
        {
          pid: 'table.style.borderColor',
          id: 'table.style.borderColor',
          type: 'color',
          default: '#dddddd'
        }
      ]
    }
  ],
  'option.text.header': [
    {
      id: 'header',
      pid: 'option.text.header',
      options: [
        {
          pid: 'header.style.font',
          id: 'header.style',
          type: 'fontpicker'
        },
        {
          pid: 'header.font.style',
          id: 'header.style',
          type: 'fontstyler',
          custom: {
            fields: {
              bold: 1,
              italic: 1,
              color: 1,
              fontSize: 1,
              align: 1
            }
          }
        },
        {
          pid: 'header.style.backgroundColor',
          id: 'header.style.backgroundColor',
          type: 'color',
          default: 'transparent'
        },
        {
          pid: 'header.headerRows',
          id: 'header.headerRows',
          type: 'number',
          default: 1,
          isFullNumber: true,
          min: 0,
          max: 'rows'
        }
      ]
    }
  ],
  'option.text.rows': [
    {
      pid: 'option.text.rows',
      id: 'rows',
      options: [
        {
          pid: 'rows.stripes',
          id: 'rows.stripes',
          type: 'checkbox',
          default: false,
          postFields: [
            {
              optionNeeded: [true],
              className: 'mx-4',
              options: [
                {
                  pid: 'rows.stripesColor',
                  id: 'rows.stripesColor',
                  type: 'color'
                },
                {
                  pid: 'rows.textColor',
                  id: 'rows.textColor',
                  type: 'color'
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  'option.text.columns': [
    {
      pid: 'option.text.columns',
      id: 'columns',
      options: [
        {
          pid: 'columns.selector',
          id: 'columns',
          type: 'columnselector'
        }
      ]
    }
  ],
  'option.text.export': [
    {
      text: 'option.util.empty',

      options: [
        {
          id: 'exporting.enabled',
          pid: 'exporting.enabled',
          type: 'checkbox',
          default: true
        },
        {
          pid: 'exporting.sourceWidth',
          id: 'exporting.sourceWidth',
          type: 'number'
        },
        {
          pid: 'exporting.style.backgroundColor',
          id: 'exporting.style.backgroundColor',
          type: 'color',
          default: '#F7F8F8'
        },
        {
          pid: 'exporting.style.color',
          id: 'exporting.style.color',
          type: 'color',
          default: '#28277E'
        },
        {
          id: 'exporting.localization',
          pid: 'exporting.localization',
          type: 'accordion',

          options: [
            {
              id: 'exporting.lang.contextButtonTitle',
              pid: 'exporting.lang.contextButtonTitle',
              type: 'string',
              default: 'Table context menu'
            },
            {
              id: 'exporting.lang.downloadPNG',
              pid: 'exporting.lang.downloadPNG',
              type: 'string',
              default: 'Download PNG image'
            },
            {
              id: 'exporting.lang.downloadJPEG',
              pid: 'exporting.lang.downloadJPEG',
              type: 'string',
              default: 'Download JPEG image'
            },
            {
              id: 'exporting.lang.downloadPDF',
              pid: 'exporting.lang.downloadPDF',
              type: 'string',
              default: 'Download PDF document'
            },
            {
              id: 'exporting.lang.downloadCSV',
              pid: 'exporting.lang.downloadCSV',
              type: 'string',
              default: 'Download CSV'
            },
            {
              id: 'exporting.lang.downloadXLS',
              pid: 'exporting.lang.downloadXLS',
              type: 'string',
              default: 'Download XLS'
            }
          ]
        }
      ]
    }
  ],
  'option.text.chart': [
    {
      id: 'chart',
      pid: 'option.text.chart',
      options: [
        {
          id: 'column',
          pid: 'chart.columns',
          type: 'tablechart'
        }
      ]
    }
  ],
  'option.text.logo': [
    {
      id: 'logo',
      pid: 'option.text.logo',
      options: [
        {
          pid: 'logo.url',
          id: 'logo.url',
          type: 'select',
          useLogos: true
        },
        {
          pid: 'logo.position',
          id: 'logo.position',
          type: 'placementalignwidget',
          default: 'bottomLeft',
          custom: {
            placement: {
              topLeft: 1,
              bottomLeft: 1,
              topRight: 1,
              bottomRight: 1
            }
          }
        },
        {
          pid: 'logo.width',
          id: 'logo.width',
          type: 'number',
          suffix: '%',
          default: '100%'
        },
        {
          pid: 'logo.minWidth',
          id: 'logo.minWidth',
          type: 'number'
        },
        {
          pid: 'logo.style.padding',
          id: 'logo.style.padding',
          type: 'fourinputs',
          fourInputs: {
            options: [
              'logo.style.paddingTop',
              'logo.style.paddingRight',
              'logo.style.paddingBottom',
              'logo.style.paddingLeft'
            ]
          }
        },
        {
          type: 'subtext',
          text: 'Logos can be added through the ',
          linkText: 'customize editor page',
          pathLink: 'profileCustomiseEditor'
        }
      ]
    }
  ]
};
