import { TemplateProps } from '../../types';

const Unit: TemplateProps = {
  title: 'Unit chart',
  description: '',
  constructor: '',
  thumbnail: 'item-icon.png',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'item'
    },
    exporting: {}
  },
  sampleSet: 'unit',
  plugins: ['icon-chart'],
  advancedParent: 'Unit chart',
  dataValidator: false,
  basic: {
    order: 9,
    thumbnail: 'icon-unit.svg',
    icon: 'Unit'
  },
  skipEmit: true,
  parent: 'More',
  locked: true
};

export default Unit;
