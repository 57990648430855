import evervizTheme from '../../editor/meta/highed.meta.everviz.theme';

export default [
  {
    id: 99999995,
    name: 'everviz',
    isDefault: true,
    thumbnail_url: '/static/thumbnails/themes/theme-everviz.png',
    template: evervizTheme,
    customCode: [
      "Highcharts.createElement('link', { href: 'https://fonts.googleapis.com/css?family=Open+Sans', rel: 'stylesheet', type: 'text/css' }, null, document.getElementsByTagName('head')[0]);"
    ]
  },
  {
    id: 99999996,
    name: 'Comfort',
    isDefault: true,
    thumbnail_url: '/static/thumbnails/themes/theme-comfort.png',
    template: {
      colors: ['#47475c', '#4e8c61', '#9EB3DC', '#ffb36a', '#b3621c', '#5c1800'],
      chart: {
        style: {
          fontFamily: "'Open Sans', sans-serif",
          color: '#666666'
        }
      },
      title: {
        fontSize: '24px',
        align: 'left'
      },
      subtitle: {
        align: 'left'
      },
      legend: {
        align: 'right',
        verticalAlign: 'bottom'
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: '#F3F3F3',
        lineColor: '#F3F3F3',
        minorGridLineColor: '#F3F3F3',
        tickColor: '#F3F3F3',
        tickWidth: 1
      },
      yAxis: {
        gridLineColor: '#F3F3F3',
        lineColor: '#F3F3F3',
        minorGridLineColor: '#F3F3F3',
        tickColor: '#F3F3F3',
        tickWidth: 1
      },
      credits: {
        text: 'www.everviz.com',
        href: 'https://www.everviz.com'
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          pointPadding: 0.1,
          groupPadding: 0.05,
          shadow: false,
          marker: {
            enabled: false
          }
        }
      },
      everviz: {
        text: {
          dynamicFonts: true
        }
      }
    },
    customCode: [
      "Highcharts.createElement('link', { href: 'https://fonts.googleapis.com/css?family=Open+Sans', rel: 'stylesheet', type: 'text/css' }, null, document.getElementsByTagName('head')[0]);"
    ]
  },
  {
    id: 99999997,
    isDefault: true,
    name: 'Monochromatic',
    thumbnail_url: '/static/thumbnails/themes/theme-monochromatic.png',
    template: {
      colors: ['#1E3A8A', '#1D4ED8', '#3B82F6', '#93C5FD', '#DBEAFE'],
      chart: {
        style: {
          fontFamily: 'Inter',
          color: '#444444'
        }
      },
      plotOptions: {
        series: {
          pointPadding: 0.1,
          borderWidth: 0,
          shadow: false,
          lineWidth: 3
        }
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: '#F3F3F3',
        lineColor: '#F3F3F3',
        minorGridLineColor: '#F3F3F3',
        tickColor: '#F3F3F3',
        tickWidth: 1
      },
      credits: {
        text: 'www.everviz.com',
        href: 'https://www.everviz.com'
      },
      yAxis: {
        gridLineColor: '#F3F3F3',
        lineColor: '#F3F3F3',
        minorGridLineColor: '#F3F3F3',
        tickColor: '#F3F3F3',
        tickWidth: 1
      },
      everviz: {
        text: {
          dynamicFonts: true
        }
      }
    },
    customCode: [
      "Highcharts.createElement('link', { href: 'https://fonts.googleapis.com/css?family=Inter', rel: 'stylesheet', type: 'text/css' }, null, document.getElementsByTagName('head')[0]);"
    ]
  },
  {
    id: 99999998,
    name: 'Monokai',
    thumbnail_url: '/static/thumbnails/themes/theme-monokai.png',
    template: {
      colors: ['#f92672', '#66d9ef', '#a6e22e', '#fd971f', '#ae81ff'],
      chart: {
        backgroundColor: '#2e2e2e',
        style: {
          fontFamily: "'Roboto Mono','Maven Pro',sans-serif",
          color: '#d6d6d6'
        }
      },
      title: {
        style: {
          color: '#d6d6d6'
        },
        align: 'left'
      },
      subtitle: {
        style: {
          color: '#d6d6d6'
        },
        align: 'left'
      },
      legend: {
        align: 'right',
        verticalAlign: 'bottom',
        itemStyle: {
          fontWeight: 'normal',
          color: '#d6d6d6'
        }
      },
      credits: {
        text: 'www.everviz.com',
        href: 'https://www.everviz.com'
      },
      xAxis: {
        gridLineDashStyle: 'Dot',
        gridLineWidth: 1,
        gridLineColor: '#A2A39C',
        lineColor: '#A2A39C',
        minorGridLineColor: '#A2A39C',
        tickColor: '#A2A39C',
        tickWidth: 1,
        title: {
          style: {
            color: '#d6d6d6'
          }
        },
        labels: {
          style: {
            color: '#d6d6d6'
          }
        }
      },
      yAxis: {
        gridLineDashStyle: 'Dot',
        gridLineColor: '#A2A39C',
        lineColor: '#A2A39C',
        minorGridLineColor: '#A2A39C',
        tickColor: '#A2A39C',
        tickWidth: 1,
        title: {
          style: {
            color: '#d6d6d6'
          }
        },
        labels: {
          style: {
            color: '#d6d6d6'
          }
        }
      },
      plotOptions: {
        series: {
          dataLabels: {
            style: {
              textOutline: '0',
              textShadow: 'false',
              color: '#d6d6d6'
            }
          },
          borderWidth: '1',
          borderColor: '#2e2e2e'
        }
      },
      rangeSelector: {
        inputStyle: {
          color: '#d6d6d6'
        },
        labelStyle: {
          color: '#d6d6d6'
        }
      },
      everviz: {
        text: {
          dynamicFonts: true
        }
      }
    },
    customCode: [
      "Highcharts.createElement('link', { href: 'https://fonts.googleapis.com/css?family=Roboto+Mono', rel: 'stylesheet', type: 'text/css' }, null, document.getElementsByTagName('head')[0]);"
    ]
  },
  {
    id: 99999999,
    name: 'Google',
    isDefault: true,
    thumbnail_url: '/static/thumbnails/themes/theme-google.png',
    template: {
      colors: ['#4285F4', '#DB4437', '#F4B400', '#0F9D58'],
      chart: {
        style: {
          fontFamily: 'Roboto',
          color: '#444444'
        }
      },
      credits: {
        text: 'www.everviz.com',
        href: 'https://www.everviz.com'
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: '#F3F3F3',
        lineColor: '#F3F3F3',
        minorGridLineColor: '#F3F3F3',
        tickColor: '#F3F3F3',
        tickWidth: 1
      },
      yAxis: {
        gridLineColor: '#F3F3F3',
        lineColor: '#F3F3F3',
        minorGridLineColor: '#F3F3F3',
        tickColor: '#F3F3F3',
        tickWidth: 1
      },
      everviz: {
        text: {
          dynamicFonts: true
        }
      }
    },
    customCode: [
      "Highcharts.createElement('link', { href: 'https://fonts.googleapis.com/css?family=Roboto', rel: 'stylesheet', type: 'text/css' }, null, document.getElementsByTagName('head')[0]);"
    ]
  }
];
