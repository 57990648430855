import { cloneDeep } from 'lodash';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actionTypes from 'redux/actions/action-types';
import { getProjectConfig } from 'redux/selectors/projectConfig';
import { setAction as setProjectConfigAction } from '../../../../redux/actions/projectConfig';
import { GenericPayload } from '../ChartEditorPointMap';
import { ProjectConfigLocationMapProps } from '../../../Editor/reducers/locationMapConfigTypes';
import {
  AnimationCameraUpdate,
  AnimationViewState,
  AnimationOptions,
  KeyFramePayload,
  AnimationProgressCallback
} from '@visual-elements/location-map';
import { updateAggregated } from '../ChartEditor';

type ToggleAnimationViewPayload = GenericPayload & {
  data: { action: 'off' | 'on' | 'toggle' };
};

export function* toggleAnimationView(params: ToggleAnimationViewPayload) {
  try {
    const { locationMapOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newLocationMapOptions = cloneDeep(locationMapOptions);

    if (params.data.action === 'on') newLocationMapOptions.animationOptions.viewEnabled = true;
    else if (params.data.action === 'off') newLocationMapOptions.animationOptions.viewEnabled = false;
    else if (params.data.action === 'toggle') {
      newLocationMapOptions.animationOptions.viewEnabled = !newLocationMapOptions.animationOptions.viewEnabled;
    }

    yield put(
      setProjectConfigAction({
        locationMapOptions: newLocationMapOptions
      })
    );
  } catch (err) {
    console.log(err);
  }
}

type AddAnimationKeyFramePayload = GenericPayload & {
  data: { index: number; bearing: number; zoom: number; pitch: number; center: [number, number] };
};

export function* addAnimationKeyFrame(params: AddAnimationKeyFramePayload) {
  try {
    const { locationMapOptions, customizedOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newLocationMapOptions = cloneDeep(locationMapOptions);
    const newCustomizedOptions = cloneDeep(customizedOptions);

    const map = newLocationMapOptions.editorMapRef?.mapRef?.getMap();

    if (!map) throw new Error('Map must be defined if it has reported viewstate');

    if (!newCustomizedOptions.animation || !newCustomizedOptions.animation.enabled) {
      newCustomizedOptions.animation = {
        enabled: true,
        sequence: [],
        setup: {
          actions: [],
          initialViewState: {
            bearing: params.data.bearing,
            zoom: params.data.zoom,
            pitch: params.data.pitch,
            center: params.data.center
          }
        }
      };
    } else {
      newCustomizedOptions.animation.sequence ??= [];
      newCustomizedOptions.animation.sequence.push({
        type: 'cameraUpdate',
        payload: {
          bearing: params.data.bearing,
          center: params.data.center,
          duration: 3000,
          easing: 'in&out',
          pitch: params.data.pitch,
          zoom: params.data.zoom
        },
        time: 0
      });
    }

    newLocationMapOptions.animationOptions.selectedKeyFrame = newCustomizedOptions.animation.sequence?.length ?? 0;

    yield put(
      setProjectConfigAction({
        locationMapOptions: newLocationMapOptions,
        customizedOptions: newCustomizedOptions
      })
    );
    yield call(updateAggregated, true);
  } catch (err) {
    console.log(err);
  }
}

type StartEditAnimationKeyFramePayload = GenericPayload & {
  data: { index: number };
};

export function* startEditAnimationKeyFrame(params: StartEditAnimationKeyFramePayload) {
  try {
    const { locationMapOptions, customizedOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newLocationMapOptions = cloneDeep(locationMapOptions);

    // One can assume this is not partial as one has to add a keyframe to update one
    const animationOptions = customizedOptions.animation as AnimationOptions;

    newLocationMapOptions.animationOptions.isPaused = false;
    newLocationMapOptions.animationOptions.isPlaying = false;

    locationMapOptions.editorMapRef?.mapRef?.getAnimationControls().stop();
    locationMapOptions.previewMapRef?.mapRef?.getAnimationControls().stop();

    const cameraUpdateKeyframes: AnimationCameraUpdate[] = animationOptions.sequence.filter(
      (x) => x.type === 'cameraUpdate'
    ) as AnimationCameraUpdate[];

    let viewState: AnimationViewState | undefined;

    if (params.data.index === 0) {
      viewState = animationOptions.setup.initialViewState;
      newLocationMapOptions.animationOptions.selectedKeyFrame = params.data.index;
    } else if (params.data.index > 0 && customizedOptions.animation?.sequence) {
      const keyFrame = cameraUpdateKeyframes[params.data.index - 1];
      if (keyFrame) {
        viewState = {
          bearing: keyFrame.payload.bearing,
          pitch: keyFrame.payload.pitch,
          center: keyFrame.payload.center,
          zoom: keyFrame.payload.zoom
        };
      }
      newLocationMapOptions.animationOptions.selectedKeyFrame = params.data.index;
    }

    if (viewState) {
      newLocationMapOptions.editorMapRef?.mapRef?.getMap()?.jumpTo({
        ...viewState
      });
    }

    yield put(
      setProjectConfigAction({
        locationMapOptions: newLocationMapOptions
      })
    );
  } catch (err) {
    console.log(err);
  }
}

type UpdateAnimationKeyFramePayload = GenericPayload & {
  data: {
    index: number;
    viewState?: { bearing: number; center: [number, number]; pitch: number; zoom: number };
    duration?: number;
    easing?: KeyFramePayload['easing'];
  };
};

export function* updateAnimationKeyFrame(params: UpdateAnimationKeyFramePayload) {
  try {
    const { locationMapOptions, customizedOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newLocationMapOptions = cloneDeep(locationMapOptions);
    const newCustomizedOptions = cloneDeep(customizedOptions);

    // One can assume this is not partial as one has to add a keyframe to update one
    const animationOptions = newCustomizedOptions.animation as AnimationOptions;

    const cameraUpdateKeyframes: AnimationCameraUpdate[] = animationOptions.sequence.filter(
      (x) => x.type === 'cameraUpdate'
    ) as AnimationCameraUpdate[];

    if (params.data.index === 0) {
      if (!params.data.viewState) throw new Error('Viewstate must be defined to update the initial view state');
      animationOptions.setup.initialViewState = {
        center: params.data.viewState.center,
        bearing: params.data.viewState.bearing,
        pitch: params.data.viewState.pitch,
        zoom: params.data.viewState.zoom
      };
    } else if (params.data.index > 0) {
      const keyFrame = cameraUpdateKeyframes[params.data.index - 1];
      if (keyFrame) {
        if (params.data.duration) {
          keyFrame.payload.duration = params.data.duration;
        }
        if (params.data.viewState) {
          keyFrame.payload = {
            ...keyFrame.payload,
            center: params.data.viewState.center,
            bearing: params.data.viewState.bearing,
            pitch: params.data.viewState.pitch,
            zoom: params.data.viewState.zoom
          };
        }
        if (params.data.easing) {
          keyFrame.payload.easing = params.data.easing;
        }
      }
    }

    yield put(
      setProjectConfigAction({
        locationMapOptions: newLocationMapOptions,
        customizedOptions: newCustomizedOptions
      })
    );
    yield call(updateAggregated, true);
  } catch (err) {
    console.log(err);
  }
}

type SeekToTimePayload = GenericPayload & {
  data: { time: number };
};

export function* seekToTime(params: SeekToTimePayload) {
  try {
    const { locationMapOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newLocationMapOptions = cloneDeep(locationMapOptions);
    console.log(params);
    // const map = newLocationMapOptions.primaryMapRef?.getLocationMap();

    // TODO Seek to time through a location map function

    yield put(
      setProjectConfigAction({
        locationMapOptions: newLocationMapOptions
      })
    );
  } catch (err) {
    console.log(err);
  }
}

type PlayAnimationPayload = GenericPayload & {
  data: { from: 'start' | number };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function* playAnimation(params: PlayAnimationPayload) {
  try {
    const { locationMapOptions, customizedOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newLocationMapOptions = cloneDeep(locationMapOptions);

    const keyFrames = customizedOptions.animation?.sequence?.filter((x) => x?.type === 'cameraUpdate')?.length;
    if (keyFrames === undefined || keyFrames <= 0) return;

    const controls = newLocationMapOptions.editorMapRef?.mapRef?.getAnimationControls();
    const controlsPreview = newLocationMapOptions.previewMapRef?.mapRef?.getAnimationControls();

    if (controls) {
      if (newLocationMapOptions.animationOptions.isPaused) {
        controlsPreview?.resume();
        controls.resume();
      } else {
        controlsPreview?.play();
        controls.play();
      }
      newLocationMapOptions.animationOptions.isPlaying = true;
      newLocationMapOptions.animationOptions.isPaused = false;

      yield put(
        setProjectConfigAction({
          locationMapOptions: newLocationMapOptions
        })
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* pauseAnimation() {
  try {
    const { locationMapOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newLocationMapOptions = cloneDeep(locationMapOptions);

    const controls = newLocationMapOptions.editorMapRef?.mapRef?.getAnimationControls();
    const controlsPreview = newLocationMapOptions.previewMapRef?.mapRef?.getAnimationControls();

    if (controls) {
      controls.pause();
      controlsPreview?.pause();

      newLocationMapOptions.animationOptions.isPlaying = false;
      newLocationMapOptions.animationOptions.isPaused = true;

      yield put(
        setProjectConfigAction({
          locationMapOptions: newLocationMapOptions
        })
      );
    }
  } catch (err) {
    console.log(err);
  }
}

type SelectCurrentKeyFrame = GenericPayload & {
  data: { index: number };
};

export function* selectCurrentKeyFrame(params: SelectCurrentKeyFrame) {
  try {
    const { locationMapOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);

    const newLocationMapOptions = cloneDeep(locationMapOptions);

    newLocationMapOptions.animationOptions.selectedKeyFrame = params.data.index;

    yield put(
      setProjectConfigAction({
        locationMapOptions: newLocationMapOptions
      })
    );
  } catch (err) {
    console.log(err);
  }
}

type StepKeyframePayload = GenericPayload & {
  data: { direction: 'forward' | 'backward' };
};

export function* stepKeyframe(params: StepKeyframePayload) {
  try {
    const { locationMapOptions, customizedOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);

    if (!customizedOptions.animation) return;
    const animationOptions = customizedOptions.animation as AnimationOptions;

    if (
      params.data.direction === 'forward' &&
      locationMapOptions.animationOptions.selectedKeyFrame < animationOptions.sequence.length
    ) {
      yield call(startEditAnimationKeyFrame, {
        data: { index: locationMapOptions.animationOptions.selectedKeyFrame + 1 },
        type: actionTypes.locationMap.startEditAnimationKeyFrame
      });
    } else if (params.data.direction === 'backward' && locationMapOptions.animationOptions.selectedKeyFrame > 0) {
      yield call(startEditAnimationKeyFrame, {
        data: { index: locationMapOptions.animationOptions.selectedKeyFrame - 1 },
        type: actionTypes.locationMap.startEditAnimationKeyFrame
      });
    }
  } catch (err) {
    console.log(err);
  }
}

type HandleAnimationProgressUpdatePayload = GenericPayload & {
  data: Parameters<AnimationProgressCallback>[0];
};

export function* handleAnimationProgressUpdate(params: HandleAnimationProgressUpdatePayload) {
  try {
    const { locationMapOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newLocationMapOptions = cloneDeep(locationMapOptions);

    newLocationMapOptions.animationOptions.selectedKeyFrame = params.data.keyframeIndex;
    if (params.data.isEnd) {
      newLocationMapOptions.editorMapRef?.mapRef?.getMap()?.flyTo({});
      newLocationMapOptions.animationOptions.selectedKeyFrame++;

      newLocationMapOptions.animationOptions.isPaused = false;
      newLocationMapOptions.animationOptions.isPlaying = false;
    }

    yield put(
      setProjectConfigAction({
        locationMapOptions: newLocationMapOptions
      })
    );
  } catch (err) {
    console.log(err);
  }
}

type RemoveAnimationKeyFramePayload = GenericPayload & {
  data: { index: number };
};

export function* removeAnimationKeyFrame(params: RemoveAnimationKeyFramePayload) {
  try {
    const { locationMapOptions, customizedOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newLocationMapOptions = cloneDeep(locationMapOptions);
    const newCustomizedOptions = cloneDeep(customizedOptions);

    // One can assume this is not partial as one has to add a keyframe to remove one
    const animationOptions = newCustomizedOptions.animation as AnimationOptions;

    const cameraUpdateKeyframes: AnimationCameraUpdate[] = animationOptions.sequence.filter(
      (x) => x.type === 'cameraUpdate'
    ) as AnimationCameraUpdate[];

    if (params.data.index === 0) {
      if (cameraUpdateKeyframes.length === 0) {
        newCustomizedOptions.animation = { enabled: false };
      } else {
        animationOptions.setup.initialViewState = cameraUpdateKeyframes[0]?.payload;
        const index = animationOptions.sequence.findIndex((x) => x.type === 'cameraUpdate');
        animationOptions.sequence.splice(index, 1);
      }
    } else {
      animationOptions.sequence.splice(params.data.index - 1, 1);
      if (newLocationMapOptions.animationOptions.selectedKeyFrame === params.data.index) {
        const lastIndex = cameraUpdateKeyframes.length - 1;

        newLocationMapOptions.animationOptions.selectedKeyFrame = lastIndex;
        let newSelectedKeyFrameViewState: AnimationViewState;
        if (lastIndex === 0) {
          newSelectedKeyFrameViewState = animationOptions.setup.initialViewState;
        } else {
          newSelectedKeyFrameViewState =
            cameraUpdateKeyframes[newLocationMapOptions.animationOptions.selectedKeyFrame - 1].payload;
        }
        newLocationMapOptions.editorMapRef?.mapRef?.getMap()?.jumpTo({
          ...newSelectedKeyFrameViewState
        });
      }
    }

    yield put(
      setProjectConfigAction({
        locationMapOptions: newLocationMapOptions,
        customizedOptions: newCustomizedOptions
      })
    );
    yield call(updateAggregated, true);
  } catch (err) {
    console.log(err);
  }
}

export function* watchToggleAnimationView() {
  yield takeEvery(actionTypes.locationMap.toggleAnimationView, toggleAnimationView);
}

export function* watchSeekToTime() {
  yield takeEvery(actionTypes.locationMap.seekToTime, seekToTime);
}

export function* watchHandleAnimationProgressUpdate() {
  yield takeEvery(actionTypes.locationMap.handleAnimationProgressUpdate, handleAnimationProgressUpdate);
}

export function* watchSelectCurrentKeyFrame() {
  yield takeEvery(actionTypes.locationMap.selectCurrentKeyFrame, selectCurrentKeyFrame);
}

export function* watchPauseAnimation() {
  yield takeEvery(actionTypes.locationMap.pauseAnimation, pauseAnimation);
}

export function* watchPlayAnimation() {
  yield takeEvery(actionTypes.locationMap.playAnimation, playAnimation);
}

export function* watchStepKeyframe() {
  yield takeEvery(actionTypes.locationMap.stepKeyframe, stepKeyframe);
}

export function* watchStartEditAnimationKeyFrame() {
  yield takeEvery(actionTypes.locationMap.startEditAnimationKeyFrame, startEditAnimationKeyFrame);
}

export function* watchRemoveAnimationKeyFrame() {
  yield takeEvery(actionTypes.locationMap.removeAnimationKeyFrame, removeAnimationKeyFrame);
}

export function* watchAddAnimationKeyFrame() {
  yield takeEvery(actionTypes.locationMap.addAnimationKeyFrame, addAnimationKeyFrame);
}

export function* watchUpdateAnimationKeyFrame() {
  yield takeEvery(actionTypes.locationMap.updateAnimationKeyFrame, updateAnimationKeyFrame);
}

export default function* rootSaga() {
  yield all([
    watchSeekToTime(),
    watchHandleAnimationProgressUpdate(),
    watchSelectCurrentKeyFrame(),
    watchToggleAnimationView(),
    watchAddAnimationKeyFrame(),
    watchRemoveAnimationKeyFrame(),
    watchUpdateAnimationKeyFrame(),
    watchStartEditAnimationKeyFrame(),
    watchStepKeyframe(),
    watchPauseAnimation(),
    watchPlayAnimation()
  ]);
}
