import { cloneDeep } from 'lodash';
import React, { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { setAction } from 'redux/actions/projectConfig';
import OptionsPanelContainer from 'shared/editor/generic/OptionsPanelContainer';
import ProjectContainer from 'shared/editor/generic/ProjectContainer';
import WorkspaceBar from 'shared/editor/generic/WorkspaceBar';
import ChartPreview from 'shared/wizard/data/ChartPreview';
import { isUsingChartType } from 'shared/wizard/utils/seriesHelper';
import { RootState } from '../../../redux/store';
import { DynamicResolutionContainer } from '../../../shared/editor/generic/DynamicResolutionContainer';
import InlineOptionsContainer from '../../../shared/editor/generic/InlineOptionsContainer';
import LocationMapToolbar from '../../../shared/editor/generic/LocationMapToolbar';
import { LocationMapAnimationTimeline } from '../../../shared/editor/generic/locationMap/AnimationTimeline';
import { useIsMobile } from '../../../shared/utils/mediaHelper';
import OptionsPanel, { PanelOptionProps } from './../../../shared/editor/generic/OptionsPanel';
import DataGrid from './../../../shared/wizard/data/DataGrid';
import PanelOptions from './../meta/PanelOptions';
import EditorError from './EditorError';
import EditorPublish from './EditorPublish';

type ChartEditorProps = {
  showWizard: boolean;
  aggregatedOptions: any;
  history: RouteComponentProps['history'];
  chart: any;
  dataConfig: any;
  isMap: boolean;
};

function getTopBar(provider: 'locationMap' | 'highcharts', isPreview: boolean, breakPoint: boolean) {
  switch (provider) {
    case 'highcharts':
      return <></>;
    case 'locationMap':
      return isPreview || !breakPoint ? (
        <></>
      ) : (
        <InlineOptionsContainer>
          <LocationMapToolbar isCompact={false} />
        </InlineOptionsContainer>
      );
  }
}

function getBottomBar(provider: 'locationMap' | 'highcharts', isPreview: boolean) {
  switch (provider) {
    case 'highcharts':
      return <></>;
    case 'locationMap':
      return <LocationMapAnimationTimeline editable={!isPreview} />;
  }
}

const ChartEditor = (props: ChartEditorProps) => {
  const dispatch = useDispatch();
  const containerRef = createRef<HTMLDivElement>();
  const { editorConfig } = useSelector((state: RootState) => state.chartEditorPage);
  const { tab, activeColumns, activeSection, isPastingActive, provider, inPackagesMode } = useSelector(
    (state: RootState) => state.projectConfig
  );
  const { resolution } = useSelector((state: RootState) => state.layoutEditorPage);
  const isMobile = useIsMobile();
  const [panelOptions, setPanelOptions] = useState<Array<PanelOptionProps>>(PanelOptions);
  const inDataTab = tab === 'data';
  const isWordcloud = isUsingChartType(props.aggregatedOptions, 'wordcloud');
  const inAdvancedCustomizeMode = ['advanced', 'inspector'].includes(activeSection);

  const filterOptions = (options: Array<PanelOptionProps>) => {
    const editorOptions = editorConfig?.features;
    if (editorOptions) {
      const newOptions = options.filter((option) => {
        return !option.permission || (option.permission && editorOptions.indexOf(option.permission) > -1);
      });
      return newOptions;
    }
    return options;
  };

  useEffect(() => {
    if (editorConfig && !inPackagesMode) {
      const options = panelOptions.filter(
        (option) =>
          !option.permission ||
          !editorConfig.features ||
          (option.permission && editorConfig.features.includes(option.permission as string))
      );
      setPanelOptions(cloneDeep(options));
    }
  }, [editorConfig]);
  const isLocationMap = provider === 'locationMap';

  useEffect(() => {
    if (!inPackagesMode) {
      let options = cloneDeep(PanelOptions);

      if (props.isMap) {
        options = options.filter((option) => !option.mapDisabled);
      }
      if (isLocationMap) {
        options = options.filter((option) => !option.locationMapDisabled);
      }
      setPanelOptions(filterOptions(options));
    }
  }, [props.isMap, isLocationMap]);

  useEffect(() => {
    if (inPackagesMode) {
      const options = panelOptions.filter((option) => option.id === 'publish');
      setPanelOptions(cloneDeep(options));
    }
  }, [inPackagesMode]);

  const toggleIsPastingActive = (toggle?: boolean) => {
    dispatch(
      setAction({
        isPastingActive: toggle ?? !isPastingActive
      })
    );
  };

  const TopBar = getTopBar(provider, tab === 'publish', isMobile);
  const BottomBar = getBottomBar(provider, tab === 'publish');

  const largePreview = inDataTab ? isWordcloud || isLocationMap : true;

  return (
    <div className="story chart-type flex flex-col h-full">
      {isMobile && <EditorPublish />}
      <WorkspaceBar panelOptions={panelOptions} />

      <div className="flex w-full h-full flex-col ev-sm:flex-row">
        <OptionsPanelContainer isAdvanced={inAdvancedCustomizeMode}>
          <OptionsPanel options={props.aggregatedOptions} panelOptions={panelOptions} />
        </OptionsPanelContainer>
        <ProjectContainer topBar={TopBar} bottomBar={BottomBar}>
          <div ref={containerRef} className="h-full w-full flex justify-center">
            <DynamicResolutionContainer
              parentRef={containerRef}
              wantedResolution={resolution}
              className="canvas-container bg-transparent"
            >
              {inDataTab && !isWordcloud && !isLocationMap && (
                <div className="relative everviz-datagrid h-full">
                  <DataGrid minRows={30} togglePasting={toggleIsPastingActive} />
                </div>
              )}
              <ChartPreview
                activeSeries={activeColumns.series}
                large={largePreview}
                redrawOnRender={!isWordcloud}
                parent={containerRef}
                inEditor={true}
              />
            </DynamicResolutionContainer>
          </div>
        </ProjectContainer>
      </div>

      <EditorError />
    </div>
  );
};

export default ChartEditor;
