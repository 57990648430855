import qs from 'qs';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { getCreatorsAction, getTagsAction } from './actions/projectsPage';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import TextButton from 'shared/buttons/TextButton';
import ProfilePageContainer from 'shared/profile/ProfilePageContainer';
import ProfilePageSectionContainer from 'shared/profile/ProfilePageSectionContainer';
import { goToPage } from 'utils/profileHelper';
import FeedbackModal from './components/FeedbackModal';
import ProjectCard from './components/ProjectCard';
import PlaceholderCard from './components/ProjectsPagePlaceholderCard';
import ProjectsView from './components/ProjectsView';
import Wizard from './components/Wizard';
import QuickExport from './components/QuickExport';

export default function ProjectsPage(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const inWizard = 'wizard' in query;
  const profileCharts = qs.parse(location.pathname, { ignoreQueryPrefix: true });

  useEffect(() => {
    // Entered through create new account step
    // Start off the wizard steps
    inWizard ? props.set({ isWizardActive: true }) : props.set({ isWizardActive: false });

    // todo: this should be moved to middleware
    dispatch(getTagsAction());
    dispatch(getCreatorsAction());
  }, []);

  const toggleDeletedFilter = () => {
    const profileChartsPath = Object.keys(profileCharts)[0];
    const updatedPath = profileChartsPath.replace(/\/deleted$/, '');
    history.push(props.deleted ? updatedPath : profileChartsPath + '/deleted');
  };

  return (
    <ProfilePageContainer
      title="My projects"
      header={
        <>
          Projects
          <PrimaryButton
            text={props.deleted ? 'View all projects' : 'View deleted projects'}
            onClick={toggleDeletedFilter}
            buttonColor={ButtonColor.WhiteBordered}
            className="text-base mt-4 md:mt-0"
          />
        </>
      }
      description={
        <>
          The project homepage lists all charts, maps, tables and layouts you have created. Clicking a project on this
          page opens the everviz editor for customizing a project.{' '}
          <TextButton
            onClick={() => goToPage('https://help.everviz.com/article/1158-project-homepage')}
            text="More information about this page can be found in our knowledge base."
          />
        </>
      }
    >
      <ProfilePageSectionContainer>
        <div className="charts-page card-view w-full">
          <Wizard isWizardActive={props.isWizardActive} />
          <FeedbackModal isOpen={props.isFeedbackFormActive} />
          <QuickExport />
          <ProjectsView
            with={ProjectCard}
            placeholder={PlaceholderCard}
            deleted={props.deleted}
            showCreateCard={true}
          />
        </div>
      </ProfilePageSectionContainer>
    </ProfilePageContainer>
  );
}
