/* eslint-disable @typescript-eslint/no-explicit-any -- this file is in progress, many unknown typings still*/
import React from 'react';
import ConditionalInput from './../../shared/widgets/ConditionalInput';
import { cloneDeep, get, isArray, isFunction } from 'lodash';
import { OptionProps, PostFieldsProps } from 'pages/ChartEditorPage/meta/CustomizeOptions';
import { getAggregatedOptionsFunction } from 'pages/ChartEditorPage/utils/optionsHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { langMap } from 'shared/utils/LangMap';
import { merge } from 'editor/core/highcharts-editor';
import themeEn from '../../pages/CompanyThemeEditorPage/meta/en';
import {
  extractOptionalArrayVal,
  extractRefersToVal,
  extractRichtextValue,
  getFilteredValue,
  getIndexFromPath,
  getNestedValue
} from './utils/widgetHelper';
import { useChart } from 'pages/ChartEditorPage/meta/highchartsHelper';

export type BuildInputsProps = {
  overrideOnChange?: any;
  section?: any;
  overrideOptions?: any;
  loadPaginated?: (parent: Window, val: string) => void;
  customizedOptions?: any;
  parentKey?: string;
  parentPath?: string;
  overrideExtractValue?: any;
  editorConfig?: any;
  index?: number | string;
  showUndoButton?: boolean;
  classNameOverride?: string;
  isArraySelector?: boolean;
};

export const keepWhiteBgMap = {
  string: 1
};

const EmptyContainer = (props: { children: React.ReactNode }) => <>{props.children}</>;
const PostFieldsContainer = (props: { children: React.ReactNode }) => (
  <div className="bg-white flex flex-wrap gap-x-1 flex-col gap-y-2">{props.children}</div>
);

const BuildInputs = (props: BuildInputsProps) => {
  const { parentPath, section, parentKey, overrideOnChange, classNameOverride } = props;
  const chart = useChart();
  const {
    themeOptions,
    customizedOptions,
    aggregatedOptions: agg,
    isCompanyThemeEditor,
    provider,
    type
  } = useSelector<any, any>((state: RootState) => state.projectConfig);

  let userAggregatedOptions = agg;
  const lang = isCompanyThemeEditor ? themeEn : langMap[type ?? 'table'];
  let aggregated: any;

  const aggregatedOptionsFunction = getAggregatedOptionsFunction(provider, type);
  if (aggregatedOptionsFunction) aggregated = aggregatedOptionsFunction(userAggregatedOptions, chart);
  else {
    userAggregatedOptions = merge(cloneDeep(themeOptions.options), cloneDeep(customizedOptions), null, {});
    aggregated = merge({}, userAggregatedOptions ?? {}, null, {});
  }

  const getSubtypeDefaultValue = (section: any, property: OptionProps) => {
    if (!property.subTypedefault) return;
    const index = getIndexFromPath(parentPath);
    const type = getFilteredValue(section, chart, index);
    return (property.subTypedefault as any)[type];
  };

  const extractValue = (aggregatedOptions: any, property: OptionProps) => {
    if (props.overrideExtractValue) return props.overrideExtractValue(property);
    const options = props.overrideOptions ?? aggregatedOptions ?? {};
    let val: unknown;

    if (property?.richtext) val = extractRichtextValue(provider, type, property, chart, aggregatedOptions);
    else if (property?.nestedValue) val = getNestedValue(options, aggregatedOptions, property);
    else if (property?.refersTo) val = extractRefersToVal(chart, options, property);
    else if (property?.isOptionalArray) val = extractOptionalArrayVal(options, property);
    else if (isArray(property?.id)) val = get(options, property.id[0] ?? '');
    else if (property?.useCustomizedOptions) val = get(customizedOptions, property.id ?? '');
    else val = get(options, property.id ?? '');

    if (isFunction(val)) return null;
    return val ?? getSubtypeDefaultValue(section, property) ?? property.default ?? null;
  };

  const generatePostFields = (properties: any, optionChosen: any, index: number) => {
    let postFields = [];
    let postFieldClasses = '';

    if (properties.postFields) {
      postFields = properties.postFields
        .map((p: PostFieldsProps) => {
          const optionNeeded = p.optionNeeded;
          let check = false;

          if (isFunction(optionNeeded)) check = optionNeeded(aggregated, optionChosen);
          else if (isArray(p.optionNeeded)) check = (p.optionNeeded ?? []).includes(optionChosen);

          if (check) {
            if (p.className) postFieldClasses += ` ${p.className}`;

            return p.options.map((option, i: number) => {
              const className = (option.type ?? '') in keepWhiteBgMap ? '' : ' bg-ev-grey';
              const postLabel =
                option.title ?? (lang.entries[`option.text.${option.pid}`] || lang.entries[option.pid ?? '']);
              return (
                <div key={`${section.id}_${i}_${index}`} className={`property ${option.id}`}>
                  <ConditionalInput
                    option={option}
                    aggregatedOptions={aggregated}
                    userAggregatedOptions={userAggregatedOptions}
                    value={extractValue(aggregated, option)}
                    label={postLabel}
                    parent={parentKey ?? ''}
                    overrideOnChange={overrideOnChange}
                    parentPath={parentPath ?? ''}
                    showUndoButton={props.showUndoButton}
                    className={classNameOverride + className}
                  />
                </div>
              );
            });
          }

          return null;
        })
        .filter((d: any) => d);
    }
    return { postFields, postFieldClasses };
  };

  return (section.filteredOptions ?? section.options).map((properties: OptionProps, index: number) => {
    const label =
      properties.title ?? (lang.entries[`option.text.${properties.pid}`] || lang.entries[properties.pid ?? '']);
    const Container = properties.postFields ? PostFieldsContainer : EmptyContainer;
    const optionChosen = extractValue(aggregated, properties);
    const { postFields, postFieldClasses } = generatePostFields(properties, optionChosen, index);
    const className = section.inputClassName || (properties.className ? properties.className : classNameOverride);

    return (
      <React.Fragment key={`${section.id}_${index}`}>
        <Container>
          <ConditionalInput
            option={properties}
            aggregatedOptions={aggregated}
            userAggregatedOptions={userAggregatedOptions}
            label={label}
            value={extractValue(aggregated, properties)}
            parent={parentKey ?? ''}
            overrideOnChange={overrideOnChange}
            parentPath={parentPath ?? ''}
            className={className}
            index={props.index}
            showUndoButton={props.showUndoButton}
            isArraySelector={props.isArraySelector}
          />

          {properties.postFields && postFields.length > 0 && (
            <div className={`option-properties relative flex flex-col rounded ${postFieldClasses}`}>{postFields}</div>
          )}
        </Container>
      </React.Fragment>
    );
  });
};

export default BuildInputs;
