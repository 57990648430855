import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config.js';
import './../../../../node_modules/react-quill/dist/quill.snow.css';

const tailwind = resolveConfig(tailwindConfig);

export default function ExportMenu(props) {
  const { options, aggregatedOptions } = props;
  const { cssModules, dataOptions } = useSelector((state) => state.projectConfig);
  const [hovered, setHovered] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const itemClass = 'hover:bg-ev-navy-blue-3 hover:text-white p-[5px] w-full cursor-pointer font-bold text-left';
  const backgroundColor = options?.style?.backgroundColor ?? tailwind.theme.colors['ev-grey'];
  const textColor = options?.style?.color ?? tailwind.theme.colors['ev-navy-blue-2'];

  const onClickMenuIcon = () => setShowMenu((prev) => !prev);

  const download = (type) => {
    onClickMenuIcon();
    if (type === 'csv') {
      window.Everviz.downloadCSV({
        options: aggregatedOptions,
        dataOptions
      });
      return;
    }

    if (type === 'xls') {
      window.Everviz.downloadXLS({
        options: aggregatedOptions,
        dataOptions
      });
      return;
    }

    window.Everviz.downloadTableImage(
      {
        options: aggregatedOptions,
        cssModules,
        dataOptions
      },
      type
    );
  };

  return (
    <>
      <button
        className="export-button"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={onClickMenuIcon}
        aria-expanded={showMenu}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
          <g className="" strokeLinecap="round" transform="">
            <title>{options?.lang?.contextButtonTitle ?? 'Table context menu'}</title>
            <rect
              fill={hovered ? '#e7e6e6' : '#ffffff'}
              className="highcharts-button-box"
              x="0.5"
              y="0.5"
              width="24"
              height="22"
              rx="2"
              ry="2"
              stroke="none"
              strokeWidth="1"
            />
            <path
              fill="#666666"
              d="M 6 6.5 L 20 6.5 M 6 11.5 L 20 11.5 M 6 16.5 L 20 16.5"
              className="highcharts-button-symbol"
              stroke="#666666"
              strokeWidth="3"
            />
            <text x="0" y="15.5" style={{ color: 'rgb(51, 51, 51)', fontWeight: 'normal', fill: 'rgb(51, 51, 51)' }} />
          </g>
        </svg>
      </button>
      {showMenu && (
        <>
          <div className="absolute top-[40px] right-[10px] bg-white border border-[rgb(153, 153, 153)] shadow-bottom z-50 rounded-sm">
            <div className="rounded-sm m-2 flex flex-col" style={{ backgroundColor, color: textColor }}>
              <button
                onClick={() => download('png')}
                className={itemClass}
                aria-label={options?.lang?.downloadPNG ?? 'Download PNG image'}
              >
                {options?.lang?.downloadPNG ?? 'Download PNG image'}
              </button>
              <button
                onClick={() => download('jpeg')}
                className={itemClass}
                aria-label={options?.lang?.downloadJPEG ?? 'Download JPEG image'}
              >
                {options?.lang?.downloadJPEG ?? 'Download JPEG image'}
              </button>
              <button
                onClick={() => download('pdf')}
                className={itemClass}
                aria-label={options?.lang?.downloadPDF ?? 'Download PDF document'}
              >
                {options?.lang?.downloadPDF ?? 'Download PDF document'}
              </button>
              <button
                onClick={() => download('csv')}
                className={itemClass}
                aria-label={options?.lang?.downloadCSV ?? 'Download CSV'}
              >
                {options?.lang?.downloadCSV ?? 'Download CSV'}
              </button>
              <button
                onClick={() => download('xls')}
                className={itemClass}
                aria-label={options?.lang?.downloadXLS ?? 'Download XLS'}
              >
                {options?.lang?.downloadXLS ?? 'Download XLS'}
              </button>
            </div>
          </div>
          <div className="absolute w-full h-full top-0 left-0 z-1" onClick={() => setShowMenu(false)} />
        </>
      )}
    </>
  );
}
