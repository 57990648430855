import PublishExport from 'shared/editor/generic/PublishExport';
import Themes from 'shared/editor/generic/Themes';
import { PanelOptionProps } from '../../../shared/editor/generic/OptionsPanel';
import Data from '../components/Data';
import Customize from './../components/Customize';
import Template from './../components/Template';

export const renderCondition = {
  highcharts: {
    advanced: true,
    inspector: true,
    customCode: true,
    preview: true
  },
  locationMap: {
    advanced: false,
    inspector: false,
    customCode: false,
    preview: false
  }
};

const options: Array<PanelOptionProps> = [
  {
    id: 'data',
    name: 'Data',
    element: Data as unknown as React.ReactNode,
    locationMapDisabled: true
  },
  {
    id: 'type',
    name: 'Types',
    element: Template as unknown as React.ReactNode,
    permission: 'template',
    mapDisabled: true,
    locationMapDisabled: true
  },
  {
    id: 'themes',
    name: 'Themes',
    element: Themes as unknown as React.ReactNode,
    permission: 'themes'
  },
  {
    id: 'customize',
    name: 'Customize',
    element: Customize as unknown as React.ReactNode,
    permission: 'customize'
  },
  {
    id: 'publish',
    name: 'Publish & Export',
    element: PublishExport as unknown as React.ReactNode,
    permission: 'publish'
  }
];

export default options;
