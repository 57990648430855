import React, { useState } from 'react';
import PackageCard from './../components/PackageCard';
import { Package, ThemedPackage } from './../types';
import ProfilePageSectionDescription from '../../../shared/profile/ProfilePageSectionDescription';
import Subheader from '../../../shared/profile/Subheader';
import SelectWidget from 'shared/widgets/SelectWidget';
import { InputChangeParams } from 'shared/types/commonPropTypes';

type PackageSectionProps = {
  packageDetails: Package;
  index: number;
};

const getSelectValues = (packageDetails: Package) => {
  return packageDetails.packages.map((details) => ({
    label: details.team_package_name,
    value: details.team_package_uuid
  }));
};

const PackageSection = (props: PackageSectionProps) => {
  const { packageDetails } = props;
  const [activePackage, setActivePackage] = useState(packageDetails.packages[0]);
  const selectValues = getSelectValues(packageDetails);
  const value = {
    label: activePackage.team_package_name,
    value: activePackage.team_package_uuid
  };
  const onChange = ({ val }: InputChangeParams) => {
    const details = packageDetails.packages.find((details) => details.team_package_uuid === val) as ThemedPackage;
    setActivePackage(details);
  };

  return (
    <div key={packageDetails.uuid}>
      <Subheader className="flex">
        {packageDetails.name}
        {(packageDetails?.packages ?? []).length > 1 && (
          <SelectWidget
            label="Theme"
            selectOptions={selectValues}
            value={value}
            onChange={onChange}
            className="w-64 ml-auto"
            inlineSelect={true}
          />
        )}
      </Subheader>
      <ProfilePageSectionDescription>{packageDetails.description}</ProfilePageSectionDescription>
      <div className="project-grid mb-12 mt-4">
        {activePackage.projects.map((packageItem) => (
          <div key={packageItem.uuid}>
            <PackageCard key={packageItem.uuid} chart={packageItem} packageUuid={packageDetails.uuid} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PackageSection;
