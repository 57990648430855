import { cloneDeep, isArray } from 'lodash';
import {
  capitalizeFirstLetter,
  clean,
  getLetterIndex,
  isNum,
  isStr,
  merge
} from '../../../editor/core/highcharts-editor';
import { dateFormatOptions } from 'shared/meta/dateFormatOptions';
import assignDefaults from './../../../editor/meta/highed.meta.assign.defaults';

export function checkSections(sections, index) {
  return (sections || []).some(function (section) {
    return (
      (section.dataColumns && section.dataColumns.includes(index)) ||
      (section.extraColumns && section.extraColumns.includes(index)) ||
      section.labelColumn === index
    );
  });
}

export function guessDelimiter(lines) {
  let guessed = false;

  const potDelimiters = {
    ',': true,
    ';': true,
    '\t': true
  };

  lines.some(function (columnStr, i) {
    let inStr = false,
      c,
      cn,
      cl,
      token = '';
    // We should be able to detect dateformats within 13 rows
    if (i > 10) {
      return true;
    }
    for (let j = 0; j < columnStr.length; j++) {
      c = columnStr[j];
      cn = columnStr[j + 1];
      cl = columnStr[j - 1];
      if (c === '#') {
        // Skip the rest of the line - it's a comment
        return;
      }
      if (c === '"') {
        if (inStr) {
          if (cl !== '"' && cn !== '"') {
            while (cn === ' ' && j < columnStr.length) {
              cn = columnStr[++j];
            }
            // After parsing a string, the next non-blank
            // should be a delimiter if the CSV is properly
            // formed.
            if (typeof potDelimiters[cn] !== 'undefined') {
              potDelimiters[cn]++;
            }
            inStr = false;
          }
        } else {
          inStr = true;
        }
      } else if (!inStr && typeof potDelimiters[c] !== 'undefined') {
        token = token.trim();
        if (!isNaN(Date.parse(token))) {
          potDelimiters[c]++;
        } else if (isNaN(token) || !isFinite(token) || /^\d+$/.test(token)) {
          potDelimiters[c]++;
        }
        token = '';
      } else {
        token += c;
      }
    }
  });
  // Count the potential delimiters.
  // This could be improved by checking if the number of delimiters
  // equals the number of columns - 1

  if (potDelimiters[','] > potDelimiters[';'] && potDelimiters[','] > potDelimiters['\t']) {
    guessed = ',';
  } else if (potDelimiters['\t'] >= potDelimiters[';'] && potDelimiters['\t'] >= potDelimiters[',']) {
    guessed = '\t';
  } else {
    guessed = ';';
  }

  return guessed;
}

export function getDateFormat(csvRawOptions) {
  const dateColumn = [];
  const csvDataLength = csvRawOptions.length;
  for (let i = 0; i < csvDataLength; i++) {
    const timeCell = csvRawOptions[i][0];

    for (const { regex } of dateFormatOptions) {
      if (regex) {
        const result = regex.exec(timeCell || '');
        if (result) {
          dateColumn.push(result[0]);
          break;
        }
      }
    }

    if (i > 12) break; // Detect dateFormat using first 13 row
  }

  return dateColumn.length > 0 ? deduceDateFormat(dateColumn) : '';
}

export function deduceDateFormat(data) {
  const format = '';
  const stable = [];
  const max = [];

  let guessedFormat = [];
  let calculatedFormat;
  let madeDeduction = false;

  for (let i = 0; i < data.length; i++) {
    const currentData = data[i];

    if (currentData && currentData.length) {
      const retrievedFormat = currentData.trim().replace(/\//g, ' ').replace(/-/g, ' ').replace(/\./g, ' ').split(' ');
      guessedFormat = ['', '', ''];
      const minLength = Math.min(retrievedFormat.length, guessedFormat.length);

      for (let j = 0; j < minLength; j++) {
        const currentFormat = parseInt(retrievedFormat[j], 10);

        if (currentFormat) {
          max[j] = !max[j] || max[j] < currentFormat ? currentFormat : max[j];

          if (typeof stable[j] !== 'undefined') {
            if (stable[j] !== currentFormat) {
              stable[j] = false;
            }
          } else {
            stable[j] = currentFormat;
          }

          if (currentFormat > 31) {
            guessedFormat[j] = currentFormat < 100 ? 'YY' : 'YYYY';
          } else if (currentFormat > 12) {
            guessedFormat[j] = 'dd';
            madeDeduction = true;
          } else if (!guessedFormat[j]) {
            guessedFormat[j] = 'mm';
          }
        }
      }
    }
  }

  if (madeDeduction) {
    // This handles a few edge cases with hard to guess dates
    for (let j = 0; j < stable.length; j++) {
      if (stable[j] !== false) {
        if (max[j] > 31 && guessedFormat[j] !== 'YY' && guessedFormat[j] !== 'YYYY') {
          guessedFormat[j] = 'YY';
        }
      } else if (max[j] > 12 && guessedFormat[j] === 'mm') {
        guessedFormat[j] = 'dd';
      }
    }

    if (
      guessedFormat.length === 3 &&
      !['YYYY', 'YY'].includes(guessedFormat[0]) &&
      ['mm', 'dd'].includes(guessedFormat[1]) &&
      ['mm', 'dd'].includes(guessedFormat[2])
    ) {
      guessedFormat[2] = 'YY';
    }
  }

  const mmOccurrence = guessedFormat.filter((element) => element === 'mm').length;
  if (mmOccurrence === 2 && ['YYYY', 'YY'].includes(guessedFormat[2])) {
    guessedFormat[0] = 'dd';
  }

  calculatedFormat = guessedFormat.join('/');
  const formatExists = dateFormatOptions.find((option) => option.value === calculatedFormat);
  return formatExists ? calculatedFormat : format;
}

/** Parse CSV Data
 * @namespace highed
 * @param {string} - inData
 * @param {string} - delimiter
 * @param {array} - section of data to include
 * @return {array} - delimiter
 */
export const parseCSV = (inData, delimiter, section) => {
  let csv = inData || '',
    result = [],
    rows;
  //The only thing CSV formats have in common..
  rows = (csv || '').replace(/\r\n/g, '\n').split('\n');

  if (!delimiter) delimiter = guessDelimiter(rows);

  rows.forEach(function (row) {
    let cols = [],
      columnCount,
      inStr = false,
      i = 0,
      token = '',
      c;

    function pushToken() {
      columnCount = columnCount + 1 || 0;
      //Dont know why this line is even in here tbh
      //token = (token || '').replace(/\,/g, '');
      if (!token.length) {
        token = null;
        // return;
      }
      const isFips = /^(?!.*[,.])0\d+$/.test(token);

      if (isNum(token) && !isFips) {
        token = parseFloat(token);
      }

      if (section && !checkSections(section, columnCount)) {
        token = '';
        return;
      }

      cols.push(token);
      token = '';
    }

    for (i = 0; i < row.length; i++) {
      c = row[i];

      if (c === '"') {
        if (inStr) {
          inStr = false;
          //pushToken();
        } else {
          inStr = true;
        }

        //Everything is allowed inside quotes
      } else if (inStr) {
        token += c;
        //Check if we're done reading a token
      } else if (c === delimiter) {
        pushToken();

        //Append to token
      } else {
        token += c;
      }

      // Push if this was the last character
      if (i === row.length - 1) {
        pushToken();
      }
    }

    result.push(cols);
  });

  return result;
};

/** Convert an array back to csv
 * @namespace highed
 * @param {string} - csvArray
 * @return {string} - delimiter
 */
export function toCSV(csvArray, delimiter) {
  return csvArray
    .map(function (cols) {
      cols = cols.map((column) => (!isNum(column) && isStr(column) ? '"' + column + '"' : column));
      return cols.join(delimiter ?? ';');
    })
    .join('\n');
}

/** Convert an array back to csv for maps.
 *  Similar to above function, we also remove null values from the final result
 *  Highcharts doesnt like null rows here.
 * @namespace highed
 * @param {string} - csvArray
 * @return {string} - delimiter
 */
export function toMapCSV(csvArray, valueIndex, delimiter) {
  return csvArray
    .filter((cols) => cols[valueIndex ?? 1] !== null && cols[valueIndex ?? 1] !== undefined)
    .map((cols) => cols.join(delimiter ?? ';'))
    .join('\n');
}

export function createWordMap(arr) {
  const WORD_BLOCKLIST = { A: 1, The: 1, An: 1, And: 1, Then: 1 };
  let obj = {};
  arr.forEach(function (el) {
    const word = capitalizeFirstLetter(el);
    if (WORD_BLOCKLIST[word]) return;
    obj[word] = obj[word] ? ++obj[word] : 1;
  });
  return obj;
}

export function sortObjectByCount(wordsMap) {
  let finalWordsArray = [];
  finalWordsArray = Object.keys(wordsMap).map(function (key) {
    return {
      name: key,
      total: wordsMap[key]
    };
  });

  finalWordsArray.sort(function (a, b) {
    return b.total - a.total;
  });
  return finalWordsArray;
}

export function getCSVFromText(text, maxAmount) {
  text = text.replace(/(?:\r\n|\r|\n)/g, ' ').replace(/'|"/g, '');
  let lines = text.split(/[,. ]+/g),
    occurances = createWordMap(lines);

  occurances = sortObjectByCount(occurances, maxAmount);
  if (isNum(maxAmount)) {
    occurances = occurances.slice(0, maxAmount);
  }

  let data = occurances.map(function (obj) {
    return obj.name + ',' + obj.total;
  });

  data.unshift(['Name,Weight']);

  return data.join('\n');
}

export function getAllMergedLabelAndData(options) {
  let seriesValues = [];
  options.forEach(function (serie, i) {
    let arr = {},
      extraColumns = [],
      values = [];
    Object.keys(serie).forEach(function (optionKeys) {
      if (optionKeys === 'labels') {
        arr.labelColumn = getLetterIndex(options[i][optionKeys]);
      } else if (optionKeys === 'values') {
        const allData = options[i][optionKeys];
        values.push(getLetterIndex(allData));
        arr.dataColumns = values;
        arr.dataColumns.sort();
      } else {
        const extraValue = options[i][optionKeys];
        if (extraValue !== '') {
          extraColumns.push(getLetterIndex(extraValue));
        }
      }
    });
    arr.extraColumns = extraColumns.sort();
    seriesValues.push(merge({}, arr));
  });
  return seriesValues;
}

export function generateTypeObject(typeObject) {
  let newOptions = cloneDeep(assignDefaults);
  newOptions = clean(merge(newOptions, typeObject));

  clean(newOptions);
  return Object.keys(newOptions)
    .sort((a, b) => (newOptions[a].order ?? 0) - (newOptions[b].order ?? 0))
    .reduce(function (result, key) {
      result[key] = newOptions[key];
      return result;
    }, {});
}

export function getSeriesMapping(allOptions, dataTableFields) {
  return new Promise((resolve) => {
    let tempOption = [],
      hasExtraColumn = [],
      extraColumnBuffer = 0;

    let series = allOptions.length;

    for (let i = 0; i < series; i++) {
      let serieOption = {};
      // eslint-disable-next-line no-loop-func
      Object.keys(allOptions[i]).forEach((key) => {
        const option = allOptions[i][key];

        if (option.value !== '') {
          if (option.isData) {
            // (highed.isArray(option)) { // Data assigndata
            if (dataTableFields.indexOf(option.rawValue[0]) > -1) {
              let linkedTo = isArray(option.linkedTo) ? option.linkedTo : [option.linkedTo];
              linkedTo.forEach((linked) => {
                serieOption[linked] = dataTableFields.indexOf(option.rawValue[0]);
              });
            }
          } else {
            // eslint-disable-next-line no-undef
            if (option.linkedTo === 'label') hasLabels = true;
            if (dataTableFields.indexOf(option.rawValue[0]) > -1) {
              let linkedTo = isArray(option.linkedTo) ? option.linkedTo : [option.linkedTo];
              linkedTo.forEach((linked) => {
                serieOption[linked] = dataTableFields.indexOf(option.rawValue[0]);
              });
            }
          }

          if (option.addExtraColumn) {
            hasExtraColumn.push(option.linkedTo);
            extraColumnBuffer = option.addExtraColumn;
          }
        }
      });
      tempOption.push(serieOption);
    }

    if (hasExtraColumn.length && tempOption.length) {
      tempOption.forEach((opt) => {
        const keys = Object.keys(opt);
        keys.forEach((key) => {
          if (hasExtraColumn.indexOf(key) === -1) {
            opt[key] += extraColumnBuffer;
          }
        });
      });
    }
    return resolve(tempOption);
  });
}

export function getMapCodeValueIndex(seriesAssigns) {
  const mapCodeIndex = seriesAssigns?.[0]?.labels?.rawValue?.[0] ?? 0;
  const mapValueIndex = seriesAssigns?.[0]?.value?.rawValue?.[0] ?? 1;
  return { mapCodeIndex, mapValueIndex };
}

export function generateNewAssignsBasedOnMapCode(newAssigns, value) {
  return newAssigns.map((item) => {
    const labels = item.labels;
    if (labels) {
      return {
        ...item,
        labels: { ...labels, linkedTo: value }
      };
    }
    return item;
  });
}
