import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import validateEmail from 'shared/utils/validateEmail';
import Button from '../../shared/buttons/Button';
import TextInput from '../../shared/inputs/TextInput';
import { postAccountForgotpassword } from './../../api/cloudApiGenerated';
/*
let captchaInstance;
const getCaptchaInstance = (e) => captchaInstance = e;
*/
class ForgotPasswordPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.validateEmail = this.validateEmail.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateState = this.updateState.bind(this);
    this.state = {
      email: '',
      emailFormatError: false,
      success: false,
      captchaSuccess: false,
      captchaError: false
    };
  }

  componentDidMount() {
    //if (window[captchaInstance] !== undefined) window[captchaInstance].render('captchaid');
  }

  validateEmail() {
    const valid = validateEmail(this.state.email);

    if (!valid) {
      this.setState({
        emailFormatError: 'The email you specified is not a valid email format'
      });
      //window[captchaInstance].reset();
    } else {
      this.setState({ emailFormatError: false });
    }

    return valid;
  }

  onSubmit(event) {
    event.preventDefault();
    const valid = this.validateEmail();
    if (!valid) return;
    //const captcha = window[captchaInstance];
    const captchaResponse = true; //!config.captcha.enabled || captcha.getResponse();

    if (captchaResponse) {
      this.setState({
        captchaError: false
      });
    } else {
      this.setState({
        captchaError: 'You need to validate the captcha'
      });
      //  window[captchaInstance].reset();
    }

    if (valid && captchaResponse) {
      postAccountForgotpassword({
        email: this.state.email,
        captchaResponse
      });
      this.setState({ success: true });
    }
  }

  updateState(event) {
    const field = event.target.name;
    let state = this.state;
    state[field] = event.target.value;
    state.captchaError = false;
    state.emailFormatError = false;
    return this.setState(state);
  }
  render() {
    return (
      <div className="forgot-password-page dark-layout">
        <div className="page-inner-div center">
          <section className="page-section">
            <div className="logo inline-block">
              <img id="logo" title="Everviz" alt="Go to front page" src="/static/images/logo_dark.svg" />
            </div>
            <h3 className="my-8 font-bold">Request password change</h3>

            {this.state.success && (
              <div className=" m-4">
                <p>
                  If you have registered and verified this e-mail, a password reset link has been sent to your e-mail
                  address.
                </p>

                <h2>Not yet verified?</h2>
                <p>
                  When you signed up, you received a verificatiton email which is valid for 24 hours. If it has expired,
                  please sign in to your account to request a new one.
                </p>

                <NavLink
                  className="btn btn-success btn-lg"
                  to={{
                    pathname: this.props.paths.login,
                    state: { overrideRedirect: true }
                  }}
                >
                  Continue to sign in
                </NavLink>
              </div>
            )}

            {!this.state.success && (
              <div className=" m-4">
                <p>
                  Submit your email address and we'll send you a link to reset your password (if you have verified your
                  email address).
                </p>

                <div className="page-form email-form">
                  <form method="post" action="">
                    <div className="page-div">
                      <TextInput
                        label=""
                        name="email"
                        type="email"
                        value={this.state.email}
                        placeholder="Email..."
                        onChange={this.updateState}
                      />

                      {/*config.captcha.enabled &&
                        <div className="form-group">
                          <Captcha
                            getInstance={getCaptchaInstance}
                            extraClass="center-block-captcha"
                          />
                        </div>
                      */}

                      <div className="btn-page-div">
                        <Button
                          buttonText="Request password reset"
                          buttonClass="bulk-action bulk-action-btn"
                          type="submit"
                          onClick={this.onSubmit}
                        />
                      </div>
                    </div>
                    <section className="forgot-password-section forgot-password-alternatives">
                      <div className="alternative">
                        Nevermind, I just remembered it! Take me back to{' '}
                        <NavLink to={this.props.paths.login}>sign in.</NavLink>
                      </div>
                    </section>
                    {(this.state.captchaError || this.state.emailFormatError) && (
                      <div className="alert alert-danger">
                        <ul>
                          {this.state.emailFormatError && <li>{this.state.emailFormatError} </li>}
                          {this.state.captchaError && <li>{this.state.captchaError} </li>}
                        </ul>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>
    );
  }
}

ForgotPasswordPage.propTypes = {
  paths: PropTypes.object.isRequired
};

export default ForgotPasswordPage;
