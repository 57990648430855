import React, { RefObject, forwardRef } from 'react';
import classNames from 'classnames';
import Tooltip from 'shared/tooltip/Tooltip';
import { GenericInputProps } from 'shared/types/commonPropTypes';

type InputProps = {
  placeholder?: string;
  ref?: RefObject<HTMLDivElement>;
  name?: string;
};

const InputWidget = forwardRef<HTMLInputElement, GenericInputProps & InputProps>((props, ref) => {
  const { option, isArraySelector, index, value } = props;
  let timeout: NodeJS.Timeout;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (option && option.timeout) {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        props.onChange({ val });
      }, option.timeout);
    } else if (isArraySelector) props.onChange({ val, index });
    else props.onChange({ val });
  };

  const isDisable = option?.shouldBeDisabled && option.shouldBeDisabled(option);

  const widgetClasses = classNames(props.className, {
    'mt-2 w-full': props.label,
    'opacity-60': isDisable
  });

  const inputClasses = classNames('highed-field-input placeholder:font-bold', option?.inputClassName, props.className, {
    'pointer-events-none': isDisable
  });

  const numberWidget = (
    <div className={widgetClasses} id={option?.id}>
      {option?.image && <img src={`https://app.everviz.com/static/editor/${option?.image}`} />}
      {props.label && <div className="text-ev-navy-blue-4 font-bold mb-2">{props.label}</div>}
      <input
        ref={ref}
        className={inputClasses}
        value={(value ?? '') as string}
        onChange={(e) => onChange(e)}
        onKeyDown={props.onKeyDown}
        placeholder={props.placeholder}
        disabled={isDisable}
        type={props.type}
        name={props.name}
      />
    </div>
  );

  if (isDisable) {
    return <Tooltip tippyProps={{ content: option.disabledText, placement: 'bottom' }}>{numberWidget}</Tooltip>;
  }
  return numberWidget;
});

export default InputWidget;
