import { cloneDeep } from 'lodash';
import { redrawProjectAction, setTemplateAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { templateIcons } from 'pages/ChartEditorPage/meta/templates/Icons';
import { SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import { getPlanById } from 'pages/SubscriptionPage/utils/plansHelper';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'redux/store';
import { useIsMobile } from 'shared/utils/mediaHelper';
import { cancelTextMap, showPaidPlanModal } from 'shared/utils/paidPlanHelper';
import { getAllInCat, getAllInCatToArray, getMostPopular } from '../../../editor/core/highed.templateman';
import TemplateText from '../../../pages/ChartEditorPage/meta/wizard/TemplateText';
import ListOption from '../components/ListOption';
import WizardProjectPreview from '../data/WizardProjectPreview';
import AdvancedTemplateSelector from '../editor/AdvancedTemplateSelector';
import MapAdvancedTemplateSelector from '../editor/MapAdvancedTemplateSelector';
import WizardContainer from './WizardContainer';
import WizardLeftContainer from './WizardLeftContainer';
import WizardRightContainer from './WizardRightContainer';

type ChooseTemplateProps = { cb: () => void };

export default function ChooseTemplate(props: ChooseTemplateProps) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { search } = useLocation();
  const [state, setState] = useState({
    showBasic: true,
    advancedSelection: 'Line',
    selected: '',
    advancedTemplates: [],
    selectedOption: {},
    advancedActive: false,
    mostPopular: [] as any[]
  });
  const { isMap, chosenWizardTemplate } = useSelector((state: RootState) => state.chartEditorPage);
  const { subscription, team } = useSelector((state: RootState) => state.profile);
  const { provider, type, loadedTemplate } = useSelector((state: RootState) => state.projectConfig);
  const hasBillingSetup = window.hcconfig.billing.enabled;
  const isLocationMap = provider === 'locationMap';

  const currentPlan = getPlanById(subscription?.id);
  const isFreePlan = currentPlan === SubscriptionPlans.Free;

  const getFilteredTemplates = (isMap: boolean, useLocationMap: boolean) => {
    let templates = isMap ? (getAllInCatToArray('Map') as any[]) : getMostPopular();
    templates = templates.map((template) => cloneDeep(template));
    templates.sort((a, b) => a.basic.order - b.basic.order);
    if (!useLocationMap) templates = templates.filter((template) => template.provider !== 'locationMap');
    return templates;
  };

  const getAllTemplates = () => {
    let { advancedSelection } = state;
    if (chosenWizardTemplate) {
      advancedSelection = (chosenWizardTemplate as any).advancedParent;
    }

    const mostPopular = getFilteredTemplates(isMap, (team?.beta_features ?? []).includes(4));
    const advancedTemplates = isMap ? [] : getAllInCat(advancedSelection);
    const typeParam = new URLSearchParams(search).get('type');
    const template = mostPopular.find((template) => template.sampleSet === typeParam) || mostPopular[0];

    if (!chosenWizardTemplate) dispatch(setTemplateAction({ template }));
    else if (type === 'chart') dispatch(redrawProjectAction({ noAnimation: true }));

    setState({
      ...state,
      advancedTemplates,
      advancedSelection,
      showBasic: true,
      selectedOption: chosenWizardTemplate || template,
      selected: (chosenWizardTemplate || template).advancedParent,
      mostPopular
    });
  };

  useEffect(() => {
    //Location map
    const useLocationMap = (team?.beta_features ?? []).includes(4);
    if (isMap && useLocationMap) {
      const mostPopular = getFilteredTemplates(isMap, useLocationMap);
      setState({
        ...state,
        mostPopular
      });
    }
  }, [team]);

  useEffect(() => {
    getAllTemplates();
  }, []);

  const selectTemplate = (selected: Record<string, any>, changeAdvancedSettings: boolean) => {
    if (selected.advancedParent === state.selected) return;
    let advancedTemplates = state.advancedTemplates;

    if (changeAdvancedSettings) {
      advancedTemplates = isMap ? [] : getAllInCat(selected.advancedParent);
    }

    if (isFreePlan && hasBillingSetup && selected.locked) showPaidPlanModal('starter', cancelTextMap.previewChart);
    setState({
      ...state,
      selected: selected.advancedParent,
      selectedOption: selected,
      advancedTemplates
    });

    dispatch(setTemplateAction({ template: selected }));
  };

  const onDoubleClick = (template: Record<string, any>) => {
    if (isFreePlan && hasBillingSetup && template.locked) return;

    selectTemplate(template, true);
    props.cb();
  };

  const toggleAdvanced = () => {
    const { advancedTemplates } = state;
    const hasAdvanced = Object.keys(advancedTemplates).length > 1;

    if (!hasAdvanced) return;
    setState({
      ...state,
      advancedActive: !state.advancedActive
    });
  };
  const { mostPopular, advancedTemplates, selected, selectedOption, advancedActive } = state;
  const hasAdvanced = Object.keys(advancedTemplates).length > 1;
  const text = TemplateText[selected as keyof typeof TemplateText];
  const isHighchartsMapTemplate = isMap && !isLocationMap;

  return (
    <WizardContainer>
      <WizardLeftContainer
        text={`Select a ${isMap ? 'map' : 'chart'} type and style and go to step 2.`}
        subheader={`${isMap ? 'Map' : 'Chart'} type`}
        header={'Type'}
      >
        <div className="templates-container basic-options grid grid-cols-2 gap-2">
          {(mostPopular as any[]).map((template, i) => {
            const Icon = templateIcons[template.basic.icon];
            return (
              <ListOption
                selected={selected === template.advancedParent}
                onClick={() => selectTemplate(template, true)}
                onDoubleClick={() => onDoubleClick(template)}
                icon={Icon}
                title={template.title}
                key={`list_${i}`}
                locked={isFreePlan && hasBillingSetup && template.locked}
              />
            );
          })}
        </div>
      </WizardLeftContainer>

      <WizardRightContainer isMap={isMap}>
        <WizardProjectPreview />

        <div className="template-extra-information">
          {!isMap && !isLocationMap && !isMobile && (
            <AdvancedTemplateSelector
              advancedTemplates={advancedTemplates}
              selectedOption={selectedOption}
              toggleAdvanced={toggleAdvanced}
              selectTemplate={selectTemplate}
              hasAdvanced={hasAdvanced}
              advancedActive={advancedActive}
            />
          )}

          {loadedTemplate && isHighchartsMapTemplate && <MapAdvancedTemplateSelector />}

          <div className="ev-sm-max:hidden template-type-text text-left pt-8 text-ev-navy-blue">{text}</div>
        </div>
      </WizardRightContainer>
    </WizardContainer>
  );
}
