import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { setAction } from 'redux/actions/profile';
import { showSignupModalAction } from 'redux/actions/projectConfig';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { getMenuItems } from 'shared/utils/menuItemsHelper';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import AnnouncementsList from 'shared/message/AnnouncementsList';
import RecentExportList from 'shared/exports/RecentExportList';
import Modal from 'shared/modal/Modal';
import useToggle from 'shared/utils/useToggle';
import Logo from 'static/images/logo.everviz.svg';
import { checkPermission, onLoggedIn, setDivision, setTeam } from 'utils/profileHelper';
import { handleGoogleTagManagerEvent } from 'shared/utils/googleTagManagerHelper';
import TeamMessage from '../message/TeamMessage';
import TeamModal from '../team/TeamModal';
import TeamSelector from '../team/TeamSelector';
import EditorLabel from './EditorLabel';
import Upsell from './TopBarUpsell';
import NavigationTab from './NavigationTab';
import BarsSolid from 'static/icons/bars-solid.svg';
import XIcon from 'static/icons/xmark-large-solid.svg';
import { getPlanById, getRemainingDays } from 'pages/SubscriptionPage/utils/plansHelper';
import { SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import ProjectTitle from '../editor/generic/ProjectTitle';
import ProjectSaveButton from '../editor/generic/ProjectSaveButton';
import ProjectPublishButton from '../editor/generic/ProjectPublishButton';
import ProjectLastSavedAt from '../editor/generic/ProjectLastSaved';
import Export from 'shared/exports/Export';

export default function TopBar(props) {
  const [toggled, changeToggled] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const profile = useSelector((state) => state.profile);
  const { showWizard, isCompanyThemeEditor } = useSelector((state) => state.projectConfig);
  const { paths } = useSelector((state) => state.app);
  const {
    recentExports,
    previousExports,
    subscription,
    unreadMessages,
    user,
    teams,
    announcements,
    messages,
    team,
    divisions,
    division
  } = profile;
  const { showDivisionModal } = props;
  const [showNewProject, setShowNewProject] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showNewTeamModal, setShowNewTeamModal] = useState(false);
  const [showTeamMessagesModal, setShowTeamMessagesModal] = useState(false);
  const [showAnnouncementsModal, toggleShowAnnouncementsModal] = useToggle(!!announcements?.new?.length > 0);
  const [showRecentExportModal, toggleShowRecentExportModal] = useToggle(false);
  const [showExportNotification, setShowExportNotification] = useState(false);

  const setTeamFromDropdown = (team) => setTeam(team, null, location.pathname);
  const checkTeamPermission = (permission) => checkPermission(permission, profile);

  const editorPaths = ['/create', '(.edit.)([-0-9]+)', '(.edittheme.)([-0-9]+)', '/createtheme'];

  const openLogoutModal = () => setShowLogoutModal(true);
  const closeLogoutModal = () => setShowLogoutModal(false);
  const openNewTeamModal = () => setShowNewTeamModal(true);
  const closeNewTeamModal = () => setShowNewTeamModal(false);
  const closeTeamMessagesModal = () => setShowTeamMessagesModal(false);
  const closeAnnouncements = () => toggleShowAnnouncementsModal(false);

  const setShowAnnouncementsModal = () => {
    toggleShowAnnouncementsModal();
    if (showRecentExportModal) toggleShowRecentExportModal(false);
  };

  const setShowRecentExportModal = () => {
    toggleShowRecentExportModal();
    if (showAnnouncementsModal) toggleShowAnnouncementsModal(false);
  };

  const closeExports = () => {
    toggleShowRecentExportModal(false);
    setShowExportNotification(false);
  };
  const showSignupModal = () => dispatch(showSignupModalAction());
  const menuItems = getMenuItems(profile);

  useEffect(() => {
    if (subscription) {
      const plan = getPlanById(subscription.id);
      if (plan === SubscriptionPlans.ProTrial && subscription.period_end_time) {
        const remainingDays = getRemainingDays(subscription.period_end_time);
        setShowNewProject(remainingDays < 1);
      }
      setShowNewProject(plan !== SubscriptionPlans.Free);
    }
  }, [subscription]);

  useEffect(() => {
    closeAnnouncements();
    closeExports();
  }, [location.pathname]);

  useEffect(() => {
    toggleShowAnnouncementsModal(!!announcements?.new?.length > 0);
  }, [announcements]);

  const logout = () => {
    props.logout();
    closeLogoutModal();
    closeAnnouncements();

    handleGoogleTagManagerEvent({
      event: 'logout'
    });
  };

  const checkPath = (path) => {
    let found = false;
    editorPaths.forEach(function (e) {
      if (path.match(e)) found = true;
    });
    return found;
  };

  const setAsRead = (message) => {
    let stateMessages = cloneDeep(unreadMessages);

    stateMessages.forEach((m) => {
      if (m.id === message.id) {
        m.read = 1;
      }
    });

    dispatch(
      setAction({
        unreadMessages: stateMessages
      })
    );
  };

  const navActions = props.navActions[location.pathname];
  const inEditor = checkPath(location.pathname);
  const parentSelector = () => document.querySelector('.page-container');

  return (
    <>
      <AnnouncementsList
        data={[...(announcements?.new ?? []), ...(announcements?.old ?? [])]}
        isOpen={showAnnouncementsModal}
        onClose={closeAnnouncements}
      />
      <RecentExportList
        exports={recentExports}
        previousExports={previousExports}
        isOpen={showRecentExportModal}
        onClose={closeExports}
      />
      <div className={'navbar navbar-default ' + (!team ? ' not-logged-in' : '') + (inEditor ? ' editor' : '')}>
        <Modal
          isOpen={showLogoutModal}
          onClose={closeLogoutModal}
          title={'Logout'}
          width={'w-5/12 max-w-xl'}
          bg={'bg-ev-yellow-1'}
        >
          <p>Are you sure you want to sign out?</p>

          <hr />
          <div className="pt-6 flex gap-2">
            <PrimaryButton onClick={logout} text="Confirm" />
            <PrimaryButton onClick={closeLogoutModal} text="Cancel" buttonColor={ButtonColor.BabyBlue} />
          </div>
        </Modal>
        <TeamModal
          isOpen={showNewTeamModal}
          parentSelector={parentSelector}
          close={closeNewTeamModal}
          reloadTeams={onLoggedIn}
        />

        <Modal
          className="hc-modal-team-message-content"
          overlayClassName="hc-modal-overlay"
          appElement={document.querySelector('.page-container')}
          parentSelector={parentSelector}
          onRequestClose={closeTeamMessagesModal}
          isOpen={showTeamMessagesModal}
          contentLabel="Messages"
        >
          <TeamMessage
            messages={[...(unreadMessages ?? []), ...(messages ?? [])]}
            team={team}
            close={closeTeamMessagesModal}
            setAsRead={setAsRead}
          />
        </Modal>

        <div className={`flex content-centered ${toggled ? 'pl-2' : ''}`}>
          <NavLink to={paths.profileCharts}>
            <Logo
              className={`ml-9 ev-sm:mr-2 ev-sm:ml-8 mb-2 ${
                toggled ? 'text-ev-light-purple ml-20' : 'text-ev-navy-blue'
              }`}
            />
          </NavLink>
        </div>

        {team && (
          <div className={'fixed right-0 py-5 mr-3 ev-sm:hidden'} onClick={() => changeToggled(!toggled)}>
            {toggled ? <XIcon className="w-4 h-4 text-gray-100 mt-1 mr-1" /> : <BarsSolid />}
          </div>
        )}
        {team && toggled && (
          <div className="fixed w-full z-[-10] h-[670px] top-0 bg-ev-navy-blue ev-sm:hidden">
            <NavigationTab changeToggled={changeToggled} toggled={toggled} tabs={menuItems} />
          </div>
        )}

        <div className={'navbar-collapse'}>
          <div className="navbar-inner flex justify-between ">
            {!inEditor && (
              <div className="info-container content-centered">
                <div className="team-selector-list hidden ev-sm:block">
                  {teams && team && (
                    <TeamSelector
                      key={divisions}
                      team={team}
                      setTeam={setTeamFromDropdown}
                      showModal={showDivisionModal}
                      division={division}
                      divisions={divisions}
                      setDivision={setDivision}
                      checkPermission={checkTeamPermission}
                    >
                      {teams}
                    </TeamSelector>
                  )}
                </div>
              </div>
            )}

            {inEditor ? (
              <div className="pl-2 pr-2 flex justify-between gap-4 flex-1 ">
                <div className="flex items-center">
                  <EditorLabel className="hidden xl:flex mt-3" />
                  {!showWizard && (
                    <ProjectTitle className="hidden ev-sm:flex mt-3 border-l-[2px] rounded-sm pl-2 max-w-[290px] ev-md:max-w-[315px] ev-xl:max-w-[440px]" />
                  )}
                </div>
                {!showWizard && (
                  <div className="flex justify-end items-center p-2">
                    <ProjectLastSavedAt className="hidden ev:block truncate max-w-[180px] ev-lg:max-w-none" />
                    <ProjectSaveButton />
                    {!isCompanyThemeEditor && <ProjectPublishButton />}
                  </div>
                )}
              </div>
            ) : (
              <div className="ml-auto mr-4 content-centered">
                <div className="create-chart-list mr-4">
                  {(!navActions || navActions.length < 1) && showNewProject && checkTeamPermission('create_chart') && (
                    <div>
                      <NavLink to={paths.vizPicker} className="bulk-action-btn ev-red everviz-red">
                        <i className="fa fa-plus" aria-hidden="true" />
                        &nbsp;New project
                      </NavLink>
                    </div>
                  )}
                </div>
                {team?.charts_max && <Upsell />}
              </div>
            )}

            <ul className="nav navbar-nav navbar-right list-none">
              {navActions &&
                navActions.map((action, index) => (
                  <li key={index} className="li-btn group">
                    <a
                      onClick={action.onClick}
                      className={
                        'btn' +
                        (action.className ? ' ' + action.className : '') +
                        (action.tooltip ? ' highed-template-tooltip' : '')
                      }
                    >
                      <span>
                        {action.icon && (
                          <i className={'text-ev-navy-blue-2 group-hover:text-white fa fa-' + action.icon} />
                        )}
                        {(action.icon ? ' ' : '') + action.text}
                      </span>
                      {action.tooltip && <span className="highed-tooltip-text">{action.tooltip.text}</span>}
                    </a>
                  </li>
                ))}

              {user && !showWizard && (
                <Export
                  toggleShowRecentExportModal={setShowRecentExportModal}
                  setShowExportNotification={setShowExportNotification}
                  showExportNotification={showExportNotification}
                />
              )}

              {!inEditor && user && (
                <li className="group li-btn hidden-sm">
                  <a className="btn highed-template-tooltip" onClick={() => setShowAnnouncementsModal()}>
                    <span>
                      <i className="text-ev-navy-blue-2 group-hover:text-white fa fa-bell no-margin" />
                      {announcements &&
                        ((announcements.new && announcements.new.length > 0) ||
                          (announcements.old && announcements.old.length > 0)) && (
                          <div className="notification-circle bg-ev-red" />
                        )}
                    </span>
                    <span className="highed-tooltip-text">Announcements</span>
                  </a>
                </li>
              )}

              {user && teams.length === 0 && (
                <li className="li-btn">
                  <a className="create-chart-btn btn" onClick={openNewTeamModal}>
                    {' '}
                    CREATE TEAM{' '}
                  </a>
                </li>
              )}

              {user && (
                <li className="group li-btn">
                  <a className="btn highed-template-tooltip" onClick={openLogoutModal}>
                    <span>
                      <i className="text-ev-navy-blue-2 group-hover:text-white fa fa-sign-out-alt" />
                    </span>
                    <span className="highed-tooltip-text">Sign out</span>
                  </a>
                </li>
              )}
              {!user && (
                <li>
                  {showWizard ? (
                    <PrimaryButton text="Sign in" onClick={showSignupModal} className="m-4 min-w-[115px]" />
                  ) : (
                    <NavLink
                      className="bulk-action-btn sign-in-btn everviz-red m-4"
                      to={{
                        pathname: paths.login,
                        state: {
                          overrideRedirect: location.pathname === '/',
                          from: location.pathname
                        }
                      }}
                    >
                      Sign in
                    </NavLink>
                  )}
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
