import { TemplateProps } from '../../types';

const Parliament: TemplateProps = {
  title: 'Parliament',
  description: '',
  constructor: '',
  thumbnail: 'parliament.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'item'
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
          format: '{point.label}'
        },
        center: ['50%', '88%'],
        startAngle: -100,
        endAngle: 100
      }
    },
    legend: {
      labelFormat: '{name} <span style="opacity: 0.4">{y}</span>'
    }
  },
  plugins: ['parliament'],
  sampleSet: 'parliament',
  dataValidator: false,
  advancedParent: 'Parliament',
  defaultDataType: 'parliament',
  basic: {
    order: 26,
    thumbnail: 'parliament.svg',
    icon: 'Parliament'
  },
  parent: 'More',
  locked: true
};

export default Parliament;
