import { TemplateProps } from '../../types';

const Scatter: TemplateProps = {
  title: 'Scatter chart',
  description: '',
  constructor: '',
  thumbnail: 'ezatat.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'scatter',
      polar: false
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true
        },
        lineWidth: 0
      }
    }
  },
  sampleSet: 'scatter',
  dataValidator: false,
  advancedParent: 'Scatter chart',
  advanced: {
    name: 'Standard'
  },
  basic: {
    order: 10,
    thumbnail: 'scatterplot.svg',
    icon: 'Scatter'
  },
  parent: 'Scatter chart',
  locked: true
};

export default Scatter;
