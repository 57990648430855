import { cloneDeep, get, isObject } from 'lodash';
import { OptionProps } from 'pages/ChartEditorPage/meta/CustomizeOptions';
import { Dispatch } from 'react';
import { GenericInputProps } from 'shared/types/commonPropTypes';
import { isArr, isNull, merge } from '../../../editor/core/highcharts-editor';
import { updateDataLabelAction } from '../../../pages/ChartEditorPage/actions/chartEditor';
import { SelectProps } from '../DependencySelectWidget';
import { getSeries } from './widgetHelper';

type DataLabelProps = {
  style?: {}
  decimalValue?: number,
  format?: string;
}

type AggregatedOptionsProps = {
  chart: { type?: string };
  series: { type?: string; dataLabels: DataLabelProps[]; format: string; decimalValue: number }[];
};

const findAllIndexes = (array: string[], val: string) => {
  const indexes = [];
  for (let i = 0; i < array.length; i++) if (array[i] === val) indexes.push(i);
  return indexes;
};

const getDecimalValue = (value: string) => {
  const decRegex = /\.([0-9])/;
  const regex = /{.*?}/g;
  const found = value ?? ''.match(regex);
  if (!found.length) return 0;
  const splitValue = found.split(':');
  if (splitValue.length <= 1) return 0;
  const dec = splitValue[1].match(decRegex);
  if (dec && dec.length) return parseInt(dec[1], 10);
  return 0;
};

export const extractDataLabelOptions = (
  aggregatedOptions: AggregatedOptionsProps,
  group: OptionProps,
  detailIndex: any
) => {
  const types = (aggregatedOptions.series || []).map(
    (series) => series.type || (aggregatedOptions.chart && aggregatedOptions.chart.type) || 'line'
  );
  const uniqueTypes = [...new Set(types)];

  // Highcharts has 3 different places where a data label can be added. The priority for these are:
  // 1. Each Series Options -> dataLabels
  // 2. Plot Options -> Template Type (ex: Line) -> dataLabels
  // 3. Plot Options -> Series -> dataLabels

  const seriesDataLabelProp: {
    decimalValue?: number;
    format?: string;
    dataLabels?: [];
  } = get(aggregatedOptions, 'plotOptions.series.dataLabels') ?? {};
  let allSeriesDataLabelProp = aggregatedOptions.series.map(() => cloneDeep(seriesDataLabelProp || {})) as DataLabelProps[];

  uniqueTypes.forEach((type) => {
    const typeDataLabel = get(aggregatedOptions, `plotOptions.${type}.dataLabels`) ?? {};
    const indexes = findAllIndexes(types, type);
    indexes.forEach((index) => {
      allSeriesDataLabelProp[index] = merge(cloneDeep(allSeriesDataLabelProp[index]), typeDataLabel, null, {});
    });
  });

  aggregatedOptions.series.forEach((series, i) => {
    const dataLabel = (isArr(series.dataLabels) ? series.dataLabels[0] : series.dataLabels) || {};
    allSeriesDataLabelProp[i] = merge(cloneDeep(allSeriesDataLabelProp[i]), dataLabel, null, {});
  });

  allSeriesDataLabelProp.forEach((series) => {
    series.decimalValue = getDecimalValue(series.format ?? '');
  });

  if (group.default) {
    allSeriesDataLabelProp = allSeriesDataLabelProp.map((obj) => ({
      ...obj,
      style: { 
        ...group.default as {}, 
        ...obj.style
      }
    }));
  }


  const rawKey = group.rawKey ?? '';

  if (detailIndex === 'all') {
    // All Series

    let isPropertyEqual = false;
    if (!isNull(get(allSeriesDataLabelProp[0], rawKey))) {
      isPropertyEqual = allSeriesDataLabelProp.every((prop) => {
        const property = get(prop, rawKey);
        const allSeriesDataLabel = get(allSeriesDataLabelProp[0], rawKey);
        if (isObject(property) && isObject(allSeriesDataLabel)) {
          const propertyKeys = Object.keys(property);
          const seriesDataLabelKeys = Object.keys(allSeriesDataLabel);
          return (
            propertyKeys.length === seriesDataLabelKeys.length &&
            propertyKeys.every((key) => seriesDataLabelKeys.includes(key))
          );
        }
        return get(allSeriesDataLabelProp[0], rawKey) === property;
      });
    }
    if (isPropertyEqual) return get(allSeriesDataLabelProp[0], rawKey) ?? group.default;
    return group.default;
  }

  // Individual series
  const opt = get(allSeriesDataLabelProp[detailIndex], rawKey) ?? group.default;
  if (!isNull(opt)) {
    return get(allSeriesDataLabelProp[detailIndex], rawKey) ?? group.default;
  }
};

export default ({
  dispatch,
  props,
  detailIndex,
  onOptionChange
}: {
  dispatch: Dispatch<any>;
  props: GenericInputProps;
  detailIndex: any;
  onOptionChange: (value: any) => void;
}) => {
  /**
   * Change All Series
   * 1. Delete all series -> dataLabel option
   * 2. Delete all plotOption -> Type -> dataLabel option
   * 3. Change value of plotOption -> Series
   *
   * Change Individual Series
   * 1. Change value of series -> dataLabel option
   */
  const changeDataLabelValue = (option: OptionProps, value: any, detailIndex: number | string) => {
    dispatch(
      updateDataLabelAction({
        option,
        value,
        detailIndex
      })
    );
  };

  const { option, aggregatedOptions, userAggregatedOptions, chart } = props;
  const selectOptions: [] = getSeries(option, aggregatedOptions, chart) || [];
  const selectValue: unknown = selectOptions.find((d: { value: string }) => d.value === detailIndex);

  return {
    selectOptions,
    selectValue,
    onChangeSelect: onOptionChange,
    filteredOptions: option as OptionProps,
    extractValue: (property: OptionProps) => extractDataLabelOptions(userAggregatedOptions, property, detailIndex),
    onChangeValue: (_: string, option: OptionProps, val: any) => changeDataLabelValue(option, val, detailIndex)
  } as SelectProps;
};
