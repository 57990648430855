import { TemplateProps } from '../../types';

const RadialBar: TemplateProps = {
  title: 'Radial bar',
  description: '',
  constructor: '',
  thumbnail: 'radialbar.svg',
  icon: '',
  sampleSets: [],
  validator: false,
  config: {
    chart: {
      type: 'column',
      inverted: true,
      polar: true
    },
    tooltip: {
      outside: true
    },
    pane: {
      size: '85%',
      innerSize: '20%',
      endAngle: 270
    },
    yAxis: {
      crosshair: {
        enabled: true,
        color: '#333'
      },
      lineWidth: 0,
      tickInterval: 25,
      reversedStacks: false,
      endOnTick: true,
      showLastLabel: true,
      title: {
        text: ''
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0.15
      }
    }
  },
  sampleSet: 'radial',
  dataValidator: false,
  basic: {
    order: 17,
    thumbnail: 'radial_bar_chart.svg',
    icon: 'RadialBar'
  },
  advancedParent: 'Radial Bar',
  parent: 'Bar',
  locked: true
};

export default RadialBar;
