import React, { useEffect } from 'react';
import SideContainer from '../../../shared/message/SideContainer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import ChannelSectionContent from '../../../shared/wizard/components/publishChannel/ChannelSectionContent';
import PermissionModal from 'shared/modal/PermissionModal';
import { checkCreatePermission, checkPermission } from '../../../utils/profileHelper';
import useToggle from '../../../shared/utils/useToggle';
import { onPublish } from '../../../shared/editor/publishHelper';
import HighchartsPreview from '../../../shared/wizard/data/HighchartsPreview';
import { loadChartAction, setAction } from '../../ChartEditorPage/actions/chartEditor';
import { loadTableAction } from 'pages/TableEditorPage/actions/tableEditor';
import { loadLayoutAction, setAction as setLayoutAction } from 'pages/LayoutEditorPage/actions/layoutEditor';

const QuickExport = () => {
  const { chartId } = useSelector((state: RootState) => state.chartEditorPage);
  const { type } = useSelector((state: RootState) => state.projectConfig);
  const { storyId } = useSelector((state: RootState) => state.layoutEditorPage);
  const [showPermissionModal, toggleShowPermissionModal] = useToggle();
  const hasPublishPermission = checkPermission('publish');
  const hasCreatePermission = checkCreatePermission(type);

  const dispatch = useDispatch();
  const shouldShowQuickExport = Boolean(chartId) || Boolean(storyId);

  useEffect(() => {
    if (shouldShowQuickExport) {
      if (type === 'chart') {
        dispatch(loadChartAction({}));
      } else if (type === 'table') {
        dispatch(loadTableAction({}));
      } else {
        dispatch(loadLayoutAction({}));
      }
    }
  }, [chartId, storyId]);

  const handlePublish = () => {
    if (!hasPublishPermission || !hasCreatePermission) {
      toggleShowPermissionModal(true);
    } else {
      onPublish(type, dispatch);
    }
  };

  const handleOnClose = () => {
    dispatch(setAction({ chartId: null, chartVersion: null }));
    dispatch(setLayoutAction({ storyId: null, chartVersion: null }));
  };

  return (
    <SideContainer isOpen={shouldShowQuickExport} header="Quick export" onClose={handleOnClose}>
      <PermissionModal isOpen={showPermissionModal} onClose={toggleShowPermissionModal} />
      <div className="bg-ev-grey p-4 rounded">
        <ChannelSectionContent onPublish={handlePublish} />
      </div>

      {shouldShowQuickExport && (
        <div className="hidden">
          <HighchartsPreview />
        </div>
      )}
    </SideContainer>
  );
};

export default QuickExport;
